﻿.access-history-table-container {
  position: relative;
  height: 310px;
  overflow: auto;
  padding-top: 5px;
  height: 500px;
  width: 71%;
}

.access-history-table {
  width: 100%;
}

tr.access-history-detail-row {
  height: 0 !important;
}

tr.access-history-element-row:not(.access-history-expanded-row):hover {
  background: whitesmoke;
}

tr.access-history-element-row:not(.access-history-expanded-row):active {
  background: #efefef;
}

.access-history-element-row td {
  border-bottom-width: 0.5;
}

.access-history-element-detail {
  overflow: hidden;
  display: flex;
}

.access-history-element-description {
  padding: 0px;
}

.access-history-element-description-attribution {
  opacity: 0.5;
}
