﻿.iso-login-viewport {
    // background-image: linear-gradient(to bottom right, lighten($cp-corp-color, 10%), $cp-corp-color);
    height: 100vh;
}

.iso-login-column {
    margin: 0 auto;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.iso-login-container {
    margin:0 auto;
}
.iso-login-panel {
    margin-top: 0%;
    width: 100%;
    //padding: 5px !important;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    height: 100%;
}

.iso-login-footer{
    background: #006BA6;
    line-height: 27px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 0px;
    height: 70px;
    position:fixed;
    bottom:0px;
    left:0px;
    right:0px;
    margin-bottom:0px;
    font-size: larger;
}

@media only screen and (max-width: 850px) {
    .iso-login-footer{
        background: #006BA6;
        line-height: 27px;
        color: #ffffff;
        text-align: center;
        padding-bottom: 0px;
        height: 50px;
    }
}

.iso-login-footer-divide1{
  padding-left: 5%;
  padding-right: 10px;
}

.iso-login-footer-divide2{
  margin-left: 10px !important;
}

.iso-login-box {
    background-color: darken($cp-corp-color,8%);
    color: $cp-white;
    padding: $login-box-padding;
    border-radius: 5px;
    box-shadow: 12px 12px 24px rgba($cp-corp-third-color, .3);

    input[type=text], input[type=email], input[type=password] {
        border:0;
        border-bottom: 1px solid $cp-corp-color;

        /*background-color: darken($cp-corp-color,8%);*/
        background-color: rgba($cp-white, .1);
        color: $cp-white;
        border-radius: 3px;
        font-size: $default-font-size + 2;
        height: $login-box-input-height;
        padding: $login-box-input-padding;
        box-shadow: none;

        &:focus {
            outline: 0;
            background: rgba($cp-white, .2);
            box-shadow: none;

            &::placeholder {
                color: $cp-corp-active-color;
            }
        }

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            color: lighten($cp-corp-color, 8%);
            font-size: $default-font-size;
        }
    }

    input[type=checkbox] + label {
        @include defaultselectioncontrol;
        color: $cp-white;
    }
}

.iso-logout-box {
    position: absolute;
    top: 25%;
    width: 100%;
    // background-color: rgba($cp-white, .75);
    // color option
    // background-image: linear-gradient(to right, rgba($cp-white, .35) 0%, rgba($cp-white, .75) 30%, rgba($cp-white, .75) 70%, rgba($cp-white, .35) 100%);
    background-image: linear-gradient(to right, rgba($cp-white, .20) 0%, rgba($cp-white, .85) 30%, rgba($cp-white, .85) 70%, rgba($cp-white, .20) 100%);
    padding: 0 30%;
}

.iso-login-form-control-label {
    color: $cp-white;
    font-weight: normal;
    font-size: ($default-font-size);
    text-transform:uppercase;
}

.iso-login-language-list {
    padding: 0;
    list-style-type: none;
    width: 100%;

    li {
        display: inline;
        padding: 0 50px 0 10px;
        cursor: pointer;

        a {
            span {
                padding-left: 15px;
                color: $cp-white;
            }
        }
    }
}

.iso-login-controls-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iso-login-a {
    color: $cp-corp-active-color;
    padding: 0 15px;

    &:hover {
        color: $cp-corp-active-color;
        text-decoration: none;
    }

    &:active {
        color: $cp-corp-active-color;
        text-decoration: none;
    }
}

.iso-login-forgotpassword {
    padding: 10px 0 0 0;
}

.iso-login-captcha {
    .captcha-image {
        width: 100%;
        height: 70px;
        background: darken($cp-white,2%);
    }

    div:last-child {
        i {
            line-height: 70px;
        }
    }

}

.iso-login-right-content {
    color: darken($cp-white,10%);
    text-align: left;
    font-size: $default-font-size;
}

.iso-login-privacy-content {
    font-size: $default-font-size;

    div {
        color: lighten($cp-corp-second-color,50%);
    }
}

.iso-login-privacy {
    ul {
        padding: 0;
        list-style-type: none;
    }

    li {
        display: inline-block;
        padding: 2px 10px;
        cursor: pointer;
        width: 100%;

        a {
            color: $cp-white;

            &:hover {
                color: $cp-white;
            }

            &:active {
                color: $cp-white;
            }
        }
    }
}

.iso-login-assistance {
    font-size: $default-font-size;
}

.iso-login-bottom {
    padding: $login-bottom-padding;
    color: darken($cp-white,10%);
    text-align: left;
    font-size: $default-font-size;
}

.iso-welcome-bottom {
    background: #f5f5f5;
    position: absolute;
    top: 25%;
    left: 25%;
    width: 45%;
    box-shadow: 0 4px 15px -10px black;
    border-radius: 6px;
}

.iso-login-bottom-disclaimer {
    padding: 15px;
    color: #004766;
    text-align: left;
    font-size: 14px;
}

.has-form-error {
    color: $cp-error-red;
}

.iso-login-alert {
    margin: 20px 0;
    padding-right: 15px;
}

.iso-login-alert-danger {
    color: $cp-white;
    background: lighten($cp-error-red, 10%);
    font-size: ($default-font-size + 2);
    padding: 15px;
    display: flex;
    flex-direction: row;

    div {
        &:first-child {
            height: 23px;
            padding-right:10px;
        }
    }
}

.iso-login-alert-success {
    color: $cp-white;
    background: lighten($cp-good-green, 10%);
    font-size: ($default-font-size + 2);
    padding: 15px;
    display: flex;
    flex-direction: row;

    div {
        &:first-child {
            height: 23px;
            padding-right:10px;
        }
    }
}

.pop-pwd-class {
    ul {
        padding: 0 5%;
        li{
            list-style-type: circle;
            color : black;

            ul {
                padding: 0 5%;
                li {
                    list-style-type: square;
                }
            }
            &.req-success {
                color : green;
            }

            &.req-fail {
                color : red;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .iso-login-box {
        & > div {
            padding: 10px 10px 30px 10px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .iso-login-viewport {
        & .iso-logo {
            width: 100%;
            text-align: center;
        }

        & .iso-brand {
            width: 100%;
            text-align: center;
        }
    }

    .iso-login-captcha {
        & > div {
            &:last-child {
                & > span {
                    line-height: 10px;
                    float: right;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .iso-login-captcha {
        & > div {
            &:last-child {
                & > span {
                    line-height: 10px;
                    float: right;
                }
            }
        }
    }
}

.iso-login-box-white {
    position: relative;

    input[type="text"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;
        border-radius: 4px;
        overflow: hidden;
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 100%;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;
        }
    }

    input[type="password"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;
        border-radius: 4px;
        overflow: hidden;
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 100%;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;
        }
    }

    .show-btn {
        position: absolute;
        top: 28px;
        right: 0px;
        height: 20px;
        width: 20px; //square
        text-align: center;

        & > i {
            margin-top: 5px;
            color: $cp-corp-active-color;
        }
    }
}

input::-ms-clear, input::-ms-reveal {
    display: none;
}

