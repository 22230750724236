.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-es {
  background-image: url(../flags/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(../flags/es.svg);
}
.flag-icon-fr {
  background-image: url(../flags/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(../flags/fr.svg);
}
.flag-icon-gb {
  background-image: url(../flags/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(../flags/gb.svg);
}
.flag-icon-us {
  background-image: url(../flags/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../flags/us.svg);
}