input[type=text] {
    @include defaultinput;

    &[disabled=disabled] {
        border: 1px solid lighten($cp-corp-third-color,50%);
        cursor: no-drop;
    }
}

input[type=number] {
    @include defaultinput;
    width: 20%;

    &[disabled=disabled] {
        border: 1px solid lighten($cp-corp-third-color,50%);
        cursor: no-drop;
    }
}

input[type=password] {
    @include defaultinput;

    &[disabled=disabled] {
        border: 1px solid lighten($cp-corp-third-color,50%);
        cursor: no-drop;
    }
}

textarea {
    width: 100%;
    border: 1px solid lighten($cp-corp-third-color, 50%);
    color: $default-font-color;
    padding: 10px;
    border-radius: 0;
    resize: none;
    min-height: 80px;

    &:focus {
        outline: 0;
        border-color: lighten($cp-corp-color,10%);
        background: lighten($cp-corp-color,65%);
    }

    &::-webkit-input-placeholder {
        color: lighten($cp-corp-second-color,50%);
    }

    &:-moz-placeholder {
        color: lighten($cp-corp-second-color,50%);
    }

    &:-ms-input-placeholder {
        color: lighten($cp-corp-second-color,50%);
    }
}

input[type=checkbox] + label {
    @include defaultselectioncontrol;
}

input[type=checkbox]:checked + label {
    @include defaultselectioncontrolselected;
}


input[type=radio] + label {
    @include defaultselectioncontrol;
    vertical-align: top;
}

input[type=radio]:checked + label {
    @include defaultselectioncontrolselected;
}

/*Select Box*/
select {
    width: 100%;
    min-height: 30px;
    border: 1px solid lighten($cp-corp-second-color, 50%);
    color: $default-font-color;
    padding: 0 0 0 10px;

    &:focus {
        outline: 0;
    }

    &:required {
    }

    &:invalid {
        color: lighten($cp-corp-second-color,50%);
    }

    &.w100 {
        width: 100px;
    }

    &.w150 {
        width: 150px;
    }
}

option[value=''][disabled] {
    display: none;
}

option {
    color: $default-font-color;
}



/*List*/
li {
    list-style-type: none;
    padding: 0;

    &:focus {
        outline: none;
        -moz-outline-style: none;
    }
}

.list {
    &.bordered {
        & > label {
            border: 1px solid $cp-light-slate;
            background: $cp-light-slate;
            color: $cp-white;
            width: 100%;
            padding: 10px;
            margin: 0;
        }

        & > ul {
            border: 1px solid lighten($cp-corp-second-color,50%);
        }
    }

    & > label {
        color: $cp-corp-color;
        border-bottom: 1px solid $cp-corp-color;
        width: 100%;
        padding-bottom: 5px;
    }

    & > ul {
        padding: 0;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;

        & > li {
            background: $cp-white;
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            width: 100%;
            border: 1px solid lighten($cp-corp-second-color,50%);
            border-bottom: 0;
            border-right: 0;
            border-left: 1px solid $cp-white;

            &:first-child {
                border-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background: darken($cp-white,3%);
                border-left: 1px solid $cp-corp-color;
            }

            & > a {
                text-decoration: none;
                color: $cp-corp-color;

                &:hover {
                    text-decoration: none;
                    color: $cp-corp-color;
                }
            }

            &.selected {
                background-color: darken($cp-white,3%);
            }
        }
    }

    &.nohover {
        & > ul {
            & > li {
                &:hover {
                    background: $cp-white;
                    cursor: none;
                }
            }
        }
    }

    &.noborder {
        & > ul {
            & li {
                border: 0;
                padding-left: 0;
            }

            &:hover {
                border: 0;
            }
        }

        &.center {
            & > ul {
                & > li {
                    text-align: center;
                }
            }
        }

        &.nohover {
            & > ul {
                li:hover {
                    background: $cp-white;
                    border: 0;
                    cursor: default;
                }
            }

            &.transparent {
                & > ul {
                    & > li {
                        background: transparent;

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    &.nopadding {
        & > ul {
            & > li {
                padding: 0;
            }
        }
    }
}

.iso-floating-list {
  background: $cp-white;

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;


    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      border: 1px solid lighten($cp-corp-third-color,50%);
      border-bottom: 1px solid $cp-white;

      &:last-child {
        border-bottom: 1px solid lighten($cp-corp-third-color,50%);
      }

      &:hover {
        border-color: $cp-corp-color;
        border-bottom: 1px solid $cp-corp-color;
        cursor: pointer;
      }

      i {
        color: $cp-corp-color;
        margin: 10px 20px;
        font-size: 250%;
      }

      h4 {
        margin-left: 20px;
        color: $cp-link-color;
      }

      &.disabled {
        pointer-events: none; //This makes it not clickable
        opacity: 0.5; //This grays it out to look disabled
      }
    }
  }

  &.row {
    background: transparent;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-direction: column;
        border: 1px solid lighten($cp-corp-third-color,50%);
        margin: 5px;
        min-width: 180px;
        border-radius: 8px;
        background: $cp-white;

        h4 {
          margin-left: 0;
        }

        &:hover {
          border-color: $cp-corp-color;
          cursor: pointer;
        }
      }
    }
  }
}

.iso-templates-list {
    background: $cp-white;

    & .nodata {
        display: flex;
        justify-content: center;
        padding: 30px;
        width: 100%;
        background: $cp-white;
        border: 1px solid lighten($cp-corp-third-color,50%);
    }

    ul {
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
            display: flex;
            flex-direction: column;
            border: 1px solid lighten($cp-corp-third-color,50%);
            border-bottom: 1px solid $cp-white;
            width: 100%;

            &:last-child {
                border-bottom: 1px solid lighten($cp-corp-third-color,50%);
            }

            &:hover {
                border-color: $cp-corp-color;
                border-bottom: 1px solid $cp-corp-color;
            }

            div {
                width: 100%;

                &.header {
                    padding: 15px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-content: center;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }

                    h5 {
                        margin-left: 20px;
                        color: $cp-link-color;
                    }

                    span {
                        i {
                            margin: 0 3px;
                            padding:5px;

                            &:hover{
                                background:darken($cp-white, 10%);
                                border-radius:3px;
                            }
                        }
                    }
                }

                &.preview {
                    display: none;
                    background: lighten($cp-corp-third-color,10%);
                    height: 500px;
                    transition: 0.5s ease-in-out top;
                    overflow: auto;
                    position: relative;

                    i {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        color: $cp-error-red;
                        opacity: 0.8;
                        font-size: 200%;
                        cursor: pointer;

                        &:hover {
                            opacity: 1;
                        }
                    }


                    & .content {
                        width: 85%;
                        margin: 20px auto;
                        background: $cp-white;

                        textarea {
                            width: 100%;
                            height: 5em;
                        }
                    }


                    .k-editable-area {
                        border: 0;
                    }

                    .k-editor-inline {
                        border: 0;
                        height: 100%;
                        min-height: 440px;
                        padding: 50px;
                        box-shadow: 10px 0 5px -2px $cp-corp-third-color;

                        ul {
                            list-style-type: disc !important;
                            padding: 0 20px !important;

                            li {
                                list-style-type: disc;
                            }
                        }

                        ol {
                            list-style-type: decimal !important;
                            padding: 0 20px !important;

                            li {
                                list-style-type: decimal;
                            }
                        }

                        p.logo {
                            width: 210px;
                        height: 45px;
                            background: darken($cp-white, 3%);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .k-editor *[contenteditable='false'] {
                        color: lighten($default-font-color, 20%);
                        cursor: default;
                    }
                }
            }
        }
    }
}


.iso-templates-grid {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;

    & .nodata {
        display: flex;
        justify-content: center;
        padding: 30px;
        width: 100%;
        background: $cp-white;
        border: 1px solid lighten($cp-corp-third-color,50%);
    }

    & .template {
        height: 300px;
        width: 250px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border: 1px solid lighten($cp-corp-third-color,50%);
        background: $cp-white;

        &:hover {
            border-color: $cp-link-color;
        }

        & .preview {
            flex-grow: 2;
            cursor: pointer;

            & .content {
                width: 95%;
                margin: 0 auto;
                background: $cp-white;


                textarea {
                    width: 100%;
                    height: 5em;
                }
            }

            .k-editable-area {
                border: 0;
            }

            .k-editor-inline {
                border: 0;
                height: 255px;
                overflow: hidden;
                font-size: 60%;
                padding: 10px 0;

                p.logo {
                    width: 100px;
                    height: 22px;
                    background: darken($cp-white, 3%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                ul {
                    list-style-type: disc !important;
                    padding: 0 20px !important;

                    li {
                        list-style-type: disc;
                    }
                }

                ol {
                    list-style-type: decimal !important;
                    padding: 0 20px !important;

                    li {
                        list-style-type: decimal;
                    }
                }
            }


            .k-editor *[contenteditable='false'] {
                color: lighten($default-font-color, 20%);
                cursor: default;
            }


            &.floating {
                /*position: absolute;
                top:0;
                left:0;
                right:0;*/
                position: fixed;
                top: 50%;
                left: 50%;
                /* bring your own prefixes */
                transform: translate(-50%, -50%);
                border: 1px solid lighten($cp-corp-third-color,50%);
                background: lighten($cp-corp-third-color,10%);
                overflow: auto;
                height: 94%;
                margin-top: 23px;


                & .content {
                    min-height: 95%;
                    width: 85%;
                    margin: 15px auto;
                    background: $cp-white;
                    padding: 40px;
                    box-shadow: 10px 0 5px -2px $cp-corp-third-color;
                }

                .k-editor-inline {
                    height: 100%;
                    width: 100%;
                    font-size: 100%;

                    p.logo {
                        width: 210px;
                        height: 45px;
                    }
                }


                .k-editor *[contenteditable='false'] {
                    color: lighten($default-font-color, 20%);
                    cursor: default;
                }
            }
        }

        & .footer {
            height: 40px;
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            border-top: 1px solid lighten($cp-corp-third-color,50%);

            h5 {
                margin-left: 20px;
                color: $cp-link-color;
            }

            span {
                i {
                    margin: 0 2px;
                    font-size: ($default-font-size + 4);
                    padding:3px;

                    &:hover{
                        background:darken($cp-white, 10%);
                        border-radius:3px;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 768px) {
    .iso-templates-grid {
        & .template {
            & .preview {
                &.floating {
                    width: 740px;
                }
            }
        }
    }
}


@media only screen and (min-width: 992px) {
    .iso-templates-grid {
        & .template {
            & .preview {
                &.floating {
                    width: 960px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .iso-templates-grid {
        & .template {
            & .preview {
                &.floating {
                    width: 1150px;
                }
            }
        }
    }
}

.form-group {
    margin-bottom: $form-group-margin-bottom;
}

.iso-form-group-inline {
    display: flex;
    flex-direction: row;

    div {
        padding: 0 15px;

        label {
            padding-right: 10px;
        }
    }
}

.iso-checked-list-box-container {
    min-height: 132px;
    overflow: auto;
    border: 1px solid lighten($cp-corp-second-color,50%);

    & .iso-checked-list-group {
        margin-bottom: 0;
    }
}

.iso-checked-list-group-item {
    display: block;
    padding: 10px 15px;
    margin-bottom: 0;
    background-color: $cp-white;
    border: 0;
    font-weight: normal;
}

.iso-floating-form-group {
    position: relative;
    width: 100%;
    margin: 0;

    label {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 8px;
        transition: all .2s linear;
        font-size: $default-font-size + 2;
        padding: 0 19px;
        height: 17px;
        line-height: 17px;
        text-transform: uppercase;
        font-weight: 500;
    }

    input {
        height: 60px;
        display: inline-block;
        padding: 0 18px;
        font-size: $default-font-size;
    }

    input:invalid + label {
        opacity: 0;
    }

    input:valid + label {
        opacity: 1;
    }

    input:valid {
        padding-top: 15px;
        font-weight: 300;
    }

    input:focus + label {
        outline: none;
        opacity: 1;
    }

    input:focus {
        padding-top: 15px;
    }

    & .iso-login-form-control-label {
        color: lighten($cp-corp-active-color,10%);
    }
}

/* The checkbox container */
.iso-checkbox-container {
    display: block;
    position: relative;
    padding-left: $checbox-span-padding-left;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.in-grid{
        display:inline;
        &.grouped {
            margin-right: 20px;
        }
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    /* On mouse-over, add a grey background color */
    &:hover {
        input ~ .iso-checkbox-checkmark {
            background-color: darken($cp-white,8%);
            border-color: lighten($cp-corp-color,10%);
        }
    }
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .iso-checkbox-checkmark {
        background-color: $cp-white;
        /*border: 1px solid $cp-white;*/
    }
    /* Show the checkmark when checked */
    input:checked ~ .iso-checkbox-checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    & .iso-checkbox-checkmark:after {
        left: $checkbox-after-left;
        top: 1px;
        width: $checkbox-after-width;
        height: $checkbox-after-height;
        border: solid $cp-corp-color;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

/* Create a custom checkbox */
.iso-checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $checkbox-height;
    width: $checkbox-width;
    background-color: darken($cp-white,5%);
    border: 1px solid lighten($cp-corp-second-color, 50%);
}
/* Create the checkmark/indicator (hidden when not checked) */
.iso-checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* The radiobox container */
.iso-radio-container {
    display: block;
    position: relative;
    padding-left: $radio-span-padding-left;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.in-grid {
        display: inline;
    }

    &.horizontal {
        display: inline-block;
        margin-left: 15px;
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    /* On mouse-over, add a grey background color */
    &:hover {
        input ~ .iso-radio-checkmark {
            background-color: darken($cp-white,8%);
            border-color: lighten($cp-corp-color,10%);
        }
    }
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .iso-radio-checkmark {
        background-color: $cp-white;
        /*border: 1px solid $cp-white;*/
    }
    /* Show the checkmark when checked */
    input:checked ~ .iso-radio-checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    & .iso-radio-checkmark:after {
        top: $radio-after-top;
        left: $radio-after-left;
        width: $radio-after-width;
        height: $radio-after-height;
        border-radius: 50%;
        background: $cp-corp-color;
    }
}

/* Create a custom checkbox */
.iso-radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $radio-height;
    width: $radio-width;
    background-color: darken($cp-white,5%);
    border: 1px solid lighten($cp-corp-second-color, 50%);
    border-radius: 50%;
}
/* Create the checkmark/indicator (hidden when not checked) */
.iso-radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.iso-file-upload {
    background: $cp-white;
    border: 2px dashed lighten($cp-corp-second-color,50%);
    height: 120px;
}

/*overriding the bootstrap class*/
.text-right {
    input {
        padding-right: 10px !important;
    }
}

.iso-tree-view {
    margin: 20px 5px;

    & > ul {
        & > li {
            &.k-item {
                padding-top: 0px;
            }

            div {
                & .k-icon {
                    display: none;
                }

                & .k-in {
                    padding: 5px 10px;
                    border: 1px solid $cp-white;
                    border-radius: 6px;
                    color: $cp-white;


                    &.k-state-selected {
                        color: $cp-white;
                        background: $cp-corp-active-color;
                    }

                    &.k-state-hover {
                        color: $cp-white;
                        background: transparent;
                    }
                }
            }
            /*First level*/
            ul {
                li {
                    &.k-item {
                        border-left: 1px dashed $cp-white;
                        border-top: 0;
                        border-right: 0;
                        padding: 0;
                        /*height: 40px;*/
                        position: relative;
                        margin-left: 12px;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 20px;
                            height: 1px;
                            width: 5%;
                            border-bottom: 1px dashed $cp-white;
                        }

                        div {
                            & .k-in {
                                margin: 6px 5px 6px 15px;
                            }
                        }
                    }
                    /*Second level*/
                    ul {
                        li {
                            &.k-item {
                                border-left: 0 !important;

                                &:before {
                                    left: -10px !important;
                                    width: 12% !important;
                                }

                                div {
                                    & .k-in {
                                        margin-left: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:last-child {

                & > ul {
                    & > li {

                        &:last-child {
                            border-left: 0;

                            &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 1px;
                                height: 50%;
                                border-left: 1px dashed $cp-white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.iso-toggle-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;

    label {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-bottom: 0;
        margin-right: 5px;

        input {
            display: none;

            &:checked + .slider {
                background-color: $cp-corp-active-color;

                &:before {
                    transform: translateX(26px);
                }
            }
        }

        & .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: darken($cp-white, 12%);
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: $cp-white;
                -webkit-transition: .4s;
                transition: .4s;
            }

            &.round {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }

        &.small {
            width: 32px;
            height: 20px;

            input {
                &:checked + .slider {
                    &:before {
                        transform: translateX(12px);
                    }
                }
            }

            & .slider {
                &:before {
                    height: 12px;
                    width: 12px;
                }
            }
        }

        &.light {
            input {
                &:checked + .slider {
                    background-color: $cp-white;

                    &:before {
                        background-color: $cp-link-color;
                    }
                }
            }
        }

        &.green {
            input {

                &:checked + .slider {
                    background-color: lighten($cp-good-green,25%);
                }
            }
        }

        &.red {
            input {

                &:checked + .slider {
                    background-color: $cp-good-green;
                }
            }
        }

        &.dark {
            input {

                &:checked + .slider {
                    background-color: $cp-corp-third-color;
                }
            }
        }

        &.blue {
            input {

                &:checked + .slider {
                    background-color: $cp-link-color;
                }
            }
        }
    }
}
