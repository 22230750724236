﻿.question-header {
  background: #4baee4;
  color: #ffffff;
  border: 1px solid #4baee4;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}
.question-header.active {
  background: #4baee4;
  border: 1px solid #4baee4;
}
.question-header i {
  margin-right: 15px;
  font-size: 24px;
}
.question-header i.end {
  margin-left: auto;
  margin-right: 0;
}
.question-header.plain {
  background: #ffffff;
  color: #6d7274;
  border: 0;
  border-bottom: 1px dashed #d5d7d8;
}
.question-header.plain i {
  color: #6d7274;
}
.question-header.plain ~ .question-content {
  border: 0;
  background: #fafafa;
  border-bottom: 1px solid #d5d7d8;
}
.question-header.white {
  background: #ffffff;
  color: #6d7274;
  border: 0;
}
.question-header.white i {
  color: #6d7274;
}
.question-header.white ~ .question-content {
  border: 0;
  border-top: 1px solid #006ba6;
}
.question-header.green {
  background: #00b16a;
  color: #ffffff;
  border: 1px solid #00b16a;
}
.question-header.green i {
  color: #ffffff;
}
.question-header.green ~ .question-content {
  border: 1px solid #00b16a;
}
.question-header.red {
  background: #fd6048;
  color: #ffffff;
  border: 1px solid #fd6048;
}
.question-header.red i {
  color: #ffffff;
}
.question-header.red ~ .question-content {
  border: 1px solid #fd6048;
}
.question-header.mango {
  background: #ffb900;
  color: #ffffff;
  border: 1px solid #ffb900;
}
.question-header.mango i {
  color: #ffffff;
}
.question-header.mango ~ .question-content {
  border: 1px solid #ffb900;
}

.question-content {
  margin: auto;
  border-top: none;
  position: relative;
  width: 100%;
  border: 1px solid #4baee4;
  border-top: 0;
  background: #ffffff;
}

.question-panel {
  margin: 0px;
  width: 100%;
  border: 1px solid #ccc;
}

.question-panel-block {
  margin: 0px;
  height: auto;
  padding: 0px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.question-panel-block-table {
  border: 0px solid #ccc;
  width: 100%;
}

.question-panel-block-table-col {
  border: 0px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  vertical-align: middle;
}

.question-panel-block div.question-text {
  margin-left: 0px;
  text-align: left;
  padding: 10px;
  padding-top: 10px;
}

.question-panel-block div.question-answer {
  // width: 230px;
  margin-left: 0px;
  text-align: left;
  padding: 10px;
}

.question-panel-block div.question-other {
  // width: 400px;
  margin-left: 5px;
  text-align: left;
  padding: 10px;
}

.label {
  font-family: Roboto;
  font-variant: normal;
  font-size: 12px;
  font-weight: normal;
  color: #6d7274;
  text-decoration: none;
  padding-left: 0px;
  text-align: left;
}

.radioButton {
  font-family: Roboto;
  font-variant: normal;
  font-size: 12px;
  font-weight: normal;
  color: #6d7274;
  text-decoration: none;
  padding-left: 3px;
  text-align: left;
}
