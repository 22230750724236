@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* @import "~bootstrap/dist/css/bootstrap.css"; */

* {
    margin: 0;
    padding: 0;
}

html {
    background-color: #ebeff1;
}

mat-dialog-container {
    padding: 0px !important;
    background-color: transparent !important;
 }

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($iso-lnav-web-theme);

/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/bootstrap'; 
@import './app.content/scss/base/base-dir';
@import './app.content/scss/components/components-dir';
@import './app.content/scss/module/module-dir';



