﻿.iso-panle-separator {
    border-right: 1px solid lighten($cp-corp-second-color,50%);

    &.left {
        border-left: 1px solid lighten($cp-corp-second-color,50%);
    }
}

/*.iso-panel-box {
    background: $cp-white;
    border: 1px solid lighten($cp-corp-second-color,50%);
    height: 100%;
    margin: 10px 0;
    min-height: 80px;

    &.noborder {
        border: 0px;
    }

    &.light {
        padding: 5px;
    }
}*/
.iso-panel-box {
    border: 0px solid lighten($cp-corp-third-color,50%);
    height: 100%;
    margin: $panel-box-margin;
    min-height: $panel-box-min-height;

    &.noborder {
        border: 0px;
    }

    &.bordered {
        border: 1px solid lighten($cp-corp-third-color,50%);

        &.top {
            border-left-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
        }

        &.bottom {
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
        }

        &.mango {
            border-color: $cp-mango;
        }
    }

    &.bg-white {
        background: $cp-white;
        padding: 5px 10px;
    }


    &.light {
        padding: 5px;
    }

    &.bottom-shadow {
        box-shadow: 0px 19px 11px -11px darken($cp-white,10%);
        padding-bottom: 15px;
    }

    &.top-seperator {
        position: relative;
        background: $cp-white;
        padding: 5px;
        border: 1px solid lighten($cp-corp-third-color,50%);

        &:before {
            height: 15px;
            background: $cp-white;
            width: 51%;
            content: '';
            position: absolute;
            top: -15px;
            transform: skewX(-45deg);
            left: 7px;
            border-left: 1px solid lighten($cp-corp-third-color,50%);
        }

        &:after {
            height: 15px;
            background: $cp-white;
            width: 51%;
            content: '';
            position: absolute;
            top: -15px;
            transform: skewX(45deg);
            right: 6px;
            border-right: 1px solid lighten($cp-corp-third-color,50%);
        }

        &.bordered {
            &:before {
                border-top: 1px dashed lighten($cp-corp-third-color,40%);
            }

            &:after {
                border-top: 1px dashed lighten($cp-corp-third-color,40%);
            }
        }
    }

    &.bottom-seperator {
        position: relative;
        background: $cp-white;
        padding: 5px;
        border: 1px solid lighten($cp-corp-third-color,50%);

        &:before {
            height: 15px;
            background: $cp-white;
            width: 51%;
            content: '';
            position: absolute;
            bottom: -15px;
            transform: skewX(45deg);
            left: 7px;
            border-left: 1px solid lighten($cp-corp-third-color,50%);
        }

        &:after {
            height: 15px;
            background: $cp-white;
            width: 51%;
            content: '';
            position: absolute;
            bottom: -15px;
            transform: skewX(-45deg);
            right: 6px;
            border-right: 1px solid lighten($cp-corp-third-color,50%);
        }

        &.bordered {
            &:before {
                border-bottom: 1px dashed lighten($cp-corp-third-color,40%);
            }

            &:after {
                border-bottom: 1px dashed lighten($cp-corp-third-color,40%);
            }
        }
    }
}

.iso-panel-box-header {
    min-height: 40px;
    font-size: $panel-box-header-font-size;
    text-transform: capitalize;
    padding: 10px 0;
    line-height: 23px;
    /*border-bottom: 1px solid lighten($cp-corp-second-color,50%);*/
    /*background: darken($cp-white,1%);*/
    color: darken($default-font-color,10%);

    i {
        float: left;
        /*padding-right: 8px;*/
        background-repeat: no-repeat;
    }

    a {
        i {
            float: right;
            background-repeat: no-repeat;
            opacity: 0.5;
        }
    }

    &.bordered {
        border: 1px solid lighten($cp-corp-third-color,50%);
        padding-left: 10px;
        border-bottom: 0;
        background: darken($cp-white,2%);
    }

    &.noborder {
        border: 0px;
        /*background: $cp-white;*/
        &.bold {
            font-size: ($default-font-size + 2);
            font-weight: bold;
        }
    }

    &.bold {
        font-size: $default-font-size;
        font-weight: bold;
    }

    &.large {
        font-size: ($default-font-size + 4);
    }

    &.small {
        font-size: ($default-font-size - 1);
    }

    &.nocase {
        text-transform: none;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.bluetext {
        color: $cp-corp-color;
    }

    &.underline {
        border-bottom: 1px solid lighten($cp-corp-third-color,30%);
        margin-bottom: 5px;
    }
}

/*.iso-panel-content {
    padding: 10px 8px;
    padding-bottom: 5px;
}*/

.div-header {
    background: #4baee4;
    color: #ffffff;
    border: 1px solid #4baee4;
    padding: 5px 10px;
    position: relative;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;

  }

.iso-panel-content {
    background: $cp-white;
    border: 1px solid lighten($cp-corp-third-color,50%);
    padding: $panel-content-padding;
    padding-bottom: $panel-content-padding-bottom;

    &.noborder {
        border: 0px;
        padding: 0;
    }

    &.border-corp-active {
        border-color: $cp-corp-active-color;
    }

    &.transparent {
        background: transparent;
    }

    &.light {
        background: darken($cp-white, 2%);
    }

    &.pos-realtive {
        position: relative;
    }

    &.rounded {
        border-radius: 8px;
        overflow: hidden;
    }

    &.shadow {
        box-shadow: 0 5px 10px 0 rgba($cp-corp-third-color,.08), 0 2px 4px 0 rgba($cp-white,.07);
        -webkit-box-shadow: 0 5px 10px 0 rgba($cp-corp-third-color,.08), 0 2px 4px 0 rgba($cp-white,.07);
    }

    &.border-bottom-dashed {
        border-bottom: 1px dashed lighten($cp-corp-third-color, 30%);
    }

    &.row-bg-light {
        background: lighten($cp-corp-color, 66%);
    }

    & .caption {
        border-bottom: 5px solid darken($cp-white,3%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        margin-bottom: 20px;

        label {
            font-size: ($default-font-size + 2);
        }
    }

    & .caption01 {
        border-bottom: 1px solid lighten($cp-corp-third-color,30%);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        position: relative;

        &.spaced {
            justify-content: space-between;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            height: 1px;
            width: 20%;
            border-bottom: 1px solid $cp-corp-color;
        }

        &.noborder {
            border-bottom: 0;

            &:before {
                border-bottom: 0;
            }
        }



        label {
            font-size: ($default-font-size + 2);
            color: $cp-corp-color;

            &.white {
                color: $cp-white;
            }

            &.lg {
                font-size: 150%;
            }
        }

        & .close-button {
            position: absolute;
            top: 8px;
            right: 0;
            font-size: ($default-font-size + 4);
            cursor: pointer;
        }
    }

    & .caption02 {
        border-bottom: 1px solid lighten($cp-corp-third-color,35%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1px;
        margin-bottom: 35px;
        position: relative;
        margin-top: 30px;

        label {
            font-size: ($default-font-size + 2);
            background: $cp-white;
            padding: 10px 20px;
            font-weight: normal;
            color: $cp-corp-color;
        }

        &.hoverboxlabel {
            label {
                background: lighten($cp-mango,45%);
            }
        }

        i {
            &.menu {
                position: absolute;
                top: -20px;
                left: 0;
                background: darken($cp-white, 4%);
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background: darken($cp-white, 8%);
                }
            }

            &.options {
                position: absolute;
                top: -20px;
                right: 0;
                background: darken($cp-white, 4%);
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background: darken($cp-white, 8%);
                }
            }
        }
    }

    .overlay-menu {
        min-width: 300px;
        position: absolute;
        z-index: 99;
        top: 0;
        bottom: 0;
        background-color: $cp-corp-third-color;
        background-color: rgba($cp-corp-third-color, 0.9);
        padding: 10px;
        display: none;

        &.right {
            right: 0;
        }

        &.white {
            background-color: rgba($cp-white, 0.9);
            box-shadow: 2px 4px 8px 0 lighten($cp-corp-third-color,20%);
        }

        &.auto-height {
            bottom: auto;
        }

        & .closebtn {
            position: absolute;
            top: 6px;
            right: 6px;
            font-size: ($default-font-size + 8);
            color: $cp-error-red;
            cursor: pointer;
        }

        & .overlay-menu-content {
            height: 100%;
            overflow-y: auto;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none; //for Chrome and Safari
            }
        }
    }

    & .actionpanel {
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: center;

        i.views {
            cursor: pointer;
            padding: 5px;

            &:hover {
                border-radius: 3px;
                background: darken($cp-white, 10%);
            }
        }
    }
}

.iso-panel-fluid {
    display: flex;
    flex-wrap: wrap;
    background: $cp-white;

    &.col-6 {
        & .iso-panel-box {
            width: 16.666%;
        }
    }

    &.col-5 {
        & .iso-panel-box {
            width: 20%;
        }
    }


    &.col-4 {
        & .iso-panel-box {
            width: 25%;
        }
    }

    &.col-3 {
        & .iso-panel-box {
            width: 33.33%;
        }
    }

    &.col-2 {
        & .iso-panel-box {
            width: 50%;
        }
    }

    &.col-1 {
        & .iso-panel-box {
            width: 100%;
        }
    }

    &.rounded {
        border-radius: 8px;
        overflow: hidden;
    }

    &.shadow {
        box-shadow: 0 5px 10px 0 rgba($cp-corp-third-color,.08), 0 2px 4px 0 rgba($cp-white,.07);
        -webkit-box-shadow: 0 5px 10px 0 rgba($cp-corp-third-color,.08), 0 2px 4px 0 rgba($cp-white,.07);
    }


    & .iso-panel-content {
        padding: 15px;
    }
}

.iso-forbidden-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background: $cp-corp-color;
    width: 100%;
    height: 100%;
}

.iso-forbidden-text {
    position: fixed;
    top: 20%;
    left: 40%;
    margin-left: -40px;
    margin-top: -40px;
    font-size: ($default-font-size + 8);
}

/*cards prototype1*/
.iso-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    /*height: 175px;*/
    & .card-content {
        flex: 0 0 18% !important;
        min-width: 170px;
        margin: 0 15px 15px 0;
        height: 140px;
        border: 1px solid lighten($cp-corp-third-color,50%);
        background: $cp-white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;


        &.curved {
            border-radius: 10px;
        }


        & .card-title-small {
            height: 30px;
            font-size: 12px !important;
            text-transform: uppercase;
            padding: 8px 0px 0px 10px !important;
            margin-bottom: 0 !important;
        }
        & .card-title {
            height: 30px;
            font-size: 12px !important;
            text-transform: uppercase;
            padding: 4px 10px 0px !important;
            margin-bottom: 0 !important;
        }

        & .card-avatar {
            height: 80px !important;
            width: 100%;
            position: relative;
            background: rgba($cp-corp-active-color,0.5);
            /*background: linear-gradient(90deg, $cp-corp-active-color 36%,lighten($cp-corp-active-color, 30%) 100%);*/
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            &.black {
                background: rgba(darken($cp-corp-third-color,30%),0.5);
                /*background: linear-gradient(90deg, $cp-corp-third-color 36%,lighten($cp-corp-third-color, 30%) 100%);

                */
            }

            &.red {
                background: rgba($cp-error-red,0.8);
                /*background: linear-gradient(90deg, $cp-error-red 36%,lighten($cp-error-red, 30%) 100%);*/
            }

            &.yellow {
                background: rgba($cp-mango, 0.68);
            }

            &.slate {
                background: rgba($cp-light-slate, 0.9);
            }

            &.green {
                background: rgba($cp-good-green, 0.8);
            }

            i {
                top: 50%;
                transform: translateY(-50%);
                font-size: ($default-font-size * 4);
                position: absolute;
                left: 5px;
                color: $cp-white;
            }
        }

        p {
            & .represented-icon {
                font-size: 16px;
                position: relative;
                top: 3px;
            }
            padding: 4px 10px 0px !important;
            margin-top: 5px;
            margin-bottom: 8px !important;
        }

        & .card-action {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            align-content: flex-end;
            flex: 1;
            padding-right: 10px;
            position: absolute;
            top: 10px !important;

            ul {
                display: flex;
                margin-bottom: 0px;
                align-items: flex-end;
                justify-content: flex-end;

                li {
                    border: 1px solid hsla(0, 0%, 100%, 1);
                    height: 40px;
                    border-radius: 50%;
                    margin: 10px 3px;
                    cursor: pointer;
                    flex: 0 0 auto;
                    background: hsla(0, 0%, 100%, .2);
                    /*&:hover {
                        background: hsla(0, 0%, 100%, .2);
                    }*/
                    i {
                        margin: 10px 9px;
                        color: hsla(0, 0%, 100%, 1);
                    }
                }
            }
        }
    }

    & .newcard {
        border: 2px dashed $cp-corp-active-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: darken($cp-white,2%);
        cursor: pointer;
        border-radius: 10px;
        min-height: 140px !important;
        height: 140px !important;

        &:hover {
            background: $cp-white;
        }

        span {
            margin: 10px;
            color: $cp-corp-active-color;
        }

        i {
            font-size: ($default-font-size + 20);
            color: $cp-corp-active-color;
        }
    }
}

.iso-cards01 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    & .card-content {
        flex: 0 0 30%;
        background: $cp-white;
        position: relative;

        &.selected {
            &:after {
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-bottom-color: $cp-mango;
                content: " ";
                position: absolute;
                bottom: 0;
                left: 45%;
                z-index: 0;
            }
        }

        & .card-title {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-size: ($default-font-size + 6);
                color: $cp-white;
                text-transform: uppercase;

                &.round {
                    background: hsla(0, 0%, 0%, 0.3);
                    border-radius: 50%;
                    margin-left: 6px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    text-align: center;
                }
            }

            &.black {
                /*background: rgba(darken($cp-corp-third-color,30%),0.5);*/
                background: radial-gradient(ellipse at bottom, lighten($cp-corp-third-color,50%) 0%, lighten($cp-corp-third-color,30%) 100%);
            }

            &.blue {
                /*background: rgba($cp-corp-color,0.8);*/
                background: radial-gradient(ellipse at bottom, lighten($cp-corp-active-color,10%) 0%, darken($cp-corp-active-color,5%) 100%);
            }
        }

        & .card-action {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 25px;

            p {
                margin: 0;
                font-size: ($default-font-size + 2);
            }

            i {
                color: rgba($cp-corp-color,0.8);
                cursor: pointer;
            }
        }
    }
}

.iso-cards02 {
    height: 125px;
    background: lighten($cp-corp-active-color,8%);
    position: relative;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;

    &.red {
        background: lighten($cp-error-red,8%);
    }

    &.green {
        background: lighten($cp-good-green,8%);
    }

    i {
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: ($default-font-size + 68);
        color: rgba(0, 0, 0, 0.1);
    }

    div {
        height: 50%;
        padding: 0 20px;

        ul {
            li {
                width: 100%;
                font-size: ($default-font-size + 4);
                color: $cp-white;
            }
        }
    }
}

.iso-cards03 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & .card {
        height: 320px;
        width: 250px;
        margin: 10px;
        box-shadow: 2px 4px 8px 0 lighten($cp-corp-third-color,20%);
        background: $cp-white;

        &.noborder {
            border: 0;
        }

        & .avatar {
            height: 160px;
            position: relative;

            i {
                position: absolute;
                top: 55px;
                left: 50%;
                transform: translateX(-50%);
                background: $cp-corp-color;
                color: $cp-white;
                font-size: ($default-font-size + 22);
                border-radius: 50%;
                padding: 25px;

                &.green {
                    background: $cp-good-green;
                }

                &.red {
                    background: $cp-error-red;
                }
            }
        }

        & .content {
            padding: 10px;
            text-align: center;
            font-size: ($default-font-size + 6);
        }
    }
}

.iso-cards04-container {
    overflow-y: auto;
    overflow-x: hidden;
    /*-ms-overflow-style: none; //for IE10
    &::-webkit-scrollbar {
        display: none; //for Chrome and Safari
    }*/
}

.iso-cards04 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: $cp-white;


    & .card {
        flex: 0 0 30%;
        /*max-width: 25%;*/
        background: darken($cp-white, 1%);
        box-shadow: 0 5px 10px 0 rgba($cp-corp-third-color,.08), 0 2px 4px 0 rgba($cp-white,.07);
        margin: 2px 5px 2px 2px;
        position: relative;
        border: 1px solid darken($cp-white,3%);

        & .content {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: flex-end;
            align-items: flex-end;
            background: $cp-white;
            border-radius: 3px;
            margin-bottom: .25rem;
            position: relative;

            i {
                border-radius: 50%;
                padding: 10px;
                box-shadow: 0 0.125rem 0.25rem rgba($cp-corp-third-color,.3);
                border: 3px solid $cp-white;
                margin-bottom: 1rem;
                font-size: 250%;
                background: rgba($cp-corp-active-color,0.5);
                color: $cp-white;
                position: absolute;
                left: 5px;

                &.black {
                    background: rgba(darken($cp-corp-third-color,30%),0.5);
                }

                &.red {
                    background: rgba($cp-error-red,0.8);
                }
            }

            p {
                color: $cp-corp-active-color;
                font-size: $default-font-size;
                margin-bottom: 5px;
            }
        }

        & .actionitemsbackdrop {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: none;
            background: $cp-corp-third-color;
            opacity: 0.8;
            z-index: 1;
        }

        & .actionitems {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: none;
            z-index: 2;
            transition: all 0.3s ease-in-out;

            ul {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                height: 100%;

                li {
                    border: 3px solid $cp-white;
                    border-radius: 50%;
                    background: darken($cp-white, 5%);
                    color: $cp-white;
                    cursor: pointer;
                    padding: 6px;
                    display: flex;
                    justify-content: center;
                    margin: 3px;


                    &.red {
                        color: $cp-error-red;
                    }

                    &.blue {
                        color: $cp-corp-active-color;
                    }

                    i {
                        font-size: ($default-font-size + 8);
                    }
                }
            }
        }

        &:hover {
            & .actionitemsbackdrop {
                display: block;
            }

            & .actionitems {
                display: block;
            }
        }

        &.active{
            border:1px solid $cp-corp-color;
        }
    }
}

.iso-cards-row {
    width: 100%;
    box-shadow: 1px 1px 1px 1px lighten($cp-corp-third-color,50%);
    background: $cp-white;

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        li {
            width: 100%;
            min-height: 75px;
            border-right: 1px solid lighten($cp-corp-third-color,50%);
            padding: 20px 15px;
            display: flex;
            flex-direction: column;

            &:last-child {
                border-right: 0;
            }

            label {
                border-bottom: 1px solid lighten($cp-corp-third-color,50%);
                padding-bottom: 8px;
            }
        }
    }
}

.iso-cards-col {
    width: 100%;
    box-shadow: 1px 1px 1px 1px lighten($cp-corp-third-color,50%);
    background: $cp-white;

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        li {
            width: 100%;
            min-height: 75px;
            border-bottom: 1px solid lighten($cp-corp-third-color,50%);
            padding: 20px 15px;
            display: flex;
            flex-direction: column;
        }
    }
}

.iso-review-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    & .nodata {
        display: flex;
        justify-content: center;
        padding: 30px;
        width: 100%;
        background: $cp-white;
    }

    &.white {
        background: $cp-white;
        border-radius: 6px;
        padding-top: 10px;
    }

    & .card-content {
        /*flex: 0 0 20%;*/
        margin: 0 15px 15px 0;
        /*height: 100%;*/
        /*border-bottom: 1px solid lighten($cp-corp-third-color,50%);*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 15px 10px 40px 10px;
        width: 100%;
        box-shadow: 0 1px 2px darken($cp-white,15%), 0 1px 3px 1px darken($cp-white,15%);
        background: $cp-white;
        border-radius: 6px;
        overflow: hidden;

        &:hover {
            /*border-bottom-color: $cp-corp-active-color;*/
            box-shadow: 0 1px 2px darken($cp-white,25%), 0 1px 3px 1px darken($cp-white,25%);
            color: $cp-corp-third-color;

            & .content {
                & .edit {
                    /*display: block;*/
                    right: -50px;
                }
            }
        }


        & .header {
            color: $cp-corp-active-color;
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            height: 20px;
            font-size: ($default-font-size + 1);

            & .item-center {
                align-items: center;
            }

            & .item-right {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;


                &.row {
                    flex-direction: row;
                    align-content: center;
                    align-items: center;
                }
            }

            & .bold {
                font-weight: bold;
                font-size: ($default-font-size + 2);
            }

            & .edited {
                background: lighten($cp-error-red,10%);
                color: $cp-white;
                padding: 3px 8px;
                margin-left: 10px;
                border-radius: 16px;
                font-size: 85%;
            }
        }

        & .content {
            width: 100%;
            margin-top: 20px;

            & .edit {
                position: absolute;
                height: 110px;
                width: 110px;
                right: -150px;
                bottom: -40px;
                padding: 18px 25px;
                border-radius: 50%;
                background-color: rgba($cp-corp-color, 0.1);
                transition: all 0.3s ease-in-out;
                z-index: 999;

                i {
                    font-size: ($default-font-size + 14);
                }
            }

            textarea {
                width: 100%;
                height: 5em;
            }
        }

        &.typeA {
            padding-left: 70px;
            position: relative;
            box-shadow: none;
            border-bottom: 0px dashed darken($cp-white, 15%);
            border-radius: 0;
            margin: 0;
            margin-bottom: 20px;
            padding-bottom: 10px;


            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                box-shadow: none;

                & .action {
                    /*display: block;*/
                    right: 6px;
                }
            }

            & .noteicon {
                position: absolute;
                top: 22px;
                left: 6px;
                color: $cp-corp-second-color;
                border: 1px solid rgba($cp-corp-color, 0.2);
                border-radius: 50%;
                padding: 8px;
            }

            & .content-body {
                background: rgba($cp-corp-color, 0.06);
                width: 99%;
                padding: 0px;
                border-radius: 4px;
                position: relative;


                & .items {
                    overflow: hidden;
                    position: relative;
                    padding: 10px 10px 20px 20px;
                }

                & .header {
                    color: darken($cp-corp-third-color, 5%);
                    margin-bottom: 3px;

                    span {
                        margin-right: 10px;
                    }

                    & .bold {
                        font-weight: 500;
                        font-size: ($default-font-size + 3);
                    }
                }

                & .k-editor-inline {
                    background: rgba($cp-corp-color, 0);
                    margin-right: 30px;
                    color: $cp-corp-third-color;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    top: 10px;
                    right: 100%;
                    left: -27px;
                    margin-right: -3.5rem;
                    border-right: 10px solid rgba($cp-corp-color, 0.06);
                    border-bottom: 17px solid transparent;
                    border-left: 17px solid transparent;
                    border-top: 17px solid transparent;
                }
            }

            & .action {
                position: absolute;
                right: -100px;
                top: 6px;
                transition: all 0.3s ease-in-out;
                z-index: 999;
                display: flex;
                align-content: center;
                flex-direction: column;

                i {
                    font-size: ($default-font-size + 4);
                    border-radius: 50%;
                    padding: 8px;
                    cursor: pointer;
                    margin-bottom: 7px;

                    &.edit {
                        background-color: rgba($cp-corp-color, 0.25);
                        color: rgba($cp-corp-color, 0.8);

                        &:hover {
                            color: $cp-corp-color;
                        }
                    }

                    &.delete {
                        background-color: rgba($cp-error-red, 0.2);
                        color: rgba($cp-error-red, 0.5);

                        &:hover {
                            color: $cp-error-red;
                        }
                    }
                }
            }
        }

        &.typeB {
            padding-left: 70px;
            position: relative;
            box-shadow: none;
            border-bottom: 1px dashed darken($cp-white, 15%);
            padding-bottom: 15px;
            border-radius: 0;
            padding-top:0;


            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                box-shadow: none;
            }

            & .noteicon {
                position: absolute;
                top: 8px;
                left: 6px;
                color: $cp-corp-second-color;
                border: 1px solid rgba($cp-corp-color, 0.2);
                border-radius: 50%;
                padding: 8px;
            }

            & .header {
                display: flex;
                flex-direction: column;
                /*justify-content: flex-end;
                align-items: flex-end;*/
                margin-bottom: 20px;
                color: $default-font-color;
                margin-bottom:18px;
                height:35px;
            }

            & .content {
                margin-top: 0;
                background: rgba($cp-corp-color, 0.06);
                padding: 10px;
                border-radius: 4px;
                position: relative;
                padding-bottom:20px;

                & .k-editor-inline {
                    background: rgba($cp-corp-color, 0);
                    margin-right: 30px;
                    color: $cp-corp-third-color;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    top: 10px;
                    right: 100%;
                    left: -27px;
                    margin-right: -3.5rem;
                    border-right: 10px solid rgba($cp-corp-color, 0.06);
                    border-bottom: 17px solid transparent;
                    border-left: 17px solid transparent;
                    border-top: 17px solid transparent;
                }
            }
        }
    }

    .k-editable-area {
        border: 0;
    }

    .k-editor-inline {
        border: 0;
        max-height: 250px;
        padding: 0;

        ul {
            list-style-type: disc !important;
            padding: 0 20px !important;

            li {
                list-style-type: disc;
            }
        }

        ol {
            list-style-type: decimal !important;
            padding: 0 20px !important;

            li {
                list-style-type: decimal;
            }
        }
    }

    table {
        &.k-editor {
            border: 0;
            max-height: 100px;


            & .k-content {
                padding: 0;
                max-height: 100px;
            }
        }
    }

    &.notes {
        & .card-content {
            background: lighten($cp-mango,40%);
        }

        .k-editor-inline {
            background: lighten($cp-mango,40%);
        }
    }
}

.iso-col-widgets {
    padding: 5px;
    display: flex;
    flex-grow: 1;
    position: relative;
    width: 33.33%;

    & > div {
        background-color: rgba($cp-corp-active-color, 0.6);
        padding: 30px 30px 10px 30px;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;

        &.active {
            border: 0px solid $cp-corp-color;
            box-shadow: 0 4px 50px -10px $cp-corp-third-color;
            background-color: rgba($cp-good-green, 0.8);

            & .header {
                color: $cp-white;

                p {

                    span.round {
                        background-color: $cp-white;
                        color: $cp-good-green;
                    }
                }
            }
        }

        & .header {
            position: relative;
            color: $cp-white;

            p {
                margin: 0;
                display: inline-block;
                font-weight: bold;
                width: 100%;
                margin-bottom: 15px;

                &.large {
                    font-size: 140%;
                }

                span.round {
                    margin-left: 5px;
                    background-color: $cp-white;
                    color: $cp-corp-active-color;
                    border-radius: 50%;
                    padding: 3px 9px;
                }
            }

            &:before {
                content: "";
                position: absolute;
                background: rgba($cp-white,.2);
                border-radius: 50%;
                right: -70px;
                bottom: -50px;
                width: 100px;
                height: 100px;
            }

            &:after {
                content: "";
                position: absolute;
                background: rgba(255,255,255,.2);
                border-radius: 50%;
                right: -65px;
                bottom: 25px;
                width: 55px;
                height: 55px;
            }
        }

        & .content {
            display: none;
            opacity: 0;
            position: absolute;
            z-index: 9999;
            min-width: 100%;
            top: 0;
            left: 0;
            background-color: $cp-white;
            border-radius: 4px;
            box-shadow: 0px 4px 8px 0px lighten($cp-corp-third-color,10%);
            transform: scale3d(.9,.9,1);
            -webkit-transition: opacity 250ms cubic-bezier(0.8, 0.0, 0.6, 1) 0ms,transform 250ms cubic-bezier(0.8, 0.0, 0.6, 1) 0ms;
            transform-origin: left top;

            &:hover {
                -webkit-transition: opacity 250ms cubic-bezier(0.4, 0.0, 0.2, 1) 0ms,transform 250ms cubic-bezier(0.4, 0.0, 0.2, 1) 0ms;
                transform: scale3d(1,1,1);
            }

            & > div {
                padding: 15px;

                & .iso-widget01 {
                    padding: 0;

                    & .widget-content {
                        padding-top: 5px;
                    }
                }
            }
        }

        &:hover {
            & .content {
                display: block;
                opacity: 1;
            }
        }
    }
}

.iso-action-buttons-as-link {
    display: flex;
    justify-content: center;

    &.row {
        flex-direction: row;
    }

    button {
        border: 1px solid $cp-corp-active-color;
        padding: 15px 0;
        background: $cp-white !important;
        border-radius: 6px;
    }
}
