﻿.iso-form-control-label {
    @include font($form-lable-size, normal);
}

.iso-form-control-label-small {
    @include font($form-lable-size-small, normal);
}

.iso-text-valign-center::before{
  content:"";
  margin-left:calc(100% - 75%);
}

.iso-text-error{
    color:$cp-error-red;
    padding:15px 0;

    &.center{
        text-align:center;
    }
}

@media only screen and (max-width: 480px) {
    .iso-form-control-label {
        @include font(0,normal);
    }
    .iso-form-control-label-small {
        @include font(0,normal);
    }
}

@media only screen and (max-width: 768px) {
    .iso-form-control-label {
        @include font(0,normal);
    }
    .iso-form-control-label-small {
        @include font(0,normal);
    }
}

@media only screen and (max-width: 1024px) {
    .iso-form-control-label {
        @include font(0,normal);
    }
    .iso-form-control-label-small {
        @include font(0,normal);
    }

    h1 {
        @include font(6,normal);
    }
}
