.modal {
    text-align: center;
    padding: 0 !important;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    max-width: 600px;
    &.modal-l {
        max-width: 700px;
    }
    &.modal-xl {
        max-width: 900px;
    }
}

.modal-alerts {
    z-index: 9999 !important;

    .modal-dialog {
        width: auto;
        min-width: 400px;
        max-width: 1000px;
    }

    &.error {
        .modal-content {
            border: 0;
            border-left: 5px solid $cp-error-red;
            background: $cp-white;
            padding: 10px 5px;
        }
    }

    &.warning {
        .modal-content {
            border: 0;
            border-left: 5px solid $cp-mango;
            background: $cp-white;
            padding: 10px 5px;
        }
    }

     &.info {
        .modal-content {
            border: 0;
            border-left: 5px solid $cp-good-green;
            background: $cp-white;
            padding: 10px 5px;
        }
    }
}

.modal-alerts + .modal-backdrop {
    background: $cp-corp-third-color;
}

.modal-header {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: $modal-header-padding;
    i {
        font-size: ($default-font-size + 6) !important;
    }
    &.error {
        color: $cp-corp-third-color;
        border: 0;
        padding: 15px;

        i {
            color: $cp-error-red;
            font-size: ($default-font-size + 23);
            position: absolute;
            top: 30%;
            left: 14px;
            transform: translateY(-50%);
            z-index: 9999;
        }

        span {
            padding-left: 49px;
            line-height: 10px;
            font-weight: bold;
        }
    }

    &.warning {
        color: $cp-corp-third-color;
        border: 0;
        padding: 15px;

        i {
            color: $cp-mango;
            font-size: ($default-font-size + 23);
            position: absolute;
            top: 30%;
            left: 14px;
            transform: translateY(-50%);
            z-index: 9999;
        }

        span {
            padding-left: 49px;
            line-height: 10px;
            font-weight: bold;
        }
    }

     &.info {
        color: $cp-corp-third-color;
        border: 0;
        padding: 15px;

        i {
            color: $cp-good-green;
            font-size: ($default-font-size + 23);
            position: absolute;
            top: 30%;
            left: 14px;
            transform: translateY(-50%);
            z-index: 9999;
        }

        span {
            padding-left: 49px;
            line-height: 10px;
            font-weight: bold;
        }
    }
}

.modal-body {
    p {
        margin: 0;
        font-size: ($default-font-size + 2);
    }

    &.error {
        color: $default-font-color;
        padding: 0;

        p {
            padding: 0 65px;
        }
    }

    &.warning {
        color: $default-font-color;
        padding: 0;

        p {
            padding: 0 65px;
        }
    }

     &.info {
        color: $default-font-color;
        padding: 0;

        p {
            padding: 0 65px;
        }
    }
}

.modal-footer {
    padding: $modal-footer-padding;
    padding-right: 0;

    &.error {
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        padding-left: 64px;

        button {
            border: 0px solid $cp-white;
            color: $cp-white;
            background: $cp-error-red;
        }
    }

    &.warning {
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        padding-left: 64px;

        button {
            border: 0px solid $cp-white;
            color: $cp-white;
            background: $cp-mango;
        }
    }
     &.info {
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        padding-left: 64px;

        button {
            border: 0px solid $cp-white;
            color: $cp-white;
            background: $cp-good-green;
        }
    }
}

.modal-content {
    border: none;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 5px 7px;
}


.modal-md {
  width: 600px;
  max-width: 600px;
}

.modal-large {
    width: 900px;
    max-width: 900px;
}

.iso-error-header {
    background: $cp-error-red;
    color: $cp-white;
}

.iso-warning-header {
    background: $cp-mango;
    color: $cp-white;
}

.iso-info-header {
    background: $cp-mango;
    color: $cp-white;
}

.iso-modal-header {
    color: $cp-corp-color;
    position: relative;
    height: 40px;
    border-bottom: 0;

    h3 {
        margin: 10px 5px;
        color: $cp-corp-color;
        text-transform: uppercase;
    }

    i {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $cp-corp-color;
    }

    &.iso-side-modal-header {
        background: $cp-corp-color;
        color: white;
        i {
            color:white;
        }
    }
}

.iso-modal-header-icons {
    position: relative;
    margin-right: 5px;
    top: 6px;
}

.iso-modal-header-text {
    font-size: ($default-font-size + 4);
    line-height: 40px;
}

.iso-modal-close {
    float: right;
    font-size: ($default-font-size + 9);
    font-weight: bold;
    line-height: 1;
    text-shadow: 0 1px 0 $cp-white;
    filter: alpha(opacity=80);
    opacity: .8;
    color: $cp-corp-color;
}

.iso-modal-close:hover,
.iso-modal-close:focus {
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=100);
    opacity: 1;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 90%;
    height: 100%;
    max-width: 1000px;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 60px;
}

/*Left*/
.modal.left.fade .modal-dialog {
    left: -90%;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
    left: 0;
}

.modal.left.fade.in.large .modal-dialog {
    max-width: 1400px;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -80%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

.modal.right.fade.in.large .modal-dialog {
    max-width: 1400px;
}

/*View as modal*/
.modal-fullheight {
    & .modal-dialog {
        background: $cp-white;
        height: 95%;
        margin: 0;
    }

    & .modal-backdrop {
        background-color: darken($cp-white,6%);

        &.in {
            opacity: 0.9;
        }
    }

    & .modal-content {
        box-shadow: none;
        border: 0;

        & .iso-modal-header {
            height: 115px;
            padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            h3 {
                margin-bottom: 25px;
            }

            i {
                top: 20px;
                right: 20px;
                font-size: ($default-font-size + 14);
            }
        }

        & .modal-body {
            padding: 0 30px;
        }

        & .modal-footer {
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            padding: 0 30px;

            button {
                padding: 10px 16px;
                width: 150px;
            }
        }
    }
}

.iso-modal-action-confirm {


    & .success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        i {
            font-size: ($default-font-size * 5);
            padding: 10px;
            border-radius: 50%;
            border: 1px solid $cp-good-green;
            color: $cp-good-green;
        }

        span {
            padding: 20px 20px 40px 20px;
            font-size: ($default-font-size + 8);
            color: $cp-good-green;
        }

        a {
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: ($default-font-size + 4);
        }

        button {
            padding: 10px 16px;
            width: 150px;
        }
    }

    & .failed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        i {
            font-size: ($default-font-size * 5);
            padding: 10px;
            color: $cp-error-red;
        }

        span {
            padding: 20px 20px 40px 20px;
            font-size: ($default-font-size + 8);
            color: $cp-error-red;
        }
    }
}

/*Blur the background*/
body.modal-open .iso-main-view {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    height: 100%;
}

/* MODAL STYLES
-------------------------------*/
iso-assess-modal {
    /* modals are hidden by default */
    display: none;

    .iso-assess-modal {
        /* modal container fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* z-index must be higher than .iso-assess-modal-background */
        z-index: 1000;

        /* enables scrolling for tall modals */
        overflow: auto;

        .iso-assess-modal-body {
            padding: 20px;
            background: #fff;

            /* margin exposes part of the modal background */
            margin: 200px;
        }
    }

    .iso-assess-modal-background {
        /* modal background fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /* semi-transparent black  */
        background-color: #000;
        opacity: 0.75;

        /* z-index must be below .iso-assess-modal and above everything else  */
        z-index: 900;
    }
}

body.iso-assess-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}

.view-side-form {
    z-index: 1050;
    position: fixed;

    .side-form {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        overflow: auto;
        background: #fff;
        border-left: 1px solid #e0e0e0;
    }
}
