﻿.accordion {
    @include accordion(lighten($cp-corp-second-color,20%),$cp-white,lighten($cp-corp-second-color,20%));
    padding: 15px 20px;
    position: relative;
    height: $accordion-height;
    cursor: pointer;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    font-size: ($default-font-size + 2);

    &.active {
        @include accordion_active(lighten($cp-corp-second-color,20%),lighten($cp-corp-second-color,20%));
    }

    i {
        margin-right: 15px;
        font-size: ($default-font-size + 12);

        &.end {
            margin-left: auto;
            margin-right: 0;
        }
    }
    
    &.secondary {
        padding: 7px 20px;
        height: ($accordion-height - 10);
        font-size: ($default-font-size - 1);

        i {
            font-size: ($default-font-size + 10);
        }
    }
    
    &.plain {
        background: $cp-white;
        color: $default-font-color;
        border: 0;
        border-bottom: 1px dashed lighten($cp-corp-third-color,50%);

        i {
            color: $default-font-color;
        }

        & ~ .accordion-content {
            border: 0;
            background: darken($cp-white,2%);
            border-bottom: 1px solid lighten($cp-corp-third-color,50%);
        }
    }

    &.white {
        background: $cp-white;
        color: $default-font-color;
        border: 0;

        i {
            color: $default-font-color;
        }

        & ~ .accordion-content {
            border: 0;
            border-top: 1px solid $cp-corp-color;
        }
    }

    &.green {
        background: $cp-good-green;
        color: $cp-white;
        border: 1px solid $cp-good-green;

        i {
            color: $cp-white;
        }

        & ~ .accordion-content {
            border: 1px solid $cp-good-green;
        }
    }

    &.red {
        background: lighten($cp-error-red,6%);
        color: $cp-white;
        border: 1px solid lighten($cp-error-red,6%);

        i {
            color: $cp-white;
        }

        & ~ .accordion-content {
            border: 1px solid lighten($cp-error-red,6%);
        }
    }

    &.mango {
        background: $cp-mango;
        color: $cp-white;
        border: 1px solid $cp-mango;

        i {
            color: $cp-white;
        }

        & ~ .accordion-content {
            border: 1px solid $cp-mango;
        }
    }
}

.accordion-content {
    margin: auto;
    padding: 30px 15px;
    /*padding-bottom: 5px;*/
    border-top: none;
    position: relative;
    width: 100%;
    border: 1px solid lighten($cp-corp-second-color,20%);
    border-top: 0;
    background: $cp-white;
}
