﻿.alert {
    margin: 10px 0 10px 0;
    padding-right: 15px;
}

.alert-danger {
    @include alertmessages($cp-error-red)
}

.alert-success {
    @include alertmessages($cp-good-green)
}

.alert-modal {
    margin: 0 0 15px 0;
    padding-right: 15px;
}

.iso-coa-messages {
    background: $cp-error-red;
    height: 50px;
    margin-bottom: 10px;
    line-height: 50px;
    color: $cp-white;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    -webkit-animation-name: coamessages; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 3s; /* Chrome, Safari, Opera */
    animation-name: coamessages;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes coamessages {
    from {
        background-color: $cp-error-red;
    }

    to {
        background-color:darken($cp-error-red,20%);
    }
}

/* Standard syntax */
@-moz-keyframes coamessages {
    from {
        background-color: $cp-error-red;
    }

    to {
        background-color: darken($cp-error-red,20%);
    }
}

@keyframes coamessages {
    from {
        background-color: $cp-error-red;
    }

    to {
        background-color: darken($cp-error-red,20%);
    }
}


.iso-coa-messages01 {
    transition: all .2s linear;
    background: rgba(darken($cp-corp-third-color,34%),0.6);

    &.error {
        & .content {
            color: $cp-error-red;
            &:before {
                background-color: $cp-error-red;
            }
        }
    }

    &.warning {
        & .content {
            color: $cp-mango;
            &:before {
                background-color: $cp-mango;
            }
        }
    }

    & .content {
        padding: 20px 20px 20px 36px;
        position: relative;
        font-size: ($default-font-size + 4);
        color: lighten($cp-corp-active-color,15%);

        &:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            background-color: lighten($cp-corp-active-color,15%);
            border-radius: 50%;
        }
    }
}

.iso-global-message {
    position: fixed;
    bottom: 0;
    background: $cp-good-green;
    width: 100%;
    color: $cp-white;
    z-index: 997;
    min-height: 60px;
    padding: 8px 0;
    font-size: $default-font-size + 2;

    &.warning {
        background: $cp-mango;
    }
}

.iso-page-mask {
    position: absolute;
    z-index: 997; // header is 999. Mask needs to be under header
    background: rgba($cp-corp-third-color, 0.5);
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 10px;

    .caption {
        margin-top: 20%;
        padding: 0px 10px;
        display: inline-block;
        background: rgba($cp-corp-third-color, 0.8);
        color: $cp-mango;
        h2 {
            color: $cp-error-red;
        }
    }
}
