﻿table {
    background: $cp-white;

    caption {
        padding: 0;
        padding-left: 10px;

        button {
            span {
                &:before {
                    margin-right: 8px;
                }
            }
        }
    }
}

.table-hover {
    tbody {
        tr {
            &:hover {
                background: lighten($cp-corp-color,65%);
            }
        }

        td {
            a {
                &:hover {
                    color: $cp-corp-color;
                }
            }
        }
    }
}

.iso-table-col-trash {
    & > span {
        color: $cp-error-red;
        font-size: ($default-font-size + 2);
        padding: 0;
    }
}

.iso-table-col-edit {
    & > span {
        color: $cp-good-green;
        font-size: ($default-font-size + 2);
        padding: 0;
    }
}

.iso-grid {
    /*background: lighten($cp-corp-color,65%);*/
    background: $cp-white;
    /*height:$grid-height;*/
    table {
        border-collapse: collapse;

        thead {
            th:first-child,
            th + th[style*="none"] {
                border-left: 0px;
            }

            th:last-child,
            th[style*="none"] + th {
                border-right: 0px;
            }
        }

        tbody {
            tr {
                &.selected {
                    background: lighten($cp-corp-color,55%);
                }
            }

            tr:last-child {
                td {
                    border-bottom: 0;
                }
            }

            td {
                vertical-align: middle;
                padding: $grid-table-tbody-td-padding;
                color: $default-font-color;
            }

            td:first-child,
            td[style*="none"] + td {
                border-left: 0px;
            }

            td:last-child,
            td[style*="none"] + td {
                border-right: 0px;
            }
        }
    }

    & .k-grid-header-wrap {
        border: 0;
    }

    & .k-grid-header {
        padding: 8px 0;
        background: darken($cp-white,2%);

        th {
            &.k-header {
                font-weight: bold;
                color: $default-font-color;
                padding-top: 2px;
                padding-bottom: 2px;
                background: darken($cp-white,2%);
                padding-left: 7px;
                vertical-align: middle;
            }
        }

        &.nodata {
            background: $cp-white;
        }
    }

    & .k-grid-footer {
        padding: 8px 0;
        background: darken($cp-white,2%);
        td {
            font-weight: bold;
            color: $default-font-color;
            padding-top: 2px;
            padding-bottom: 2px;
            background: darken($cp-white,2%);
            padding-left: 7px;
            vertical-align: middle;
            border-width: 0px;
        }        
    }

    & .k-grid-footer-wrap {
        border: 0;
    }

    & .k-grid-content {
        table {
            tbody {
                td {
                    border: 0;
                    border-bottom: 1px solid darken($cp-white, 8%);
                }
            }
        }
    }

    & .k-header {
        & > .k-link {
            color: $default-font-color;
        }
    }

    & .k-edit-cell {
        input[type=checkbox] {
            float: left;
            margin: 0.6em auto;
            width: 100%;

            &:focus {
                outline: 0;
            }
        }
    }

    & .k-pager-wrap {
        background: $cp-white;
        min-height: auto;
    }

    & .iso-grid-row-height-35 {
        height: $grid-row-height-35;
    }

    & .iso-grid-row-height-45 {
        height: 45px;
    }

    & .iso-grid-row-height-50 {
        height: 50px;
    }

    & .iso-grid-row-height-60 {
        height: 60px;
    }

    & .iso-grid-row-height-70 {
        height: 70px;
    }
}

.iso-grid-no-data {
    color: $cp-error-red;
    /*background: lighten($cp-corp-color,65%);*/
    /*background:  darken($cp-white,2%);*/
    /*background:lighten($cp-error-red, 40%);*/
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 110%;
}

.iso-grid-error-message {
    list-style-type: none;
    margin-bottom: 0;

    & > li {
        display: inline-block;
        padding: 5px;
        width: 100%;
    }
}

.iso-grid-cell-selected {
    background-color: #bce5e8 !important;
    color: #000000  !important; }
    .iso-medical-medcode-selected td {
      color: #000000 !important; }
    .iso-medical-medcode-selected a {
      color: #000000 !important; }
      .iso-medical-medcode-selected a:hover {
        color: #000000 !important; }
      .iso-medical-medcode-selected a:focus {
        color: #000000 !important; }
    .iso-medical-medcode-selected i {
      color: #000000 !important; }

.iso-editable-grid-cell {
    background: lighten($cp-mango, 45%);
    /*border: 0.15em solid lighten($cp-mango, 5%) !important;*/
    color: $cp-corp-active-color;
}

.iso-disable-grid-cell {
    background: lighten($cp-corp-third-color, 55%);
    color: $cp-corp-third-color;
    pointer-events: none;
}

.iso-mandatory-grid-cell {
    background: lighten($cp-error-red, 35%);
    border: 0.15em solid $cp-error-red !important;
    color: $cp-corp-active-color;
}

.iso-editable-grid-container-error {
    text-align: left;
    margin-top: 10px;

    &.float {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 998;
    }
}

.iso-grid-row-error-triangle {
    border-left: 15px solid $cp-error-red;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
}

.iso-grid-row-warning-triangle {
    border-left: 15px solid $cp-mango;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
}

.iso-grid-header-button {
    border: 1px solid lighten($cp-corp-third-color, 48%);
    border-bottom: 0;
    padding: 5px 10px;
}

.iso-grid-detail-row {
    background: $cp-white;


    &:hover {
        background: $cp-white !important;
    }

    & .iso-grid-detail-asmodal {
        width: 100%;
        padding: 0px;
        margin: 5px 0;
        position: relative;
        background: $cp-white;

        & .modal {
            position: relative;
            box-shadow: 0px 4px 3px -3px $cp-corp-third-color;
            border: 1px solid $cp-corp-third-color;
            border-radius: 8px;
            background: $cp-white;
            z-index:998 !important;
        }

        & .modal-backdrop {
            /*position: static;*/
            background:$cp-white;
            opacity:0.5;
            z-index:988 !important;
        }

        & .modal-dialog {
            background: $cp-white;
            height: 95%;
            margin: 0;
            width: 100%;
        }

        & .modal-content {
            box-shadow: none;
            border: 0;
        }
    }
}

.iso-grid-detail-label {
    font-weight: bold;
}

.iso-grid-detail-field {    
    margin: 5px 10px;
}

.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.discontinued{
    opacity: 0.5;
    // background-color: burlywood;
}

.deleted{
    opacity: 0.5;
    text-decoration: line-through;
}

.row-deleted{
    opacity: 0.7;
}

.row-red{
    color: #f00;
    background-color: #ced;
}
