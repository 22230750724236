﻿.iso-main-view {
    transition: margin-left .5s;
}

.iso-content-viewport {
    margin: 0;
    width: 100%;
    min-height: 100%;
    padding: 0;
    position: relative;
    padding-bottom: 60px;
    margin-top: $content-viewport-margin-top;
    /*height:79vh;
    overflow-y:scroll;*/
    & .container {
        /*background: $cp-white;*/
        padding-top: $content-viewport-container-padding-top;
        padding-bottom: 60px;
    }
}

.iso-system-error {
    color: $cp-error-red;
    font-size: ($default-font-size + 4);
    text-align: center;
    padding-top: 100px;
}

.iso-inner-content-viewport {
    padding-top: 49px;
}

.iso-info-arrow-box {
    position: relative;
    background: $cp-mango;
    margin-right: 10px;
    padding: 5px 15px;
    color: $cp-white;

    &.rounded {
        border-radius: 4px;
    }

    &.right {
        &:after {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: $cp-white;
            border-left-color: $cp-mango;
            border-width: 7px;
            margin-top: -7px;
        }
    }

    &.left {
        &:after {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: $cp-white;
            border-right-color: $cp-mango;
            border-width: 7px;
            margin-top: -7px;
        }
    }
}

.iso-arrow-box {
    /*position: relative;
    border: 0;
    background: $cp-error-red;
    padding: 10px;
    color: $cp-white;

    &:after {
        bottom: 100%;
        left: 0;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $cp-error-red;
        border-width: 12px;
    }*/
    /*&:before {
        font-family: "Material Icons";
        content: '\E000';
        font-size:1.6em;
        position:absolute;
        top:-2px;
        left:0
    }*/
    position: relative;
    top: -1px;
    color: $cp-error-red;
    border-top: 2px solid $cp-error-red;
    padding: 4px 0;
    font-size: 98%;
    font-style: italic;
}


.center-block {
    /*display: block;
    margin-left: auto;
    margin-right: auto;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.vertical {
        vertical-align: middle;
    }

    &.transparent {
        background: transparent;
    }
}

.iso-pull-right {
    display: flex;
    justify-content: flex-end;
}

.iso-common-viewport {
    background: $cp-corp-color;
    color: $cp-white;
}

.iso-blocks {
    margin: 5px 10px;

    div {
        border: 1px solid lighten($cp-corp-second-color,20%);
        text-align: center;
        background: $cp-white;
        cursor: pointer;
        padding: 10px;
        color: $default-font-color;
        min-height: 80px;
        display: table;
        margin: 5px 10px 30px 0;
        box-shadow: 0 4px 8px 0 lighten($cp-corp-second-color,20%);
        transition: 0.3s;

        &:hover {
            background: $cp-corp-active-color;
            color: $cp-white;
            box-shadow: 0 8px 16px 0 lighten($cp-corp-active-color,40%);
            border: 1px solid $cp-corp-active-color;

            i {
                color: $cp-corp-active-color;
                border: 1px solid $cp-corp-active-color;
            }
        }

        i {
            position: absolute;
            background: $cp-white;
            padding: 7px 0;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            top: -20px;
            left: calc(50% - 20px);
            border: 1px solid lighten($cp-corp-second-color,20%);
            color: $default-font-color;
        }

        p {
            font-size: ($default-font-size + 2);
        }

        h4 {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.iso-chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: ($default-font-size + 4);
    line-height: 50px;
    border-radius: 25px;
    background-color: darken($cp-white,2%);
    width: 100%;
    color: $default-font-color;

    i {
        float: left;
        margin: 0 10px 0 -25px;
        height: 50px;
        width: 50px;
        padding: 10px;
        border-radius: 50%;
        background: $cp-white;
        border: 3px solid darken($cp-white,2%);
        color: $default-font-color;
    }
}


.iso-linear-guage {
    width: 100%;
    position: relative;
    height: 35px;

    .chart {
        height: 15px;
        position: absolute;
        bottom: 3px;
        left: 25px;
        right: 25px;
        border: 1px solid darken($cp-corp-second-color,20%);
        border-top: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        align-items: flex-end;
    }

    .scale {
        position: relative;
        height: 15px;
        width: 10%;
        border: 0;
        align-items: baseline;
    }

    .midscale {
        border: 1px solid darken($cp-corp-second-color,20%);
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
    }

    .min {
        position: absolute;
        left: -15px;
        top: -20px;
    }

    .max {
        position: absolute;
        right: -15px;
        top: -20px;
    }

    .mid {
        position: absolute;
        top: -20px;
        right: -15px;
    }

    .range {
        background: $cp-good-green;
    }

    .rangeleft {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, $cp-good-green 50%, transparent 50%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, $cp-good-green 50%, transparent 50%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, $cp-good-green 50%, transparent 50%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, $cp-good-green 50%, transparent 50%); /* Standard syntax */
    }

    .rangeleft25 {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, $cp-good-green 25%, transparent 25%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, $cp-good-green 25%, transparent 25%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, $cp-good-green 25%, transparent 25%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, $cp-good-green 25%, transparent 25%); /* Standard syntax */
    }

    .rangeleft75 {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, $cp-good-green 75%, transparent 75%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, $cp-good-green 75%, transparent 75%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, $cp-good-green 75%, transparent 725%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, $cp-good-green 75%, transparent 75%); /* Standard syntax */
    }


    .rangeright {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, transparent 50%, $cp-good-green 50%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, transparent 50%, $cp-good-green 50%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, transparent 50%, $cp-good-green 50%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, transparent 50%, $cp-good-green 50%); /* Standard syntax */
    }

    .rangeright25 {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, transparent 25%, $cp-good-green 25%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, transparent 25%, $cp-good-green 25%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, transparent 25%, $cp-good-green 25%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, transparent 25%, $cp-good-green 25%); /* Standard syntax */
    }

    .rangeright75 {
        background: $cp-good-green; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, transparent 75%, $cp-good-green 75%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(left, transparent 75%, $cp-good-green 75%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(left, transparent 75%, $cp-good-green 75%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to left, transparent 75%, $cp-good-green 75%); /* Standard syntax */
    }



    .pointer {
        position: absolute;
        border: 0;
        padding: 10px;
        color: $cp-white;
        left: -11px;
        top: 20px;

        &:after {
            bottom: 100%;
            left: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-bottom-color: $cp-good-green;
            border-width: 10px;
        }
    }
}

.iso-bg {
    &.white {
        background: $cp-white;
    }

    &.vblue {
        background: $cp-corp-color;
    }

    &.activeblue {
        background: $cp-corp-active-color;
    }

    &.vgray {
        background: $cp-corp-second-color;
    }

    &.error-red {
        background: $cp-error-red;
    }

    &.good-green {
        background: $cp-good-green;
    }

    &.mango {
        background: $cp-mango;
    }



    &.light-slate {
        background: $cp-light-slate;
    }
}

.iso-hide-controls.ng-hide {
    transition: all 0.5s linear;
}

.iso-panel-content-fluid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;

    div {
        margin: 0 8px 0 0;
    }
}

.horiz-scroller-wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    & .scroller {
        width: 40px;
        text-align: center;
        cursor: pointer;
        display: none;
        padding: 5px;
        margin-top: 5px;

        &.scroller-right {
            position: absolute;
            right: 0;
            border-radius: 50%;
            background: lighten($cp-corp-second-color,30%);
            opacity: 0.7;
            z-index: 997;
            padding: 3px;
            top: 50%;
            transform: translateY(-50%);
            height: 35px;
            width: 35px;

            &:hover {
                opacity: 0.8;
            }

            i {
                padding: 5px 0;
                color: darken($cp-corp-second-color,50%);
            }
        }

        &.scroller-left {
            position: absolute;
            left: 0;
            border-radius: 50%;
            background: lighten($cp-corp-second-color,30%);
            opacity: 0.7;
            z-index: 997;
            padding: 3px;
            top: 50%;
            transform: translateY(-50%);
            height: 35px;
            width: 35px;

            &:hover {
                opacity: 0.8;
            }

            i {
                padding: 4px 0;
                color: darken($cp-corp-second-color,50%);
            }
        }
    }
}

.iso-widget01 {
    background: $cp-white;
    padding: 15px;
    margin: 3px 0;
    min-width: 300px;

    & .widget-header {
        position: relative;
        border-bottom: 1px solid darken($cp-white,15%);

        h3 {
            color: $cp-corp-color;
            text-transform: uppercase;
        }

        h5, h4 {
            color: $cp-corp-color;
        }

        span {
            position: absolute;
            right: 0;
            top: 0;
            font-size: ($default-font-size + 12);
            color: $cp-corp-active-color;

            &.medium {
                font-size: ($default-font-size + 6);
            }

            &.ribbon {
                padding: 5px;
                right: -27px;
                background: $cp-corp-active-color;
                color: $cp-white;
            }
        }

        & .close-button {
            position: absolute;
            top: -30px;
            right: -20px;
            font-size: ($default-font-size + 14);
            cursor: pointer;
            color: darken($cp-white,15%);

            &:hover {
                color: $cp-corp-third-color;
            }
        }
    }

    & .widget-content {
        padding: 15px 0;

        ul {

            li {
                width: 100%;
                padding: 10px 0;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid darken($cp-white,5%);

                &.column {
                    flex-direction: column;

                    p {
                        display: flex;
                        justify-content: space-between;
                    }

                    span {
                        display: flex;
                        justify-content: space-between;
                        
                    }
                }

                &.cursor {
                    cursor: pointer;
                }

                &.hover {


                    &:hover {
                        border-bottom: 1px solid $cp-corp-color;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        &.condensed {
            ul {
                li {
                    padding: 2px 0;
                    border-bottom: 1px solid darken($cp-white,10%);
                }
            }
        }

        &.width-25 {
            ul {
                width: 25%;
            }
        }

        &.width-50 {
            ul {
                width: 50%;
            }
        }

        &.width-75 {
            ul {
                width: 75%;
            }
        }

        &.left-align {
            ul {
                li {
                    display: flex;
                    justify-content: flex-start;

                    span {

                        &:first-child {
                            width: 25%;
                        }

                        &:last-child {
                            width: 75%;
                            word-wrap:break-word;
                        }
                    }
                }
            }
        }

        &.big {
            ul {
                li {
                    font-size: ($default-font-size + 2);
                    text-transform: uppercase;
                }
            }
        }

        &.formatted {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    border-bottom:0;
                    span {
                        font-size: ($default-font-size + $form-lable-size);
                        font-weight: normal;

                        &.divider {
                            flex-grow: 1;
                            border-bottom: 1px dashed darken($cp-white,10%);
                            margin: 5px 14px;
                        }
                    }
                }
            }
        }
    }

    &.transparent {
        background: transparent;
    }
}

.row {
    &.bottom-shadow {
        box-shadow: 0px 19px 11px -11px darken($cp-white,10%);
    }

    &.sameheight {
        display: flex;
        flex-wrap: wrap;

        & [class*='col-'] {
            display: flex;
            flex-direction: column;
        }
    }
}

.iso-sticky {
    position: fixed;
    min-width: 300px;
    background: $cp-corp-active-color;
    border-radius: 4px;
    padding: 15px;

    p {
        color: $cp-white;
        margin: 0;
    }

    &.top-center {
        top: 175px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.iso-page-header-section {
    height: 185px;
    padding-bottom: 20px;
    padding-top: 15px;
    padding-right: 0;
    border-left: 0;
    background: linear-gradient(141deg, darken($cp-corp-color,10%) 10%, lighten($cp-corp-color,15%) 50%, darken($cp-corp-color,10%) 85%);
    color: $cp-white;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &.left {
            justify-content: flex-start;
        }

        & .caption {
            font-size: ($default-font-size + 10);
        }
    }
}

.iso-page-content-section {
    margin-top: -185px;
}


.iso-check-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    div {
        border: 1px solid $cp-link-color;
        padding: 8px 10px;
        margin: 5px;
        color: $cp-link-color;
        position: relative;
        cursor: pointer;

        i {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translate(-50%,-50%);
            font-size: $default-font-size;
        }

        &.active {
            background: rgba($cp-link-color,0.7);
            border-color: rgba($cp-link-color,0.7);
            color: $cp-white;
            padding: 8px 25px;

            i {
                opacity: 1;
                background: rgba(darken($cp-corp-third-color,34%), 0.6);
                color: $cp-white;
                padding: 3px;
                border-radius: 50%;
            }
        }
    }
}


.iso-selected-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;

    label {
        margin-bottom: 0;
        margin-right: 10px;
    }

    a {
        padding: 5px 20px;
        position: relative;
        background: darken($cp-white,8%);
        border-radius: 20px;

        span {
            padding: 0 5px;
            line-height: 10px;
        }

        i {
            font-size: 120%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: $cp-white;
            opacity: 0;
        }

        &:hover {
            background: lighten($cp-error-red,10%);
            color: lighten($cp-error-red,10%);

            i {
                opacity: 1;
            }
        }
    }
}

.iso-filter-chip-content {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 36px;
    flex-wrap: wrap;
    width: 100%;

    & .clearfilter {
        margin-right: 8px;
        color: $cp-link-color;
        margin-top: 5px;
    }
}

.iso-content-space-around {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .menu {
        cursor: pointer;
    }
}

.iso-chart-container {
    position: relative;
    min-height: 400px;
    margin: 15px 10px 10px 10px;
    padding: 20px;
    box-shadow: 0 4px 25px -18px $cp-corp-third-color;
    border: 1px solid darken($cp-white,10%);
    border-radius: 6px;

    &.loading {
        background:$cp-white;
        opacity:0.2;
        /*filter: blur(2px);
        -webkit-filter: blur(2px);
        height: 100%;*/
    }

    & .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .2;
        filter: alpha(opacity=60);
        background: lighten($cp-corp-third-color,45%);
        text-align: center;
        border-radius: 6px;

        div {
            position: relative;
            font-size: 200%;
            margin-top: -17px;
            top: 50%;
            color: $cp-corp-third-color;
        }
    }
}

.iso-preview-container {
    background: lighten($cp-corp-third-color,10%);
    border: 1px solid lighten($cp-corp-third-color,10%);
    padding: 0;

    & .iso-preview {
        position: relative;

        & .preview-actions {
            position: absolute;
            top: 0;
            width: 98%;
            left: 0;
            right: 0;
            height: 40px;
            background: darken($cp-corp-third-color, 10%);
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            div {
                width: 95%;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: space-between;
                color: $cp-white;

                a {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: flex-end;

                    span {
                        display: none;
                        opacity: 0;
                        color: $cp-white;
                        margin-right: 5px;
                        transition: 0.3s;
                        padding: 5px 15px;
                        background: $cp-corp-color;
                        border-radius: 6px;
                    }

                    i {
                        color: $cp-white;
                    }

                    &:hover {
                        span {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    & .preview-content {
        display: flex;
        justify-content: center;
        overflow-y: scroll;

        & .content {
            width: 85%;
            margin-top: 80px;
            /*margin-bottom: 20px;*/
            background: $cp-white;
            /*height:100%;*/
            textarea {
                width: 100%;
                height: 5em;
            }

            & .nodata {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                filter: alpha(opacity=60);
                background: $cp-white;
                text-align: center;

                div {
                    position: relative;
                    font-size: 200%;
                    margin-top: -17px;
                    top: 50%;
                    color: $cp-corp-third-color;
                }
            }

            & .addpara {
                border: 0px solid red;
                min-height: 30px;
                width: 100%;
                display: inline-block;
                color: $default-font-color;

                p[contenteditable='true'] {
                    min-height: 30px;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    .k-editable-area {
        border: 0;
    }

    .k-editor-inline {
        border: 0;
        /*max-height: 250px;*/
        padding: 50px;
        /*margin-top: 20px;
        margin-bottom: 20px;*/
        box-shadow: 10px 0 5px -2px $cp-corp-third-color;

        ul {
            list-style-type: disc !important;
            padding: 0 20px !important;

            li {
                list-style-type: disc;
            }
        }

        ol {
            list-style-type: decimal !important;
            padding: 0 20px !important;

            li {
                list-style-type: decimal;
            }
        }
    }

    table {
        &.k-editor {
            border: 0;
            /*max-height: 100px;*/
            & .k-content {
                padding: 0;
                /*max-height: 100px;*/
            }
        }
    }
}

.iso-editor-textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
}

table.expandEditor {
    border-width: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 480px) {
    .iso-blocks {
        div {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .iso-content-viewport {
        padding: 5px;
        margin-left: -15px;

        & .container {
            width: 100%;
        }
    }

    /*.iso-inner-content-viewport {
        padding-top: 80px;
    }*/


    .iso-blocks {
        div {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .iso-content-viewport {
        padding-left: 10px;
    }



    .iso-blocks {
        div {
            width: 100%;
        }
    }
}
