﻿.iso-tooltip {
    position: relative;

    & .iso-tooltiptext {
        visibility: hidden;
        min-width: 250px;
        max-width: 500px;
        min-height: 25px;
        background-color: darken($cp-corp-third-color,10%);
        color: $cp-white;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 999;
        font-size: $default-font-size;
        margin: 8px;
        vertical-align: middle;

        &.iso-hoverboxtext {
            max-width: 650px;
            background-color: lighten($cp-mango,45%);
            color: #6d7274;
            border: 1px solid lighten($cp-corp-third-color,50%);

            &.white{
                background-color: $cp-white;
                border: 0px solid $cp-corp-color;
                box-shadow: 0px 4px 8px 0px darken($cp-white,25%);
            }
        }

        &.top {
            bottom: 100%;
            left: 50%;

            &::after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: calc(50% - 10px);
                /*margin-left: -5px;*/
                border-width: 5px;
                border-style: solid;
                border-color: darken($cp-corp-third-color,10%) transparent transparent transparent;
            }
        }

        &.right {
            top: -8px;
            left: 105%;

            &::after {
                content: " ";
                position: absolute;
                top: 50%;
                right: 100%; /* To the left of the tooltip */
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent darken($cp-corp-third-color,10%) transparent transparent;
            }
        }

        &.bottom {
            top: 105%;
            left: 50%;

            &::after {
                content: " ";
                position: absolute;
                bottom: 100%; /* At the top of the tooltip */
                left: calc(50% - 10px);
                /*margin-left: -5px;*/
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent darken($cp-corp-third-color,10%) transparent;
            }
        }

        &.left {
            top: -355px;
            right: 105%;

            &::after {
                content: " ";
                position: absolute;
                top: 75%;
                left: 100%; /* To the right of the tooltip */
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent transparent darken($cp-corp-third-color,10%);
            }
        }

        &.predictive {
            top: -155px;
            right: 105%;

            &::after {
                content: " ";
                position: absolute;
                top: 75%;
                left: 100%; /* To the right of the tooltip */
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent transparent darken($cp-corp-third-color,10%);
            }
        }
    }

    &:hover {
        & .iso-tooltiptext {
            visibility: visible;
        }
    }
}

.tooltip {
    z-index: 9999999;
}

.tooltip-inner {
    background: darken($cp-corp-third-color,10%);
    min-width: 100px;
    max-width: 100%;
}

.tooltip.top {
    & .tooltip-arrow {
        border-top-color: darken($cp-corp-third-color,10%);
    }
}

.tooltip.right {
    & .tooltip-arrow {
        border-right-color: darken($cp-corp-third-color,10%);
    }
}

.tooltip.bottom {
    & .tooltip-arrow {
        border-bottom-color: darken($cp-corp-third-color,10%);
    }
}

.tooltip.left {
    & .tooltip-arrow {
        border-left-color: darken($cp-corp-third-color,10%);
    }
}

.iso-info-tooltip {
    max-width: 50%;

    & .tooltip-inner {
        border: 1px solid lighten($cp-corp-color, 50%);
        background: lighten($cp-corp-color, 60%);
        color: $cp-corp-color;
        padding: 10px 15px;
        text-align: justify;
    }

    & .tooltip-arrow {
        display: none;
    }
}

.iso-popover {
    &.error {
        border-color: $cp-error-red;
        background: lighten($cp-error-red,30% );

        &.right {
            & .arrow {
                border-right-color: $cp-error-red;

                &:after {
                    border-right-color: lighten($cp-error-red,30% );
                }
            }
        }

        & .popover-content {
            color: darken($cp-error-red, 12%);
        }
    }

    &.warning {
        border-color: $cp-mango;
        background: lighten($cp-mango,30% );

        &.right {
            & .arrow {
                border-right-color: $cp-mango;

                &:after {
                    border-right-color: lighten($cp-mango,30% );
                }
            }
        }

        & .popover-content {
            color: darken($cp-mango, 30%);
        }
    }
}

.iso-moveable-tooltip {
    border: 1px solid darken($cp-mango, 20%);
    background: lighten($cp-mango,30% );
    color: darken($cp-corp-third-color, 10%);
    /*box-shadow: 0 2px 3px darken($cp-white,15%);*/
    position: absolute;
    padding: 7px 10px;
    text-align: left;
    /*border-radius: 4px;*/
    max-width: 350px;
    overflow: auto;
}

.mat-tooltip {
  background-color: $cp-corp-third-color;
  font-size: 12px;
}

.monitor-tooltip {
  padding: 20px 5px;

  &.mango {
    color: $cp-mango;
  }
}
