.iso-left-menu {
    background: $cp-corp-color;
    position: fixed;
    height: 100%;
    z-index: 1001;
    padding-top: 50px;
    left: 0;
    transition: 0.5s;
    width: 45px;

    &.iso-left-menu-expanded {
        width: 225px;
        transition: 0.5s;
        & .iso-left-menu-open {
            justify-content:flex-end;

            // i{
            //     transform: rotate(180deg);
            // }
        }
    }

    & .iso-left-menu-open {
        width: 100%;
        background: darken($cp-corp-color,10%);
        border-right: 1px solid hsla(0,0%,100%,.15);
        height: 70px;
        display:flex;
        justify-content:center;
        align-items:center;


        & > span {
            /*width: 100%;*/
            float: right;
            background: darken($cp-corp-color,10%);
            cursor: pointer;
            border-radius: 50%;
            margin-right: 2px;

            i {
                padding: 10px;
                float: right;
            }
        }
    }

    & .tooltip {
        min-width: 120px;
    }

    & .tooltip-inner {
        background: darken($cp-corp-third-color,10%);
        padding: 8px 0;
    }

    & .tooltip.right {
        & .tooltip-arrow {
            border-right-color: darken($cp-corp-third-color,10%);
        }
    }
}

.iso-banner-arrow{
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffb900;
    border-bottom-color: #ffb900;
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 12%;
    top:-30px;
    z-index: 0;
}


.iso-left-menu-icons {
    padding: 0px;
    list-style-type: none;

    & > li {
        display: table;
        padding: 10px;
        width: 100%;
        border: 0;
        cursor: pointer;
        text-align: left;
        border-left: 1px;

        &:hover {
            background:  hsla(0,0%,100%,.15);
            border: 0;
            border-left: 1px;
        }

        &.active {
            /*color: $cp-mango;*/
            border-left: 1px solid $cp-mango;
            background: hsla(0,0%,100%,.12);

            /*i {
                color: $cp-mango;
            }

            span {
                color: $cp-mango;
            }*/
        }


        & > span {
            color: $cp-white;
            display: none;
            vertical-align: middle;
            text-align: left;
            width: 100%;
            padding-left: 15px;

            &.showtext {
                display: table-cell;
            }

            &.hidetext {
                display: none;
            }
        }

        & > i {
            color: $cp-white;
        }
    }
}

.iso-banner {
    font-size: ($default-font-size + 4);
    padding: 0 10px 0 10px;
    text-align: left;
    color: $cp-white;
    background: linear-gradient(141deg, darken($cp-corp-color,10%) 10%, lighten($cp-corp-color,15%) 50%, darken($cp-corp-color,10%) 85%);
    position: relative;

    & .iso-banner-container {
        margin: 0 60px 0 45px;
        border-right: 0px solid darken($cp-corp-color,5%);
    }

    & .iso-banner-items {
        position: absolute;
        right: 4px;
        bottom: -55px;

        ul {
            display: flex;

            li {
                flex: 0 0 auto;
                border: 1px solid $cp-corp-active-color;
            }
        }
    }

    & .scroller-wrapper {
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        height: $banner-links-li-height;

        & .scroller {
            width: 40px;
            text-align: center;
            cursor: pointer;
            display: none;
            padding: 5px;
            margin-top: 5px;

            &.scroller-right {
                /*float: right;*/
                position: absolute;
                right: 0;
                border-bottom-left-radius: 36px;
                border-top-left-radius: 36px;
                background: lighten($cp-corp-second-color,60%);
                opacity: 0.7;
                z-index: 997;
                padding: 0;

                &:hover {
                    opacity: 0.8;
                }

                i {
                    /*background: $cp-corp-active-color;*/
                    /*padding: 2px;*/
                    padding: 5px 0;
                    /*border-radius: 50%;*/
                    color: darken($cp-corp-second-color,50%);
                }
            }

            &.scroller-left {
                /*float: left;*/
                position: absolute;
                left: 0;
                border-bottom-right-radius: 36px;
                border-top-right-radius: 36px;
                background: lighten($cp-corp-second-color,60%);
                opacity: 0.7;
                z-index: 997;
                padding: 0;

                &:hover {
                    opacity: 0.8;
                }

                i {
                    /*background: $cp-corp-active-color;*/
                    /*padding: 2px;*/
                    padding: 4px 0;
                    /*border-radius: 50%;*/
                    color: darken($cp-corp-second-color,50%);
                }
            }
        }
    }
}

.iso-parent-banner-links {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    /*-webkit-flex-wrap: wrap;*/
    /*flex-wrap: wrap;*/
    margin-bottom: 0px;
    /*position: absolute;*/
    /*justify-content: center;*/
    border-bottom: 1px dotted  hsla(0,0%,100%,.2);

    & > li {
        border-bottom: 0px dotted darken($cp-white,15%);
        font-size: ($default-font-size + 2);
        flex: 0 0 auto;
        height: $parent-banner-links-li-height;
        line-height: $parent-banner-links-li-height;
        position: relative;
        z-index: 1;

        span {
            color: darken($cp-white,5%);
            padding: 10px 16px;
            text-decoration: none;
        }

        &:hover {
            & > a {
                color: $cp-mango;
            }
        }

        &.active {
            &:before {
                content: "";
                position: absolute;
                left: 25%;
                bottom: 0;
                height: 1px;
                width: 50%;
                border-bottom: 2px solid $cp-mango;
            }

            span {
                color: $cp-white;
                /*font-weight: bold;*/
            }
        }
    }
}

.iso-banner-links {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    /*-webkit-flex-wrap: wrap;*/
    /*flex-wrap: wrap;*/
    margin-bottom: 0px;
    /*position: absolute;*/
    /*justify-content: center;*/
    & > li {
        font-size: ($default-font-size + 2);
        flex: 0 0 auto;
        height: $banner-links-li-height;
        line-height: $banner-links-li-height;
        position: relative;


        a {
            color: darken($cp-white,5%);
            padding: 10px 16px;
            text-decoration: none;
            border-right: 0px dotted darken($cp-white,20%);
        }

        &:last-child {
            a {
                border-right: 0;
            }
        }

        &:hover {
            & > a {
                color: $cp-white;
            }
        }

        &.active {
            //border-bottom: 5px solid $cp-mango;
            /*padding: 0 15px 0 0;*/
            &:after {
                width: 0;
                height: 0;
                border-left: $banner-links-li-arrow-border-left;
                border-right: $banner-links-li-arrow-border-right;
                border-bottom: $banner-links-li-arrow-border-bottom;
                border-bottom-color: darken($cp-white,2%);
                content: " ";
                position: absolute;
                bottom: 0;
                left: 45%;
                z-index: 0;
            }

            & > a {
                color: $cp-white;
                /*font-weight: bold;*/
                cursor: default;
                /*padding-right: 32px;*/
            }

            i {
                position: absolute;
                top: 15px;
                right: 8px;
                font-weight: normal;
                cursor: pointer;
                font-size: $default-font-size + 4;
            }
        }
    }
}

.iso-banner-scroller {
    width: 40px;
    text-align: center;
    cursor: pointer;
    /*display: none;*/
    padding: 5px;
    margin-top: 5px;
}

.iso-banner-scroller-right {
    float: right;
}

.iso-banner-scroller-left {
    float: left;
}


.iso-back-to-top {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1;
    width: $back-to-top-width;
    height: $back-to-top-height;
    border: 1px solid $cp-corp-color;
    opacity: 0.5;
    display: none;
    border-radius: 50%;

    & > i {
        padding: $back-to-top-i-padding;
        width: 100%;
        height: 100%;
    }

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
}

.iso-dropdown-menu {
    position: relative;
    display: inline-block;

    &:hover {
        & .iso-dropdown-menu-content {
            display: block;
        }

        span {
            color: $cp-white;
            background: darken($cp-corp-second-color,5%);
            border-bottom: 3px solid darken($cp-corp-second-color,8%);
            cursor: pointer;
        }
    }

    &.menu01 {
        color: $cp-white;
        padding: 8px 12px;
        padding-right: 8px;
        /*background: hsla(0,0%,100%,.15);*/
        border: 0px solid hsla(0,0%,100%,.25);
        border-radius: 8px;
        margin: 5px 0;
        display: inline-flex;


        span {
            font-size: 120%;
            border-bottom: 0;
        }

        i {
            margin-left: 3px;
        }

        & .iso-dropdown-menu-content {
            top: -2px;
            left: -3px;
            background: $cp-white;
            border-radius: 8px;
            box-shadow: 0px 4px 8px 0px lighten($cp-corp-third-color,10%);
            opacity: 1;
            padding: 15px 0;
            font-size: 115%;
            transform: scale3d(.8,.8,1);
            -webkit-transition: opacity 250ms cubic-bezier(0.8, 0.0, 0.6, 1) 0ms,transform 250ms cubic-bezier(0.8, 0.0, 0.6, 1) 0ms;
            transform-origin: left top;
            display: none;
            opacity: 0;

            a {
                color: $default-font-color;
                padding: 8px 24px 8px 56px;
                position: relative;

                &:hover {
                    background: darken($cp-white,4%);
                }

                &.selected {
                    font-weight: 600;

                    &:before {
                        /*Add another block-level blank space*/
                        content: '';
                        display: block;
                        /*Make it a small rectangle so the border will create an L-shape*/
                        width: 7px;
                        height: 14px;
                        /*Add a white border on the bottom and left, creating that 'L' */
                        border: solid $cp-corp-color;
                        border-width: 0 2px 2px 0;
                        /*Rotate the L 45 degrees to turn it into a checkmark*/
                        transform: rotate(45deg);
                        position: absolute;
                        left: 28px;
                    }
                }
            }

            &:hover {
                -webkit-transition: opacity 250ms cubic-bezier(0.4, 0.0, 0.2, 1) 0ms,transform 250ms cubic-bezier(0.4, 0.0, 0.2, 1) 0ms;
                transform: scale3d(1,1,1);
            }
        }

        &:hover {
            & .iso-dropdown-menu-content {
                display: block;
                opacity: 1;
            }
        }
    }
}

.iso-dropdown-menu-content {
    display: none;
    position: absolute;
    background-color: darken($cp-corp-second-color,5%);
    min-width: 200px;
    box-shadow: 0px 4px 8px 0px darken($cp-corp-second-color,5%);
    z-index: 9999;
    width: 100%;
    color: $cp-white;
    opacity: 0.95;

    a {
        color: darken($cp-white,5%);
        width: 100%;
        display: inline-block;
        padding: 3px 16px;

        &:hover {
            background: darken($cp-corp-second-color,2%);
        }
    }

    &.right {
        right: 0;
    }
}

.iso-quick-toolbar-items {
    /*position: relative;*/
    padding-top: 3px;

    ul {
        /*position: absolute;
        right: 5px;
        top: -6px;
        z-index: 998;*/
        display: flex;
        margin: 0;
        justify-content: flex-end;

        li {
            flex: 0 0 auto;
            border: 0px solid $cp-corp-active-color;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0px;
            cursor: pointer;
            /*margin-right: 10px;*/


            i {
                font-weight: normal;
                color: lighten($cp-corp-third-color,25%);
            }

            button {
                padding: 0;
            }

            &:hover {
                /*background: $cp-corp-active-color;*/

                i {
                    color: $cp-corp-third-color;
                }
            }
        }
    }

    &.top {
        ul {
            li {
                i {
                    color: $cp-white;
                }
                &:hover {
                    i {
                        color: lighten($cp-corp-third-color,25%);
                    }
                }
            }
        }
    }
}

.iso-overlay-menu {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-y: hidden;
    transition: 0.5s;

    & .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 40px;
        color: $cp-error-red;
    }

    & .iso-overlay-menu-content {
        position: relative;
        top: 50px;
        width: 100%;
        text-align: left;
        margin-top: 30px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            *display: inline;
            zoom: 1;
            top: 0;
            vertical-align: top;
            color: darken($cp-white,10%);

            li {
                font-weight: normal;
                padding: 10px 0;
                font-size: 14px;

                a {
                    color: darken($cp-white,25%);
                    width: 100%;

                    &:hover {
                        color: $cp-white;
                        ;
                    }
                }

                &.active {
                    a {
                        color: $cp-mango;
                    }
                }
            }
        }
    }
}


.iso-breadcrumb {
  padding-left: 3%;
  margin-top: 5px;
  margin-bottom: 5px !important;

  ul {
    padding: 5px 0px;
    list-style: none;
    /*background-color: darken($cp-white,5%);*/
    margin-bottom: 0;

    li {
      display: inline;
      font-size: $default-font-size + 4;

      a {
        color: $cp-link-color;
      }
    }

    li + li:before {
      padding: 8px;
      color: darken($cp-corp-second-color,10%);
      content: "/\00a0";
    }
  }

  &.menu {
    margin-left: 35px;

    ul {
      li {
        font-size: 115%;
        color: $cp-white;

        a {
          color: $cp-white;
        }
      }

      li + li:before {
        color: $cp-white;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
    .iso-left-menu {
        width: 225px;
        left: -225px;
    }

    .iso-banner {
        font-size: $default-font-size;
    }

    .iso-back-to-top {
        display: none;
        opacity: 0;
    }
}

@media only screen and (max-width: 768px) {
    .iso-banner {
        font-size: $default-font-size;
    }

    .iso-back-to-top {
        display: none;
        opacity: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .iso-left-menu {
        width: 400px;
        left: -400px;
        transition: 0.5s;
        z-index: 998;

        & .iso-left-menu-open {
            display: none;
        }

        &.active {
            left: 0;
        }

        & .tooltip {
            display: none;
        }
    }

    .iso-left-menu-icons {
        padding: 0 20px;

        & > li {
            font-size: $default-font-size + 2;

            &:hover {
                background: $cp-corp-color;
            }

            & > span {
                display: table-cell;

                &.hidetext {
                    display: table-cell;
                }
            }
        }
    }

    .iso-banner {
        font-size: $default-font-size;
    }

    .iso-back-to-top {
        right: 30px;
        bottom: 30px;
    }

    .iso-back-to-top > i {
        padding: $back-to-top-i-padding;
    }
}
