﻿@font-face {
    font-family: 'Roboto';
    src:
        local('Roboto'),
        local('Roboto-Regular'),
        url(/src/app.content/fonts/roboto/Roboto-Regular.ttf) format('truetype');

    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(/src/app.content/fonts/roboto/Roboto-Light.ttf) format('truetype');
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
    // margin-bottom: 1px;
}

body {
    background: darken($cp-white,3%);
    color: $default-font-color;
    font-family: $default-font-family;
    font-size: $default-font-size;
    overflow-x: hidden;
    position: relative;
    height: 100%;
}

/*:-webkit-any(body) {
    position: static;
}*/

a {
    @include anchor($default-anchor-font-color);
    cursor: pointer;

    &.light {
        @include anchor($cp-corp-active-color);

        &:hover {
            @include anchor($cp-corp-active-color);
        }
    }

    &.white {
        @include anchor($cp-white);

        &:hover {
            @include anchor($cp-white);
        }

        &.underline {
            padding-bottom: 3px;
            border-bottom: 1px dotted $cp-white;

            &:hover {
                border-bottom-style: solid;
            }
        }
    }



    &:hover {
        @include anchor($default-anchor-font-color);
    }

    &:active {
        @include anchor($default-anchor-font-color);
    }

    &:focus {
        @include anchor($default-anchor-font-color);
        outline: none;
        -moz-outline-style: none;
    }
}

h1 {
    font-size: ($default-font-size + 28);

    &.blue {
        color: $cp-corp-color;
    }
}

h2 {
    font-size: ($default-font-size + 18);

    &.blue {
        color: $cp-corp-color;
    }
}

h3 {
    font-size: ($default-font-size + 8);

    &.blue {
        color: $cp-corp-color;
    }
}

h4 {
    font-size: ($default-font-size + 4);

    &.blue {
        color: $cp-corp-color;
    }
}

h5 {
    font-size: ($default-font-size);

    &.blue {
        color: $cp-corp-color;
    }
}

h1, h2, h3, h4, h5 {
    margin-top: 10px;
}


@media only screen and (max-width: 480px) {
    body {
        font-size: ($default-font-size);
    }
}

@media only screen and (max-width: 768px) {
    body {
        font-size: ($default-font-size);
    }
}

@media only screen and (max-width: 1024px) {
    body {
        font-size: ($default-font-size);
    }
}
