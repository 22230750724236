﻿.iso-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $cp-white;
    background: $cp-corp-color;
    width: 100%;
    height: 100%;
    z-index: 9999;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.iso-page-refresh-spinner {
    /*background:$cp-white;*/
    background: darken($cp-white,3%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &.modal {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    &.white {
        background: $cp-white;
    }
}

.iso-page-refresh-spinner-icon {
    width: 60px;
    height: 60px;
    margin: 12% auto;
    background-color: $cp-corp-color;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;

    &.white {
        background-color: $cp-white;
        animation: sk-scaleout-plain 1.0s infinite ease-in-out;
    }

    &.red {
        background-color: $cp-error-red;
        animation: sk-scaleout-plain 1.0s infinite ease-in-out;
    }

    &.green {
        background-color: $cp-good-green;
        animation: sk-scaleout-plain 1.0s infinite ease-in-out;
    }

    &.mango {
        background-color: $cp-mango;
        animation: sk-scaleout-plain 1.0s infinite ease-in-out;
    }

    &.small {
        margin: 0;
        width: 30px;
        height: 30px;
    }

    &.large {
        margin: 0;
        width: 80px;
        height: 80px;
    }
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }

    25% {
        background-color: $cp-error-red;
    }

    50% {
        background-color: $cp-good-green;
    }

    75% {
        background-color: $cp-mango;
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    25% {
        background-color: $cp-error-red;
    }

    50% {
        background-color: $cp-good-green;
    }

    75% {
        background-color: $cp-mango;
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}

@-webkit-keyframes sk-scaleout-plain {
    0% {
        -webkit-transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout-plain {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}

.iso-wireframe {
    background: $cp-white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 998;
    margin: 1px;
}

@keyframes shine {
    to {
        background-position: 0 0, 100% 0, /* move highlight to right */
        0 0, 0 40px, 0 80px, 0 120px;
    }
}

.iso-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 998;

    & .widget {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &.white {
        background: $cp-white;
    }
}

.iso-spinner-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    
    border-bottom: 3px solid darken($cp-white,10%);
    border-left: 3px solid darken($cp-white,10%);
    border-right: 3px solid $cp-corp-active-color;
    border-top: 3px solid $cp-corp-active-color;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    -webkit-animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    -ms-animation: spin 1s infinite linear;
    -o-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;

    &.small {
        height: 25px;
        width: 25px;
    }

    &.medium {
        height: 50px;
        width: 50px;
    }

    &.large {
        height: 80px;
        width: 80px;
    }

    &.top-align{
        margin:12% auto;
    }
}

.iso-loading-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    border-bottom: 3px solid darken($cp-white,5%);
    border-left: 3px solid darken($cp-white,5%);
    border-right: 3px solid lighten($cp-corp-active-color,20%);
    border-top: 3px solid lighten($cp-corp-active-color,20%);
    border-radius: 100%;
    height: 80px;
    width: 80px;
    -webkit-animation: spin .9s infinite linear;
    -moz-animation: spin .9s infinite linear;
    -ms-animation: spin .9s infinite linear;
    -o-animation: spin .9s infinite linear;
    animation: spin .9s infinite linear;
    overflow: hidden;
}

.iso-loading-animation.small {
    margin: 20% auto;
    border-bottom: 3px solid darken($cp-white,5%);
    border-left: 3px solid darken($cp-white,5%);
    border-right: 3px solid lighten($cp-corp-active-color,20%);
    border-top: 3px solid lighten($cp-corp-active-color,20%);
    border-radius: 100%;
    height: 25px;
    width: 25px;
    -webkit-animation: spin .6s infinite linear;
    -moz-animation: spin .6s infinite linear;
    -ms-animation: spin .6s infinite linear;
    -o-animation: spin .6s infinite linear;
    animation: spin .6s infinite linear;
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    from {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


.iso-loading-progress {
    background-color: darken($cp-white,5%);
    height: 4px;
    margin: 1em;
    overflow: hidden;
    position: relative;
    width: 12em;
    margin: 20% auto;

    &.top {
        height: 2px;
        margin: 0 auto;
    }

    &.wide {
        width: 100%;
    }
}

.iso-loading-progress-center {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.iso-loading-progress-bar {
    animation: side2side 2s ease-in-out infinite;
    background-color: $cp-corp-active-color;
    height: 100%;
    position: absolute;
    width: 50%;
}

@keyframes side2side {
    0%, 100% {
        transform: translateX(-50%);
    }

    50% {
        transform: translateX(150%);
    }
}

.iso-loading-line-bar {
    height: 1px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;

    &:before {
        display: block;
        position: absolute;
        content: "";
        left: -200px;
        width: 200px;
        height: 1px;
        background-color: $cp-corp-color;
        animation: loading 2s linear infinite;
    }
}


@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 70%;
    }

    80% {
        left: 50%;
    }

    95% {
        left: 120%;
    }

    to {
        left: 100%;
    }
}


.iso-mask-container {
    /*background:$cp-white;*/
    background: darken($cp-white,3%);
    opacity:0.1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
}

@media only screen and (max-width: 768px) {
    .iso-page-refresh {
        & > i {
            padding: 5px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .iso-page-refresh {
        & > i {
            padding: 5px;
        }
    }
}


.k-loading-image {
  background: none !important;
  border-bottom: 3px solid #e6e6e6;
  border-left: 3px solid #e6e6e6;
  border-right: 3px solid #069ABC;
  border-top: 3px solid #069ABC;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -ms-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear; 
  margin: 5% 45%;
}
