﻿.iso-copyright-bottom {
    color: $cp-white;
    text-align: center;
    padding: 10px;
    font-size: $default-font-size - 2;
    line-height: 50px; 
    position: relative;       
    & > img {
        position: absolute;
        left: -35px; // align the image with box - if image changes, this value may change
        top: 0;
    }
}

.iso-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px; /* Height of the footer */
    background: $cp-white;

    & .contactus{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
    }
}

@media only screen and (max-width: 768px) {
    .iso-copyright-bottom {
        & .iso-logo {
            top: -25px;
        }
    }
}
