﻿//@import "~@angular-mdc/theme/material";

$mdc-theme-primary: #1565c0;
$mdc-theme-secondary: #388e3c;
$mdc-theme-background: #fff;

.iso-home-container {
  height: calc(100% - 50px - 36px);
  overflow: auto;
}

.iso-home-welcome {
    padding-bottom: $home-welcome-padding-bottom;
    padding-top: $home-welcome-padding-top;
    padding-right: 0;
    border-left: 0;
    background: linear-gradient(141deg, darken($cp-corp-color,10%) 10%, lighten($cp-corp-color,15%) 50%, darken($cp-corp-color,10%) 85%);
}

.iso-home-welcome-section {
    position: relative;
    height: $home-welcome-section-height;
    color: $cp-white;
}

.iso-home-welcome-date {
    color: $cp-white;
}

.iso-home-section {
  background: darken($cp-white,2%);
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: $home-section-min-height;
  height: calc(100% - 125px);

  >div>.recent-documents-container {
    position: relative;
    transition: all 0.25s linear 0.125s;
    min-height: max(
        calc(100vh - 50px - 36px - 125px - 10px - 10px),
        calc(125px + 10px + 5px + (10px + 23px + 10px) + (48px + 1px) + (5px + 60px + (16px + 16px + (10em * 1.3) + 16px + 16px))) + 5px + 10px);
        // * 1.3 to account for line height in relation to font size, 125px to match Welcome message for user to hide by scrolling down

    >.body {
      position: absolute;
      width: calc(100% - 15px - 15px);

      >.tab-group {
        height: calc(100% - 10px - 23px - 10px);

        .tab-content {
          padding-top: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;

          >.header {
            display: flex;
            align-items: baseline;
          }
          >.body {
            position: relative;
            overflow: auto;
          }

          @keyframes fadeOutInKf {
            // set visibility for accessibility
            0% {
              opacity: 1;
              visibility: visible;
            }
            25% {
              opacity: 0;
              visibility: hidden;
            }
            75% {
              opacity: 0;
              visibility: hidden;
            }
            100% {
              opacity: 1;
              visibility: visible;
            }
          }
          >.fadeOutIn {
            // duration, function, delay, iteration, direction, fill mode, play-state, name
            // animation timing coordinated with transition
            animation: 0.5s linear 0s 1 normal forwards running fadeOutInKf;
          }
        }
      }
    }
  }

  .right-panel {
    margin-top: calc(10px + 23px + 10px + 48px - 2px);
  }
}

@media only screen and (min-width: 1024px) {
  .iso-home-section{
    padding-left: calc(45px + 20px + 15px);
  }
}

@media only screen and (max-width: 1024px) {
  .iso-home-section {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.iso-home-assistance {
    border: 2px dotted $cp-corp-color;
    background: $cp-white;
    padding-bottom: 20px;

    a {
        &:focus {
            outline: 1px solid $cp-corp-color;
        }
    }
}

.iso-home-recentlist, .iso-search-recentlist{
    margin-bottom: 0;
    thead {
        th {
            border-top: 0;
        }
    }
    tbody {
        tr {
            td {
                a {
                    opacity: 0;
                }
            }

            &:hover {
                a {
                    opacity: 1;
                }
            }
        }
    }
}

.iso-home-profile-lst {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    & > i {
        position: absolute;
        background: $cp-white;
        padding: 15px;
        border-radius: 100%;
        width: 70px;
        height: 70px;
        top: 25px;
        left: calc(50% - 35px);
        border: 1px solid lighten($cp-corp-third-color,50%);
        color: $default-font-color;
        font-size: ($default-font-size + 26);
    }

    & > div {
        padding: 10px 0;
        align-self: center;
        text-align: center;
        width: 100%;

        i {
            float: right;
            cursor: pointer;
            padding-right: 10px;
        }

        p {
            color: $cp-corp-color;
        }

        ul {
            margin-top: 15px;
            padding: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                text-align: left;
                color: $cp-corp-color;
                width: 100%;
                display: inline-block;
                padding: 10px;
                border-bottom: 1px solid lighten($cp-corp-second-color,50%);

                &:first-child {
                    border-top: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }

                span {
                    width: 50%;

                    &:first-child {
                        font-weight: bold;
                        margin-right: 15px;
                    }

                    &:last-child {
                        float: right;
                        text-align: right;
                        font-size: ($default-font-size + 2);
                    }
                }
            }
        }

        h4 {
            margin: 0;
        }
    }
}

.iso-home-quick-links {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 145px;

    ul {
        display: flex;
        margin: 0;
        justify-content: flex-end;

        li {
            flex: 0 0 auto;
            cursor: pointer;
            display:flex;
            flex-direction:column;
            align-items:center;
            width:120px;

            i {

                font-weight: normal;
                color: $cp-white;
                border: 1px solid $cp-white;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                padding: 11px;
            }

            span {
                color: $cp-white;
                margin-top:5px;
            }
        }
    }
}

.pop-pwd-class {
    ul {
        padding: 0 5%;
        li{
            list-style-type: circle;
            color : black;

            ul {
                padding: 0 5%;
                li {
                    list-style-type: square;
                }
            }
            &.req-success {
                color : green;
            }

            &.req-fail {
                color : red;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .iso-home-welcome {
        padding-bottom: 50px;
        padding-top: 30px;


        h3 {
            font-size: ($default-font-size + 2);
        }

        h2 {
            font-size: ($default-font-size + 4);
        }
    }
}

@media only screen and (max-width: 768px) {
    .iso-home-welcome {
        padding-bottom: 50px;

        h3 {
            font-size: ($default-font-size + 2);
        }

        h2 {
            font-size: ($default-font-size + 4);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .iso-home-recentlist, .iso-search-recentlist {
        tbody {
            tr {
                td {
                    a {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1500px;
  }
}

.example-table-container {
  position: relative;
  height: 310px;
  overflow: auto;
}

table {
  width: 100%;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

th.mat-header-cell {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}

.mat-menu-item {
  line-height: 40px !important;
  height: 40px !important;
  font-size: 13px;
  text-transform: capitalize;
}

.mat-menu-item:hover{
  background-color:grey;
  color: $cp-corp-active-color;
}
.mat-menu-item:hover .mat-icon{
  color: $cp-corp-active-color;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.mat-form-field-label-wrapper {
  font-size: 14px;
}

.filter-form-field .mat-form-field-wrapper{
  margin-bottom: -1em !important;
}

.mat-tab-label {
  min-width: 100px !important;
}

.mat-ink-bar {
  width: 100px !important;
}



.tile-subtitle-item {
  font-size: 14px;
}

.mdc-image-list__supporting {
  padding: 0px 0 !important;
}

.highlight{
  background: $cp-good-green;
}

.colorBox {
  width: 25px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 8px;
  margin-left: 3px;
  border: .5px solid rgb(77, 77, 77);
  border-radius: 7px;
  float: left;
}

.arrange-horizontally > * {
}

.button-space {
  margin-left: 10px !important;
}

// grid layout for tile view
// alternate value for grid-template-columns: repeat(auto-fit, minmax(19em, 1fr))
.tile-view__grid {
  display: grid;
  // column width set to fit date of upload and quick action icons in one line
  // longest date of upload produced by Roboto Light was May 11, 1111 at 11:11 AM (all digits are the same width), simplified to 24ch
  grid-template-columns: repeat(auto-fit, minmax(calc(16px + 24ch + (3 * 24px) + 16px), 1fr));
  gap: 16px 16px;
  padding: 16px;

  // used in conjunction with tile-card
  >.selected {
    box-shadow: 0px 0px 3px 3px #00B16A;
  }
  >.unselected {
    box-shadow: 0px 0px 5px silver;
  }

  >.tile-card {
    color: black;
    border-radius: 4px; // override border radius from .mdc-card__primary-action:first-child

    >.content-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      height: 100%;

      >div>.header {
        font-family: Roboto Medium;
        font-size: 1.25em;
        overflow-wrap: break-word;

        >.menu {
          float: right;
        }
      }
      >div>.body {
        font-family: Roboto Light;
        font-size: 1em;

        >div>.field-name {
          font-family: Roboto Regular;
        }
        >div>span>.icon {
          vertical-align: middle;
        }
      }
      >.footer {
        display: flex;
        justify-content: space-between;

        >div>.upload-date-subtitle {
          font-family: Roboto Regular;
          font-size: 0.8em;
        }
        >div>.upload-date {
          font-family: Roboto Light;
        }
        >.quick-action-container {
          display: flex;
          flex-direction: column;
          justify-content: end;
        }
      }
    }
  }
}

.thumbnail-view__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(204px, 1fr));
  gap: 16px 16px;
  padding: 16px;
  justify-items: center;

  >.selected {
    box-shadow: 0px 0px 3px 3px #00B16A;
  }
  >.unselected {
    box-shadow: 0px 0px 3px 3px #E6E6E6;
  }

  >.thumbnail-card {
    width: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0px 0px 4px 4px;

    >.thumbnail-area {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      height: 264px;

      >.thumbnail {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
    >.tab-area {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      border-top: 1px solid #CCCCCC;

      >.document-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
