﻿@mixin defaultselectioncontrol {
    color: $default-font-color;
    margin: 0 3px;
    font-weight: normal;
}

@mixin defaultselectioncontrolselected {
    color: $default-font-color;
    font-style: normal;
    margin: 0 3px;
}

@mixin defaultbutton {
    min-height: 30px;
    background: $cp-corp-active-color;
    color: $cp-white;
    border: 1px solid $cp-corp-active-color;
    padding:$button-padding;
    text-transform: uppercase;
    min-width: $button-min-width;
    margin: 5px 5px 5px 0;

    &:focus {
        outline: none;
    }

    &:hover {
        background: darken($cp-corp-active-color,5%);
        color: $cp-white;
    }

    &:disabled {
        color: lighten($cp-corp-second-color,50%);
        cursor: no-drop;
    }

    span {
        &:before {
            padding-right: 5px;
        }
    }
}

@mixin secondarybutton($background,$color) {
    background: $background;
    color: $color;
    border: 1px solid $background;
}

@mixin tertiarybutton($bordercolor) {
    background: $cp-white;
    border: 1px solid $bordercolor;
    color: $bordercolor;

    &:hover {
        background: lighten($cp-corp-second-color,60%);
        border: 1px solid lighten($cp-corp-second-color,15%);
        color: lighten($cp-corp-second-color,15%);
    }
}

@mixin defaultinput {
    width: 100%;
    min-height: $input-min-height;
    border: 1px solid lighten($cp-corp-third-color, 50%);
    color: $default-font-color;
    padding: 0 10px;
    border-radius: 0;

    &:hover {
        border-color: lighten($cp-corp-color,10%);
    }

    &:focus {
        outline: 0;
        border-color: lighten($cp-corp-color,10%);
        background:lighten($cp-corp-color,65%);
        box-shadow:none;
    }

    &::-ms-clear {
        display: none;
    }

    &::-webkit-input-placeholder {
        color: lighten($default-font-color,50%);
    }

    &:-moz-placeholder {
        color: lighten($default-font-color,50%);
    }

    &:-ms-input-placeholder {
        color: lighten($default-font-color,50%) !important;
    }

    &.w100 {
        width: 100px;
    }

    &.w150 {
        width: 150px;
    }

    &.w200 {
        width: 200px;
    }
}

@mixin anchor($color) {
    color: $color;
    text-decoration: none;
}

@mixin font($fontsize,$fontweight) {
    font-size: ($default-font-size + $fontsize);
    font-weight: $fontweight;
}

@mixin alertmessages($color) {
    border-radius: 3px;
    color: $color;
    background: $cp-white;
    font-size: ($default-font-size + 2);
    border: 1px solid $color;
    padding: 0;
    padding: 15px;
    padding-left: 60px;
    min-height: 50px;

    div {
        &:first-child {
            background: $color;
            width: 50px;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            text-align: center;

            i {
                @include vertical-align-middle;
            }
        }

        &:last-child {
            ul {
                margin: 0;
            }
        }
    }
}

@mixin accordion($background,$color,$bordercolor) {
    background: $background;
    color: $color;
    border: 1px solid $bordercolor;
}

@mixin accordion_active($background,$bordercolor) {
    background: $background;
    border: 1px solid $bordercolor;
}

@mixin vertical-align-middle() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
