﻿.iso-search-box-back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $cp-white;
    background: darken($cp-corp-second-color,50%);
    width: 100%;
    height: 100%;
    z-index: 998;
    opacity: 0.1;
}

.iso-search-box-result {
    position: absolute;
    z-index: 10000; 
    background: $cp-white;
    /*width: 400px;*/
    /*max-width: 500px;*/
    min-height:150px;
    max-height: 400px;
    overflow: auto;
    border: 1px solid lighten($cp-corp-third-color,50%);
    margin: 0;
    padding: 0;
   
    box-shadow: 0 4px 8px 0 lighten($cp-corp-third-color,20%);
    transition: max-height 2s ease-in;
}

.iso-quicksearch-nomatch {
    padding: 15px;
    text-align: center;
    font-size: ($default-font-size + 2);
}

.iso-header-search-box-result-claimnumber {
    color: $cp-corp-color;
    font-weight: bold;
    font-size: ($default-font-size + 2);
}

/*NEW SMALL SEARCH BOX*/
.iso-search-box-white {    
    position: relative;

    input[type="text"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;        
        border-radius: 4px;
        overflow: hidden;        
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 100%;
                    
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;            
        }        
    }

    input[type="search"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;        
        border-radius: 4px;
        overflow: hidden;        
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 93%;
        padding-left: 5px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;            
        }        
    }
    
    .search-btn {
        position: absolute;
        top: 0;
        right: -10px;
        height: 32px;   
        width: 32px; //square
        text-align: center;

        & > i {
            margin-top: 5px;
            color: $cp-corp-active-color;
        }

        &:active {
            background-color: lighten($cp-corp-active-color, 10%);
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            & > i {
                color: $cp-white;
            } 
        }
    }

    input[type="text"]:focus + .search-btn {        
        background-color: lighten($cp-corp-active-color, 5%);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;    
        & > i {
            color: $cp-white;
        }
    }
}

.center-spinner{
	margin:12% auto !important;
}


.iso-login-box-white {    
    position: relative;

    input[type="text"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;        
        border-radius: 4px;
        overflow: hidden;        
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 100%;
                    
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;            
        }        
    }

    input[type="password"] {
        background-color: $cp-white;
        border: 0;
        outline: 0;
        height: 32px;        
        border-radius: 4px;
        overflow: hidden;        
        background: $cp-white;
        color: $default-font-color;
        border: 1px solid $cp-corp-active-color;
        width: 100%;
                    
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $cp-corp-active-color;            
        }        
    }
      
    .show-btn {
        position: absolute;
        top: 28px;
        right: 0px;
        height: 20px;   
        width: 20px; //square
        text-align: center;

        & > i {
            margin-top: 5px;
            color: $cp-corp-active-color;
        }
    }
}