﻿.iso-content-header {
    width: 100%;
    z-index: 1002;
    padding: 0 15px;
    background: $cp-corp-color;
    position: fixed;

    & .header {
        width: 100%;
        margin: 0;
        background: $cp-corp-color;
        height: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        & .iso-app-menu {
            display: none;
            padding-top: 8px;
            cursor: pointer;
            width: 50px;
        }

        & .iso-logo {
            padding: 0;
        }

        & .iso-brand {
            /*color: $cp-white;
            font-size: ($default-font-size + 8);
            padding: 0 8px;
            border-left: 0px solid $cp-white;*/
            margin: 5px 0;
            padding: 10px 0;
            margin-left: 30px;

            // img {
            //     height: 34px;
            // }
        }

        & .iso-header-boxes {
            // margin-right: 10px;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;


            & .iso-header-search-box {
                background: hsla(0,0%,100%,.15);
                width: 400px;
                position: relative;
                border-radius:2px;

                input[type=text] {
                    background: transparent;
                    border: 0;
                    outline: 0;
                    /*font-size: ($default-font-size - 1);*/
                    height: 32px;
                    padding: 0 10px;
                    width: 100%;
                    color: $cp-white;
                    border-radius:2px;

                    &:focus {
                        background: $cp-white;
                        color: $default-font-color;
                    }

                    &:focus + a {
                        i{
                            color: $default-font-color;
                        }
                    }

                    &::-webkit-input-placeholder {
                        /*color: lighten($default-font-color,20%);*/
                        color: $cp-white;
                    }

                    &:-moz-placeholder {
                        /*color: lighten($default-font-color,20%);*/
                        color: $cp-white;
                    }

                    &:-ms-input-placeholder {
                        /*color: lighten($default-font-color,20%);*/
                        color: $cp-white;
                    }
                }

                span {
                    color: $cp-corp-color;
                    padding-right: 8px;
                }

                a {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding-top: 6px;
                    padding-right: 6px;
                    z-index: 1000000;
                    /*color:$cp-corp-color;*/
                    color: $cp-white;
                    &:focus {
                        color: darken($cp-corp-color,8%);
                    }
                }
                
            }

            & .iso-header-user {
                padding: 0 20px;
                color: $cp-white;
            }

            & .iso-header-links {
                padding-left: 5px;
                padding-right: 5px;

                & > a {
                    color: $cp-white;
                    text-decoration: none;
                    background: darken($cp-corp-color,8%);
                    padding: 8px 12px;
                    border-radius: 6px;

                    &:hover {
                        color: $cp-white;
                        text-decoration: none;
                        background: darken($cp-corp-color,3%);
                    }
                    
                    &:focus {
                        background: darken($cp-corp-color,3%);
                    }
                   
                }

                i {
                    display: none;
                }

                & .iso-header-logout {
                    background: darken($cp-corp-color,8%);
                    padding: 8px 12px;
                    border-radius: 6px;

                    &:hover {
                        background: darken($cp-corp-color,3%);
                    }
                }
            }
        }
    }
}

.iso-caseinfos {    
    & > ul {        
        padding: 10px 0 0 0;        
        margin-bottom: 0;

        & > li {
            display: inline;
            padding: 0 10px;
            font-weight: bold;

            & > span {
                font-weight: normal;
                padding: 0 10px;                
            }

            & > a {
                color: $cp-corp-color;
                display: inline-flex;
                align-items: center;                
                i {                    
                    font-size: $caseinfo-more-arrow-font-size;
                }
            }
        }
    }
}

.iso-caseinfos-dropdown {    
    width: 100%;
    padding: 5px 0;
    border-bottom: 0;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all .4s;

    & > ul {
        list-style: none;
        padding: 10px 0 0 0;
        position: relative;

        & > li {
            display: inline;
            padding: 20px 10px;
            font-weight: bold;

            & > span {
                font-weight: normal;
                padding: 0 10px;
            }
        }
    }

    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.iso-quick-toolbar-icons {
    div {
        &.icons {
            width: 36px;
            height: 36px;
            border: 1px solid $cp-corp-color;
            opacity: 0.7;
            border-radius: 50%;
            float: left;
            margin: 5px;
            /*box-shadow: 0 4px 8px 0 lighten($cp-corp-color,5%);*/
            & > i {
                padding: 5px;
                width: 100%;
                height: 100%;
                color: $cp-corp-color;
            }

            &:hover {
                opacity: 1;
                cursor: pointer;
                box-shadow: 0 4px 8px 0 lighten($cp-corp-color,50%);
            }
        }

        &.active {
            border: 1px solid $cp-mango;
            /*box-shadow: 0 4px 8px 0 lighten($cp-mango,5%);*/
            & > i {
                color: $cp-mango;
            }

            &:hover {
                box-shadow: 0 4px 8px 0 lighten($cp-mango,50%);
            }
        }
    }
}

.iso-claimantinfo-container {
    line-height: 1;
}


.iso-claimantinfo {
    padding-top: 18px;
    padding-left: 5px;    
    font-weight: bold;
    display: flex;    

    .display,
    .edit {
        font-weight: normal;
        flex: 0 0 70%;
        text-align: center;
        margin-left: 10px;
    }

    .display {        
        color: lighten($cp-corp-color, 15%);
    }

    .edit {
        transform: translateY(-6px);
    }
}

@media only screen and (max-width: 480px) {
    .iso-content-header {
        padding: 0 5px;

        & .header {
            & .iso-logo {
                display: none;
            }

            & .iso-brand {
                display: none;
            }

            & .iso-header-boxes {

                & .iso-header-user {
                    display: none;
                }

                & .iso-header-links {
                    span {
                        display: none;
                    }

                    i {
                        display: inline;
                        vertical-align: middle;
                        // display: block;
                        // line-height: 50px;
                        // padding-left: 15px;
                    }
                }
            }
        }
    }

    .iso-caseinfos {
        & > ul {
            & li {
                display: none;
            }
        }
    }

    .iso-caseinfos-dropdown {
        display: block;

        & ul {
            & li {
                display: block;
                border-bottom: 1px solid lighten($cp-corp-second-color,30%);

                &:last-child {
                    border-bottom: none;
                }

                &:nth-child(1) {
                    display: block;
                }

                &:nth-child(2) {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {

    .iso-content-header {
        padding: 0 5px;

        & .header {
            & .iso-brand {
                display: none;
            }

            & .iso-header-boxes {

                & .iso-header-search-box {
                    width: initial; /* override 400px width for smaller screens */
                    flex-grow: 1;

                    input[type=text] {
                        width: 100%;
                    }
                }

                & .iso-header-user {
                    display: none;
                }

                & .iso-header-links {
                    span {
                        display: none;
                    }

                    i {
                        display: inline;
                        vertical-align: middle;
                        // display: block;
                        // line-height: 50px;
                        // padding-left: 15px;
                    }
                }
            }
        }
    }

    .iso-caseinfos {
        & > ul {
            & li {
                display: none;
            }
        }
    }

    .iso-caseinfos-dropdown {
        display: block;


        & ul {
            & li {
                display: block;
                border-bottom: 1px solid lighten($cp-corp-second-color,30%);

                &:last-child {
                    border-bottom: none;
                }

                &:nth-child(1) {
                    display: block;
                }

                &:nth-child(2) {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .iso-content-header {
        display: table;

        & .header {
            & .iso-app-menu {
                display: block;
            }

            & .iso-logo {
                display: none;
            }

            & .iso-header-boxes {
                & .iso-header-user {
                    display: none;
                }

                & .iso-header-user-icon {
                    display: block;
                }

                & .iso-header-links {
                    span {
                        display: none;
                    }

                    i {
                        display: inline;
                        vertical-align: middle;
                        // display: block;
                        // line-height: 50px;
                        // padding-left: 15px;
                    }
                }
            }
        }
    }

    .iso-caseinfos-dropdown {
        & ul {
            & > li {
                display: block;
                border-bottom: 1px solid lighten($cp-corp-second-color,30%);
                padding: 10px;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.iso-seperator {
    text-align:center;
    padding-top:10px;
    font-size:$default-font-size + 7;
    & li {
        padding-top:2px;
    }
}

.header-sticky{
    position: fixed;
    z-index: 1000;
    background: #fff;
    top: 50px;
    border: 1px solid #d5d7d8;
}