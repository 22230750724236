﻿.iso-tabs {
    /*background: darken($cp-white,2%);*/
    background: $cp-white;

    & .tabs {
        list-style: none;
        margin: 0 0 0 0;
        text-align: left;
        border-bottom: 5px solid lighten($cp-corp-third-color,50%);
        padding: $tabs-ul-padding;        

        & > li {
            display: inline;
            padding: $tabs-li-padding;
            border-radius: 2px 2px 0 0;
            font-size: ($default-font-size + 2);
            font-weight: normal;
            cursor: pointer;
            color: $cp-white;
            width: 100%;
            text-transform: uppercase;            

            &.small{
                font-size: ($default-font-size);
                text-transform: none;
            }

            & > a {
                color: $cp-corp-color;
                text-decoration: none;

                &:active {
                    text-decoration: none;
                }

                &:focus {
                    text-decoration: none;
                }
            }

            &:hover {
                border-bottom: 5px solid $cp-corp-color;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;

                & > a {
                    color: $cp-corp-color;
                }
            }

            &.active {
                padding-top: 17px;
                color: $cp-white;
                border-bottom: 5px solid $cp-corp-color;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;

                & > a {
                    color: $cp-corp-color;
                }
            }
        
            & > input[type=search] {    
                outline: none;            
                background: darken($cp-white, 10%) url(/src/app.content/images/search.png) no-repeat 9px center;
                background-size: 18px;
                border: solid 1px #ccc;
                padding: 5px 24px 5px 10px;  
                margin-left: 8px;          
                
                -webkit-border-radius: 10em;
                -moz-border-radius: 10em;
                border-radius: 10em;
                
                -webkit-transition: all .5s;
                -moz-transition: all .5s;
                transition: all .5s;

                width: 28px;
                color: transparent;
                cursor: pointer;

                font-size: ($default-font-size - 1);

                &:hover {
                    background-color: $cp-white;
                }

                &:focus {
                    width: 150px;
                    padding-left: 32px;
                    color: $cp-corp-color;
                    border-color: $cp-corp-color;
                    background-color: $cp-white;
                    background-size: 18px;
                    cursor: auto;
                }

                &:-moz-placeholder {
                    color: transparent;
                }

                &::-webkit-input-placeholder {
                    color: transparent;
                }
            }
        }
    }

    & .tab-content {
        width: 100%;
        padding: 15px;
        /*padding-top: $tab-content-padding-top;*/
        background: $cp-white;
        margin-top:3px;
    }

    &.secondary {
        padding: 10px;

        & .tabs {
            border-bottom: 0;

            li {
                background: rgba(lighten($cp-corp-active-color,5%),0.5);
                color: $cp-white;
                margin-right: 10px;
                position: relative;

                a {
                    color: $cp-white;
                }

                &:hover {
                    border-bottom: 0;
                    transition: all 0.5s ease-in-out;
                    -webkit-transition: all 0.5s ease-in-out;
                    background: lighten($cp-corp-active-color,5%);

                    a {
                        color: $cp-white;
                    }
                }

                &.active {
                    background: lighten($cp-corp-active-color,5%);
                    padding-top: 12px;
                    color: $cp-white;
                    border-bottom: 0;
                    transition: all 0.5s ease-in-out;
                    -webkit-transition: all 0.5s ease-in-out;

                    a {
                        color: $cp-white;
                    }

                    &:after {
                        top: 100%;
                        /*right:0;*/
                        left: 50%;
                        transform: translateX(-50%);
                        border: solid transparent;
                        content: " ";
                        height: 12px;
                        width: 12px;
                        position: absolute;
                        pointer-events: none;
                        border-color: transparent;
                        border-top-color: lighten($cp-corp-active-color,5%);
                        border-width: 12px;
                        margin-bottom: -12px;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }

        & .tab-content {
            margin-top: 25px;

            border:1px solid darken($cp-white,10%);
        }

        /*Use this class if need to add anything additional in the tab row*/
        & .additional-tab-item {            
            border-bottom: 0px;
        }
    }

    /*Use this class if need to add anything additional in the tab row*/
    & .additional-tab-item {
        padding: 13px 0px;        
        font-size: $default-font-size + 2;
        border-bottom: 5px solid lighten($cp-corp-third-color,50%);

        &.toggle {
            padding: 12px 0px 11px 30px; // To fit that toggle checkbox size
        }

    }
}


.iso-tabs .tab-content {
    width: 100%;
    padding: 5px;
    padding-top: $tab-content-padding-top;
    background: $cp-white;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.iso-tabs .iso-list-tabs {
    display: flex;
    height: 400px;


    & .items {
        width: 40%;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none; //for IE10
        position: relative;
        z-index: 1;

        &::-webkit-scrollbar {
            display: none; //for Chrome and Safari
        }


        ul {
            margin-bottom: 0;
            margin-right: 30px;

            li {
                width: 100%;
                min-height: 75px;
                padding: 5px 20px;
                border: 1px solid darken($cp-white,8%);
                border-left: 5px solid darken($cp-mango, 5%);
                border-right: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                /*margin: 15px 0;*/
                background: darken($cp-white,2%);
                cursor: pointer;
                position: relative;

                &:first-child {
                    margin-top: 0;
                }

                &.greenleftborder {
                    border-left-color: $cp-good-green;
                }

                &.redleftborder {
                    border-left-color: $cp-error-red;
                }

                &.selected {
                    width: 100%;
                    background: linear-gradient(to right, darken($cp-white,3%), darken($cp-white,9%));
                    /*border-color:darken($cp-white,9%);*/
                    /*border: 0;*/
                    /*box-shadow: 0 5px 6px -1px darken($cp-white,25%);*/
                    /*font-size: ($default-font-size + 2);*/
                    color: $cp-corp-second-color;
                    /*border-left-color: $cp-corp-active-color;*/
                    &:after {
                        left: 99%;
                        /*right:0;*/
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        pointer-events: none;
                        border-color: transparent;
                        border-left-color: darken($cp-white,9%);
                        border-width: 15px;
                        margin-top: -15px;
                    }
                }

                & .headercolumn {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-content: flex-start;

                    p {
                        color: $cp-corp-second-color;
                        padding-left:15px;

                        &:first-child{
                            line-height:0px;
                            padding-top:8px;
                        }

                        &:last-child {
                            font-size: ($default-font-size - 1);
                            line-height:24px;
                        }
                    }
                }

                p {
                    margin: 5px 0;

                    &.header {
                        color: $cp-corp-second-color;
                        font-weight: 600;
                        text-transform: uppercase;

                        &.green {
                            color: $cp-good-green;
                        }

                        &.red {
                            color: $cp-error-red;
                        }
                    }

                    &.headericon {
                        background: darken($cp-mango, 5%);
                        color: $cp-white;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: ($default-font-size + 12);
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        text-transform: uppercase;
                        vertical-align: middle;
                        border-radius: 3px;

                        &.green {
                            background: $cp-good-green;
                        }

                        &.red {
                            background: $cp-error-red;
                        }
                    }

                    &.headertext {
                        color: $cp-corp-second-color;
                        font-size: ($default-font-size + 2);

                        &.green {
                            color: $cp-good-green;
                        }

                        &.red {
                            color: $cp-error-red;
                        }
                    }
                }

                i {
                    &.delete-item {
                        position: absolute;
                        right: 10px;
                        top: 6px;
                        font-size: ($default-font-size + 8);
                        color: $cp-error-red;


                        &.disabled {
                            color: $default-font-color;
                            cursor: default;
                        }
                    }

                    &.view-item {
                        position: absolute;
                        right: 35px;
                        top: 9px;
                        font-size: ($default-font-size + 6);
                        color: $cp-corp-second-color;
                    }
                }
            }
        }
    }

    & .content {
        background: $cp-white;
        width: 100%;
        padding: 15px;
        position: relative;
        margin-left: -30px;
        /*box-shadow: 5px 6px 6px -3px darken($cp-white,25%);*/
        border-top: 5px solid darken($cp-mango, 5%);


        &.fixed {
            position: fixed;
        }

        &.greentopborder {
            border-top-color: $cp-good-green;
        }

        &.redtopborder {
            border-top-color: $cp-error-red;
        }
    }
}

.iso-tab-chip-container {

    & .iso-tabs {
        /*display: none;*/
        margin-top: 10px;
        padding: 0 10px;
        position: relative;
        border: 1px solid darken($cp-white,8%);

        i {
            position: absolute;
            top: 0;
            right: -2px;
            padding: 5px;
            cursor: pointer;

            &:hover {
                background: darken($cp-white,10%);
                border-radius: 3px;
            }
        }

        ul {
            border: 0;

            li {
                display: none;
            }
        }
    }
}

.iso-tabs-chip {
    & .tabs {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-basis: 25%;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        margin-top: 15px;

        li {
            padding: 10px;
            /*border:1px solid darken($cp-white,5%);*/
            box-shadow: 0 1px 2px darken($cp-white,15%), 0 1px 3px 1px darken($cp-white,15%);
            border-radius: 6px;
            background: $cp-white;
            margin: 1px 1px 4px 15px;
            height: 100px;
            width: 245px;
            padding: 13px 13px 13px 80px;
            position: relative;
            cursor: pointer;

            &:hover {
                box-shadow: 0 1px 2px darken($cp-white,25%), 0 1px 3px 1px darken($cp-white,25%);
            }

            &.active {
                box-shadow: 0 1px 2px darken($cp-white,25%), 0 1px 3px 1px darken($cp-white,25%);
                background: $cp-corp-color;
                color: $cp-white;

                &:after {
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid transparent;
                    border-top-color: $cp-corp-color;
                    content: " ";
                    position: absolute;
                    bottom: -8px;
                    left: 45%;
                    z-index: 0;
                }

                a {
                    color: $cp-white;
                }
            }

            &:first-child {
                margin-left: 0px;
            }

            div {
                font-size: 112%;
                font-weight: 600;
                margin-bottom: 8px;
            }


            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                padding: 12px;
                border-radius: 50%;
                font-size: 250%;
                background: darken($cp-white, 4%);
            }
        }
    }

    & .tab-content {
        width: 100%;
        padding: 10px;
        padding-top: 32px;
        background: $cp-white;
        position: relative;
        box-shadow: 0 1px 2px darken($cp-white,25%), 0 1px 3px 1px darken($cp-white,25%);
        border-radius: 6px;

        & .closetab {
            position: absolute;
            top: 0;
            right: -2px;
            padding: 5px;
            cursor: pointer;

            &:hover {
                background: darken($cp-white,10%);
                border-radius: 3px;
            }
        }
    }
}
