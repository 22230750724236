﻿/*Color Palette(cp) based on verisk guidelines */
$cp-white:#ffffff !default; //white
$cp-black: #000000 !default; //black
// $cp-corp-color: #006BA6 !default; //verisk blue
$cp-corp-color: #00358E !default; //2022 globe blue
// $cp-corp-active-color:#069ABC !default; //active blue
$cp-corp-active-color:#2A7DE1 !default; //2022 radical blue
// $cp-corp-second-color:#1A7AAF !default; //verisk gray
$cp-corp-second-color:#047CB1 !default; //2022 ext blue 3
// $cp-corp-third-color:#54585A !default; //verisk gray
$cp-corp-third-color:#3F3F3F !default; //2022 75% black
// $cp-link-color: #006BA6 !default; //verisk blue
$cp-link-color: #004EAA !default; //2022 medium blue
// $cp-error-red: #FD462A !default; //error red
$cp-error-red: #EE3932 !default; //2022 red
// $cp-good-green: #00B16A !default; //good green
$cp-good-green: #33AD82 !default; //2022 ext green 3
// $cp-mango: #FFB900 !default; //mango
$cp-mango: #F4AF2D !default; //2022 ext yellow 4
// $cp-light-slate:#78909C !default; //light slate
$cp-light-slate:#999999 !default; //2022 40% black
// $cp-warning-yellow: #FFFF00 !default; //warning yellow
$cp-warning-yellow: #FFC600 !default; //2022 gold

$body-background: darken($cp-white,3%) !default;

/*Font*/
$default-font-family:"Roboto" !default;
$default-font-size:14px !default;
$default-font-color:lighten($cp-corp-third-color, 10%) !default;
$default-anchor-font-color:$cp-link-color !default;


/*Login*/
$login-panel-padding:20px 0 !default;
$login-box-padding: 10px 30px 30px 30px !default;
$login-box-input-height:40px !default;
$login-box-input-padding:10px !default;
$login-bottom-padding:40px 0 !default;

/*Checkbox*/
$checkbox-height:18px !default;
$checkbox-width:18px !default;
$checkbox-after-height:12px !default;
$checkbox-after-width:6px !default;
$checkbox-after-left:5px !default;
$checbox-span-padding-left:26px !default;


/*Radiobutton*/
$radio-height:18px !default;
$radio-width:18px !default;
$radio-after-height:10px !default;
$radio-after-width:10px !default;
$radio-after-top:3px !default;
$radio-after-left:3px !default;
$radio-span-padding-left:26px !default;

/*Button*/
$button-min-width:100px !default;
$button-padding: 8px 16px !default;
$link-button-padding:8px 20px !default;

/*Modal*/
$modal-header-padding:15px 15px !default;
$modal-footer-padding:15px 15px !default;
$modal-close-icon-top:16px !default;

/*Material*/
$material-default-font-size:($default-font-size + 10) !default;
$material-font-size-40:($default-font-size + 28) !default;
$material-font-size-38:($default-font-size + 26) !default;
$material-font-size-36:($default-font-size + 24) !default;
$material-font-size-34:($default-font-size + 22) !default;
$material-font-size-32:($default-font-size + 20) !default;
$material-font-size-30:($default-font-size + 18) !default;
$material-font-size-28:($default-font-size + 16) !default;
$material-font-size-26:($default-font-size + 14) !default;
$material-font-size-24:($default-font-size + 12) !default;
$material-font-size-22:($default-font-size + 10) !default;
$material-font-size-20:($default-font-size + 8) !default;
$material-font-size-18:($default-font-size + 6) !default;
$material-font-size-16:($default-font-size + 4) !default;
$material-font-size-14:($default-font-size + 2) !default;
$material-font-size-12:($default-font-size) !default;

/*Home*/
$home-welcome-padding-bottom:40px !default;
$home-welcome-padding-top:30px !default;
$home-welcome-section-height:50px !default;
$home-section-min-height:350px !default;

/*Header*/
$caseinfo-more-arrow-font-size:24px !default;

/*Quick Tool Bar Items*/
$quick-toolbar-items-width:40px !default;
$quick-toolbar-items-height:40px !default;
$quick-toolbar-items-padding:7px !default;

/*input textbox*/
$input-min-height:30px !default;
$input-kendo-numeric-height:30px !default;
$input-kendo-numeric-padding:0.340em 0 !default;

/*Panel*/
$panel-box-min-height:40px !default;
$panel-box-margin:10px 0 !default;
$panel-box-header-font-size:($default-font-size + 4) !default;
$panel-content-padding:10px 8px !default;
$panel-content-padding-bottom:5px !default;

/*form*/
$form-group-margin-bottom:15px !default;
$form-lable-size:2 !default;
$form-lable-size-small:0 !default;

/*Grid*/
$grid-table-tbody-td-padding:8px 8px !default;
$grid-table-thead-th-padding-top:10px !default;
$grid-table-thead-th-padding-bottom:10px !default;
$grid-height:350px !default;
$grid-row-height-35:35px !default;

/*Layout*/
$content-viewport-margin-top:15px !default;
$content-viewport-container-padding-top:10px !default;

/*Accordion*/
$accordion-height:35px !default;
$accordion-lastdiv-padding:15px 20px !default;
$accordion-expand-top:13px !default;

/*Banner*/
$parent-banner-links-li-height:45px !default;
$banner-links-li-height:45px !default;
$banner-links-li-arrow-border-left:10px solid transparent !default;
$banner-links-li-arrow-border-right:10px solid transparent !default;
$banner-links-li-arrow-border-bottom:10px solid darken($cp-white, 2%) !default;

/*Tabs*/
$tabs-ul-padding:13px 0 !default;
$tabs-li-padding:14px 21px !default;
$tab-content-padding-top:20px !default;

/*Back to top*/
$back-to-top-width:50px !default;
$back-to-top-height:50px !default;
$back-to-top-i-padding:12px !default;

/*Medical Details*/
$medical-blueman-flash-object-width:320px !default;
$medical-blueman-flash-object-height:345px !default;
$medical-blueman-bodyparts-list-height:220px !default;

/*Kendo editor*/
$kendo-editor-background:$cp-white !default;
$kendo-editor-bordercolor:darken($cp-white, 15%) !default;
