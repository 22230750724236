/*Font*/
/*Login*/
/*Checkbox*/
/*Radiobutton*/
/*Button*/
/*Modal*/
/*Material*/
/*Home*/
/*Header*/
/*Quick Tool Bar Items*/
/*input textbox*/
/*Panel*/
/*form*/
/*Grid*/
/*Layout*/
/*Accordion*/
/*Banner*/
/*Tabs*/
/*Back to top*/
/*Medical Details*/
/*Color Palette(cp) based on verisk guidelines */
/*Font*/
/*Login*/
/*Checkbox*/
/*Radiobutton*/
/*Button*/
/*Modal*/
/*Material*/
/*Home*/
/*Header*/
/*Quick Tool Bar Items*/
/*input textbox*/
/*Panel*/
/*form*/
/*Grid*/
/*Layout*/
/*Accordion*/
/*Banner*/
/*Tabs*/
/*Back to top*/
/*Medical Details*/
/*Kendo editor*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  src: local("Roboto-Regular"), url(../fonts/roboto/Roboto-Regular.ttf) format("truetype"); }

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  height: 100%;
  /* margin-bottom: 1px; */
}

body {
  background: #f7f7f7;
  color: #6d7274;
  font-family: "Roboto";
  font-size: 12px;
  overflow-x: hidden;
  position: relative;
  height: 100%; }

/*:-webkit-any(body) {
    position: static;
}*/
a {
  color: #00358E;
  text-decoration: none;
  cursor: pointer; }
  a.light {
    color: #2A7DE1;
    text-decoration: none; }
    a.light:hover {
      color: #2A7DE1;
      text-decoration: none; }
  a.white {
    color: #ffffff;
    text-decoration: none; }
    a.white:hover {
      color: #ffffff;
      text-decoration: none; }
    a.white.underline {
      padding-bottom: 3px;
      border-bottom: 1px dotted #ffffff; }
      a.white.underline:hover {
        border-bottom-style: solid; }
  a:hover {
    color: #00358E;
    text-decoration: none; }
  a:active {
    color: #00358E;
    text-decoration: none; }
  a:focus {
    color: #00358E;
    text-decoration: none;
    outline: none;
    -moz-outline-style: none; }

h1 {
  font-size: 40px; }
  h1.blue {
    color: #00358E; }

h2 {
  font-size: 30px; }
  h2.blue {
    color: #00358E; }

h3 {
  font-size: 20px; }
  h3.blue {
    color: #00358E; }

h4 {
  font-size: 16px; }
  h4.blue {
    color: #00358E;
    font-size: 12px;
  }

h5 {
  font-size: 12px; }
  h5.blue {
    color: #00358E; }

h1, h2, h3, h4, h5 {
  margin-top: 10px; }

@media only screen and (max-width: 480px) {
  body {
    font-size: 12px; } }

@media only screen and (max-width: 768px) {
  body {
    font-size: 12px; } }

@media only screen and (max-width: 1024px) {
  body {
    font-size: 12px; } }

.iso-form-control-label {
  font-size: 13px;
  font-weight: normal; }

.iso-text-valign-center::before {
  content: "";
  margin-left: calc(100% - 75%); }

.iso-text-error {
  color: #EE3932;
  padding: 15px 0; }
  .iso-text-error.center {
    text-align: center; }

@media only screen and (max-width: 480px) {
  .iso-form-control-label {
    font-size: 12px;
    font-weight: normal; } }

@media only screen and (max-width: 768px) {
  .iso-form-control-label {
    font-size: 12px;
    font-weight: normal; } }

@media only screen and (max-width: 1024px) {
  .iso-form-control-label {
    font-size: 12px;
    font-weight: normal; }
  h1 {
    font-size: 18px;
    font-weight: normal; } }

input[type=text] {
  width: 100%;
  min-height: 25px;
  border: 1px solid #d5d7d8;
  color: #6d7274;
  padding: 0 10px;
  border-radius: 0; }
  input[type=text]:hover {
    border-color: #008cd9; }
  input[type=text]:focus {
    outline: 0;
    border-color: #008cd9;
    background: #f3fbff;
    box-shadow: none; }
  input[type=text]::-ms-clear {
    display: none; }
  input[type=text]::-webkit-input-placeholder {
    color: #eff0f1; }
  input[type=text]:-moz-placeholder {
    color: #eff0f1; }
  input[type=text]:-ms-input-placeholder {
    color: #eff0f1 !important; }
  input[type=text].w100 {
    width: 100px; }
  input[type=text].w150 {
    width: 150px; }
  input[type=text].w200 {
    width: 200px; }
  input[type=text][disabled=disabled] {
    border: 1px solid #d5d7d8;
    cursor: no-drop; }

input[type=password] {
  width: 100%;
  min-height: 25px;
  border: 1px solid #d5d7d8;
  color: #6d7274;
  padding: 0 10px;
  border-radius: 0; }
  input[type=password]:hover {
    border-color: #008cd9; }
  input[type=password]:focus {
    outline: 0;
    border-color: #008cd9;
    background: #f3fbff;
    box-shadow: none; }
  input[type=password]::-ms-clear {
    display: none; }
  input[type=password]::-webkit-input-placeholder {
    color: #eff0f1; }
  input[type=password]:-moz-placeholder {
    color: #eff0f1; }
  input[type=password]:-ms-input-placeholder {
    color: #eff0f1 !important; }
  input[type=password].w100 {
    width: 100px; }
  input[type=password].w150 {
    width: 150px; }
  input[type=password].w200 {
    width: 200px; }
  input[type=password][disabled=disabled] {
    border: 1px solid #d5d7d8;
    cursor: no-drop; }

textarea {
  width: 100%;
  border: 1px solid #d5d7d8;
  color: #6d7274;
  padding: 10px;
  border-radius: 0;
  resize: none;
  min-height: 80px; }
  textarea:focus {
    outline: 0;
    border-color: #008cd9;
    background: #f3fbff; }
  textarea::-webkit-input-placeholder {
    color: #d0eaf8; }
  textarea:-moz-placeholder {
    color: #d0eaf8; }
  textarea:-ms-input-placeholder {
    color: #d0eaf8; }

input[type=checkbox] + label {
  color: #6d7274;
  margin: 0 3px;
  font-weight: normal; }

input[type=checkbox]:checked + label {
  color: #6d7274;
  font-style: normal;
  margin: 0 3px; }

input[type=radio] + label {
  color: #6d7274;
  margin: 0 3px;
  font-weight: normal;
  vertical-align: top; }

input[type=radio]:checked + label {
  color: #6d7274;
  font-style: normal;
  margin: 0 3px; }

/*Select Box*/
select {
  width: 100%;
  min-height: 30px;
  border: 1px solid #d0eaf8;
  color: #6d7274;
  padding: 0 0 0 10px; }
  select:focus {
    outline: 0; }
  select:invalid {
    color: #d0eaf8; }
  select.w100 {
    width: 100px; }
  select.w150 {
    width: 150px; }

option[value=''][disabled] {
  display: none; }

option {
  color: #6d7274; }

/*List*/
li {
  list-style-type: none;
  padding: 0; }
  li:focus {
    outline: none;
    -moz-outline-style: none; }

.list.bordered > label {
  border: 1px solid #999999;
  background: #999999;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  margin: 0; }

.list.bordered > ul {
  border: 1px solid #d0eaf8; }

.list > label {
  color: #00358E;
  border-bottom: 1px solid #00358E;
  width: 100%;
  padding-bottom: 5px; }

.list > ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0; }
  .list > ul > li {
    background: #ffffff;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #d0eaf8;
    border-bottom: 0;
    border-right: 0;
    border-left: 1px solid #ffffff; }
    .list > ul > li:first-child {
      border-top: 0; }
    .list > ul > li:last-child {
      border-bottom: 0; }
    .list > ul > li:hover {
      background: #f7f7f7;
      border-left: 1px solid #00358E; }
    .list > ul > li > a {
      text-decoration: none;
      color: #00358E; }
      .list > ul > li > a:hover {
        text-decoration: none;
        color: #00358E; }
    .list > ul > li.selected {
      background-color: #f7f7f7; }

.list.nohover > ul > li:hover {
  background: #ffffff;
  cursor: none; }

.list.noborder > ul li {
  border: 0;
  padding-left: 0; }

.list.noborder > ul:hover {
  border: 0; }

.list.noborder.center > ul > li {
  text-align: center; }

.list.noborder.nohover > ul li:hover {
  background: #ffffff;
  border: 0;
  cursor: default; }

.list.noborder.nohover.transparent > ul > li {
  background: transparent; }
  .list.noborder.nohover.transparent > ul > li:hover {
    background: transparent; }

.list.nopadding > ul > li {
  padding: 0; }

.iso-floating-list {
  background: #ffffff; }
  .iso-floating-list ul {
    padding: 0;
    list-style-type: none;
    margin: 0; }
    .iso-floating-list ul li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      border: 1px solid #d5d7d8;
      border-bottom: 1px solid #ffffff; }
      .iso-floating-list ul li:last-child {
        border-bottom: 1px solid #d5d7d8; }
      .iso-floating-list ul li:hover {
        border-color: #00358E;
        border-bottom: 1px solid #00358E;
        cursor: pointer; }
      .iso-floating-list ul li i {
        color: #00358E;
        margin: 10px 20px;
        font-size: 250%; }
      .iso-floating-list ul li h4 {
        margin-left: 20px;
        color: #00358E; }
      .iso-floating-list ul li.disabled {
        pointer-events: none;
        opacity: 0.5; }

.iso-templates-list {
  background: #ffffff; }
  .iso-templates-list .nodata {
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d5d7d8; }
  .iso-templates-list ul {
    padding: 0;
    list-style-type: none;
    margin: 0; }
    .iso-templates-list ul li {
      display: flex;
      flex-direction: column;
      border: 1px solid #d5d7d8;
      border-bottom: 1px solid #ffffff;
      width: 100%; }
      .iso-templates-list ul li:last-child {
        border-bottom: 1px solid #d5d7d8; }
      .iso-templates-list ul li:hover {
        border-color: #00358E;
        border-bottom: 1px solid #00358E; }
      .iso-templates-list ul li div {
        width: 100%; }
        .iso-templates-list ul li div.header {
          padding: 15px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center; }
          .iso-templates-list ul li div.header:hover {
            cursor: pointer; }
          .iso-templates-list ul li div.header h5 {
            margin-left: 20px;
            color: #00358E; }
          .iso-templates-list ul li div.header span i {
            margin: 0 3px;
            padding: 5px; }
            .iso-templates-list ul li div.header span i:hover {
              background: #e6e6e6;
              border-radius: 3px; }
        .iso-templates-list ul li div.preview {
          display: none;
          background: #6d7274;
          height: 500px;
          transition: 0.5s ease-in-out top;
          overflow: auto;
          position: relative; }
          .iso-templates-list ul li div.preview i {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #EE3932;
            opacity: 0.8;
            font-size: 200%;
            cursor: pointer; }
            .iso-templates-list ul li div.preview i:hover {
              opacity: 1; }
          .iso-templates-list ul li div.preview .content {
            width: 85%;
            margin: 20px auto;
            background: #ffffff; }
            .iso-templates-list ul li div.preview .content textarea {
              width: 100%;
              height: 5em; }
          .iso-templates-list ul li div.preview .k-editable-area {
            border: 0; }
          .iso-templates-list ul li div.preview .k-editor-inline {
            border: 0;
            height: 100%;
            min-height: 440px;
            padding: 50px;
            box-shadow: 10px 0 5px -2px #3F3F3F; }
            .iso-templates-list ul li div.preview .k-editor-inline ul {
              list-style-type: disc !important;
              padding: 0 20px !important; }
              .iso-templates-list ul li div.preview .k-editor-inline ul li {
                list-style-type: disc; }
            .iso-templates-list ul li div.preview .k-editor-inline ol {
              list-style-type: decimal !important;
              padding: 0 20px !important; }
              .iso-templates-list ul li div.preview .k-editor-inline ol li {
                list-style-type: decimal; }
            .iso-templates-list ul li div.preview .k-editor-inline p.logo {
              width: 210px;
              height: 45px;
              background: #f7f7f7;
              display: flex;
              justify-content: center;
              align-items: center; }
          .iso-templates-list ul li div.preview .k-editor *[contenteditable='false'] {
            color: #a0a5a7;
            cursor: default; }

.iso-templates-grid {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative; }
  .iso-templates-grid .nodata {
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d5d7d8; }
  .iso-templates-grid .template {
    height: 300px;
    width: 250px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid #d5d7d8;
    background: #ffffff; }
    .iso-templates-grid .template:hover {
      border-color: #00358E; }
    .iso-templates-grid .template .preview {
      flex-grow: 2;
      cursor: pointer; }
      .iso-templates-grid .template .preview .content {
        width: 95%;
        margin: 0 auto;
        background: #ffffff; }
        .iso-templates-grid .template .preview .content textarea {
          width: 100%;
          height: 5em; }
      .iso-templates-grid .template .preview .k-editable-area {
        border: 0; }
      .iso-templates-grid .template .preview .k-editor-inline {
        border: 0;
        height: 255px;
        overflow: hidden;
        font-size: 60%;
        padding: 10px 0; }
        .iso-templates-grid .template .preview .k-editor-inline p.logo {
          width: 100px;
          height: 22px;
          background: #f7f7f7;
          display: flex;
          justify-content: center;
          align-items: center; }
        .iso-templates-grid .template .preview .k-editor-inline ul {
          list-style-type: disc !important;
          padding: 0 20px !important; }
          .iso-templates-grid .template .preview .k-editor-inline ul li {
            list-style-type: disc; }
        .iso-templates-grid .template .preview .k-editor-inline ol {
          list-style-type: decimal !important;
          padding: 0 20px !important; }
          .iso-templates-grid .template .preview .k-editor-inline ol li {
            list-style-type: decimal; }
      .iso-templates-grid .template .preview .k-editor *[contenteditable='false'] {
        color: #a0a5a7;
        cursor: default; }
      .iso-templates-grid .template .preview.floating {
        /*position: absolute;
                top:0;
                left:0;
                right:0;*/
        position: fixed;
        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        border: 1px solid #d5d7d8;
        background: #6d7274;
        overflow: auto;
        height: 94%;
        margin-top: 23px; }
        .iso-templates-grid .template .preview.floating .content {
          min-height: 95%;
          width: 85%;
          margin: 15px auto;
          background: #ffffff;
          padding: 40px;
          box-shadow: 10px 0 5px -2px #3F3F3F; }
        .iso-templates-grid .template .preview.floating .k-editor-inline {
          height: 100%;
          width: 100%;
          font-size: 100%; }
          .iso-templates-grid .template .preview.floating .k-editor-inline p.logo {
            width: 210px;
            height: 45px; }
        .iso-templates-grid .template .preview.floating .k-editor *[contenteditable='false'] {
          color: #a0a5a7;
          cursor: default; }
    .iso-templates-grid .template .footer {
      height: 40px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      border-top: 1px solid #d5d7d8; }
      .iso-templates-grid .template .footer h5 {
        margin-left: 20px;
        color: #00358E; }
      .iso-templates-grid .template .footer span i {
        margin: 0 2px;
        font-size: 16px;
        padding: 3px; }
        .iso-templates-grid .template .footer span i:hover {
          background: #e6e6e6;
          border-radius: 3px; }

@media only screen and (min-width: 768px) {
  .iso-templates-grid .template .preview.floating {
    width: 740px; } }

@media only screen and (min-width: 992px) {
  .iso-templates-grid .template .preview.floating {
    width: 960px; } }

@media only screen and (min-width: 1200px) {
  .iso-templates-grid .template .preview.floating {
    width: 1150px; } }

.form-group {
  margin-bottom: 8px; }

.iso-form-group-inline {
  display: flex;
  flex-direction: row; }
  .iso-form-group-inline div {
    padding: 0 15px; }
    .iso-form-group-inline div label {
      padding-right: 10px; }

.iso-checked-list-box-container {
  min-height: 132px;
  overflow: auto;
  border: 1px solid #d0eaf8; }
  .iso-checked-list-box-container .iso-checked-list-group {
    margin-bottom: 0; }

.iso-checked-list-group-item {
  display: block;
  padding: 10px 15px;
  margin-bottom: 0;
  background-color: #ffffff;
  border: 0;
  font-weight: normal; }

.iso-floating-form-group {
  position: relative;
  width: 100%;
  margin: 0; }
  .iso-floating-form-group label {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 8px;
    transition: all .2s linear;
    font-size: 14px;
    padding: 0 19px;
    height: 17px;
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 500; }
  .iso-floating-form-group input {
    height: 60px;
    display: inline-block;
    padding: 0 18px;
    font-size: 12px; }
  .iso-floating-form-group input:invalid + label {
    opacity: 0; }
  .iso-floating-form-group input:valid + label {
    opacity: 1; }
  .iso-floating-form-group input:valid {
    padding-top: 15px;
    font-weight: 300; }
  .iso-floating-form-group input:focus + label {
    outline: none;
    opacity: 1; }
  .iso-floating-form-group input:focus {
    padding-top: 15px; }
  .iso-floating-form-group .iso-login-form-control-label {
    color: #08c2ed; }

/* The checkbox container */
.iso-checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .iso-checkbox-container.in-grid {
    display: inline; }
  .iso-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .iso-checkbox-container:hover input ~ .iso-checkbox-checkmark {
    background-color: #ebebeb;
    border-color: #008cd9; }
  .iso-checkbox-container input:checked ~ .iso-checkbox-checkmark {
    background-color: #ffffff;
    /*border: 1px solid $cp-white;*/ }
  .iso-checkbox-container input:checked ~ .iso-checkbox-checkmark:after {
    display: block; }
  .iso-checkbox-container .iso-checkbox-checkmark:after {
    left: 3px;
    top: 1px;
    width: 6px;
    height: 9px;
    border: solid #00358E;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

/* Create a custom checkbox */
.iso-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #f2f2f2;
  border: 1px solid #d0eaf8; }

/* Create the checkmark/indicator (hidden when not checked) */
.iso-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* The radiobox container */
.iso-radio-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .iso-radio-container.in-grid {
    display: inline; }
  .iso-radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .iso-radio-container:hover input ~ .iso-radio-checkmark {
    background-color: #ebebeb;
    border-color: #008cd9; }
  .iso-radio-container input:checked ~ .iso-radio-checkmark {
    background-color: #ffffff;
    /*border: 1px solid $cp-white;*/ }
  .iso-radio-container input:checked ~ .iso-radio-checkmark:after {
    display: block; }
  .iso-radio-container .iso-radio-checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #00358E; }
  .iso-radio-container input:checked {
    background: gray;
  }

/* Create a custom checkbox */
.iso-radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #f2f2f2;
  border: 1px solid #d0eaf8;
  border-radius: 50%; }

/* Create the checkmark/indicator (hidden when not checked) */
.iso-radio-checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.iso-file-upload {
  background: #ffffff;
  border: 2px dashed #d0eaf8;
  height: 140px; }

/*overriding the bootstrap class*/
.text-right input {
  padding-right: 10px !important; }

.iso-tree-view {
  margin: 20px 5px; }
  .iso-tree-view > ul > li {
    /*First level*/ }
    .iso-tree-view > ul > li.k-item {
      padding-top: 0px; }
    .iso-tree-view > ul > li div .k-icon {
      display: none; }
    .iso-tree-view > ul > li div .k-in {
      padding: 5px 10px;
      border: 1px solid #ffffff;
      border-radius: 6px;
      color: #ffffff; }
      .iso-tree-view > ul > li div .k-in.k-state-selected {
        color: #ffffff;
        background: #2A7DE1; }
      .iso-tree-view > ul > li div .k-in.k-state-hover {
        color: #ffffff;
        background: transparent; }
    .iso-tree-view > ul > li ul li {
      /*Second level*/ }
      .iso-tree-view > ul > li ul li.k-item {
        border-left: 1px dashed #ffffff;
        border-top: 0;
        border-right: 0;
        padding: 0;
        /*height: 40px;*/
        position: relative;
        margin-left: 12px; }
        .iso-tree-view > ul > li ul li.k-item:before {
          content: "";
          position: absolute;
          left: 0;
          top: 20px;
          height: 1px;
          width: 5%;
          border-bottom: 1px dashed #ffffff; }
        .iso-tree-view > ul > li ul li.k-item div .k-in {
          margin: 6px 5px 6px 15px; }
      .iso-tree-view > ul > li ul li ul li.k-item {
        border-left: 0 !important; }
        .iso-tree-view > ul > li ul li ul li.k-item:before {
          left: -10px !important;
          width: 12% !important; }
        .iso-tree-view > ul > li ul li ul li.k-item div .k-in {
          margin-left: 30px; }
    .iso-tree-view > ul > li:last-child > ul > li:last-child {
      border-left: 0; }
      .iso-tree-view > ul > li:last-child > ul > li:last-child:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 1px;
        height: 50%;
        border-left: 1px dashed #ffffff; }

.iso-toggle-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0; }
  .iso-toggle-switch label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 0;
    margin-right: 5px; }
    .iso-toggle-switch label input {
      display: none; }
      .iso-toggle-switch label input:checked + .slider {
        background-color: #2A7DE1; }
        .iso-toggle-switch label input:checked + .slider:before {
          transform: translateX(26px); }
    .iso-toggle-switch label .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #e0e0e0;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
      .iso-toggle-switch label .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: #ffffff;
        -webkit-transition: .4s;
        transition: .4s; }
      .iso-toggle-switch label .slider.round {
        border-radius: 34px; }
        .iso-toggle-switch label .slider.round:before {
          border-radius: 50%; }
    .iso-toggle-switch label.small {
      width: 32px;
      height: 20px; }
      .iso-toggle-switch label.small input:checked + .slider:before {
        transform: translateX(12px); }
      .iso-toggle-switch label.small .slider:before {
        height: 12px;
        width: 12px; }
    .iso-toggle-switch label.light input:checked + .slider {
      background-color: #ffffff; }
      .iso-toggle-switch label.light input:checked + .slider:before {
        background-color: #00358E; }
    .iso-toggle-switch label.green input:checked + .slider {
      background-color: #32ffad; }
    .iso-toggle-switch label.red input:checked + .slider {
      background-color: #33AD82; }
    .iso-toggle-switch label.dark input:checked + .slider {
      background-color: #3F3F3F; }
    .iso-toggle-switch label.blue input:checked + .slider {
      background-color: #00358E; }

button {
  min-height: 30px;
  background: #2A7DE1;
  color: #ffffff;
  border: 1px solid #2A7DE1;
  padding: 5px 16px;
  text-transform: uppercase;
  min-width: 80px;
  margin: 5px 5px 5px 0; }
  button:focus {
    outline: none; }
  button:hover {
    background: #2264B4;
    color: #ffffff; }
  button:disabled {
    color: #d0eaf8;
    cursor: no-drop; }
  button span:before {
    padding-right: 5px; }
  button[disabled=disabled] {
    color: #d0eaf8;
    cursor: no-drop; }
  button.square {
    min-height: 50px;
    min-width: 50px;
    border-radius: 10px;
    font-size: 14px; }
    button.square span {
      font-size: 24px; }
  button.roundbutton {
    min-width: 0;
    border-radius: 50%;
    padding: 10px;
    margin: 0 5px 5px 0;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    /*i {
            position: absolute;
            top: 8px;
            left: 8px;
        }*/ }
  button.floating-button {
    min-width: 0;
    border-radius: 50%;
    padding: 5px 10px;
    box-shadow: 0 4px 8px 0 #4baee4;
    transition: 0.3s; }
    button.floating-button i {
      /*position:absolute;
            top:8px;
            left:8px;*/
      padding-top: 4px; }
  button.flat-button {
    background: #ffffff;
    color: #2A7DE1;
    border: 0;
    font-weight: bold;
    min-width: 50px;
    font-size: 13px; }
    button.flat-button.transparent {
      background: transparent; }
    button.flat-button.dotted {
      border: 1px dotted #2A7DE1;
      padding: 10px;
      border-radius: 4px; }
    button.flat-button.withicon {
      display: flex;
      align-items: center; }
      button.flat-button.withicon i {
        border: 1px solid #2A7DE1;
        border-radius: 50%;
        padding: 3px; }
    button.flat-button.withicon-colum {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-weight: normal;
      margin: 10px; }
      button.flat-button.withicon-colum i {
        border: 1px solid #2A7DE1;
        border-radius: 50%;
        padding: 3px;
        margin-bottom: 10px; }
      button.flat-button.withicon-colum:hover {
        font-weight: bold; }
        button.flat-button.withicon-colum:hover i {
          background: #2A7DE1;
          color: #ffffff; }
    button.flat-button i {
      margin-right: 5px; }
  button.iso-cancel {
    background: #ffffff;
    border: 1px solid #2A7DE1;
    color: #2A7DE1; }
    button.iso-cancel:hover {
      background: #fcfeff;
      border: 1px solid #35a4e1;
      color: #35a4e1; }
  button.iso-close {
    background: #ffffff;
    border: 1px solid #2A7DE1;
    color: #2A7DE1; }
    button.iso-close:hover {
      background: #fcfeff;
      border: 1px solid #35a4e1;
      color: #35a4e1; }
  button.iso-close-warning {
    background: #ffffff;
    border: 1px solid #F4AF2D;
    color: #F4AF2D; }
    button.iso-close-warning:hover {
      background: #fcfeff;
      border: 1px solid #35a4e1;
      color: #35a4e1; }
    button.iso-close-warning.flat-button {
      background: #ffffff;
      color: #F4AF2D;
      font-weight: bold;
      border: 0;
      font-size: 13px; }
  button.iso-clear {
    background: #ffffff;
    border: 1px solid #2A7DE1;
    color: #2A7DE1; }
    button.iso-clear:hover {
      background: #fcfeff;
      border: 1px solid #35a4e1;
      color: #35a4e1; }
  button.iso-warning-action {
    background: #F4AF2D;
    color: #ffffff;
    border: 1px solid #F4AF2D;
    /*&:hover {
            @include secondarybutton(darken($cp-mango,5%),$cp-white);
        }*/ }
    button.iso-warning-action.flat-button {
      background: #ffffff;
      color: #F4AF2D;
      font-weight: bold;
      border: 0;
      font-size: 13px; }
  button.iso-close-error {
    background: #EE3932;
    color: #ffffff;
    border: 1px solid #EE3932;
    /*&:hover {
            @include secondarybutton(darken($cp-error-red,5%),$cp-white);
        }*/ }
    button.iso-close-error.flat-button {
      background: #ffffff;
      color: #EE3932;
      font-weight: bold;
      border: 0;
      font-size: 13px; }

.iso-link-button {
  border: 1px solid #2A7DE1;
  padding: 5px 10px;
  text-transform: uppercase;
  background: #ffffff;
  color: #2A7DE1;
  font-size: 12px; }
  .iso-link-button[disabled=disabled] {
    border: 1px solid #d0eaf8;
    cursor: no-drop; }
    .iso-link-button[disabled=disabled]:hover {
      color: #d0eaf8;
      background: #ffffff; }
  .iso-link-button:hover {
    color: #2A7DE1;
    background: #f3fbff; }
  .iso-link-button i {
    float: left;
    font-size: 18px;
    margin-right: 8px; }
  .iso-link-button.small {
    padding: 5px 10px;
    font-size: 12px; }
  .iso-link-button.round {
    border-radius: 20px; }
  .iso-link-button.round8 {
    border-radius: 8px; }
  .iso-link-button.round4 {
    border-radius: 4px; }
  .iso-link-button.cancel {
    border: 1px solid #d0eaf8;
    color: #047CB1; }
    .iso-link-button.cancel:hover {
      color: #047CB1;
      background: #f2f2f2; }

.iso-close-icon {
  float: right;
  opacity: 0.5;
  background-repeat: no-repeat; }
  .iso-close-icon.white {
    float: right;
    opacity: 1.0; }

.iso-button-dialog {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  cursor: pointer;
  background: #e6e6e6;
  margin: 3px;
  color: #3F3F3F; }
  .iso-button-dialog div {
    padding: 5px; }
  .iso-button-dialog i {
    padding: 0; }
  .iso-button-dialog:hover {
    background: #d9d9d9; }
  .iso-button-dialog.active {
    background: #6d7274;
    color: #ffffff;
    pointer-events: none; }
  .iso-button-dialog.chip {
    border-radius: 16px; }
  .iso-button-dialog.selected {
    background: #008cd9;
    color: #ffffff; }
    .iso-button-dialog.selected:hover {
      background: #00358E; }
    .iso-button-dialog.selected.active {
      background: #00358E;
      pointer-events: none; }
    .iso-button-dialog.selected i {
      border-radius: 50%;
      font-size: 100%;
      background: #004a73;
      margin: 4px 0 4px 10px;
      padding: 3px;
      color: rgba(255, 255, 255, 0.5); }
      .iso-button-dialog.selected i:hover {
        background: rgba(255, 255, 255, 0.5);
        color: #00358E; }

.iso-button-dialog-text {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center; }

.iso-button-dialog-container {
  background: #ffffff;
  position: absolute;
  min-width: 300px;
  min-height: 200px;
  box-shadow: 5px 6px 10px -3px #3F3F3F;
  z-index: 1;
  display: none;
  border-radius: 6px;
  transition: margin-left .5s;
  border: 1px solid #e6e6e6; }
  .iso-button-dialog-container .header {
    /*color: $cp-corp-color;*/
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 16px;
    /*color: $cp-white;*/
    font-size: 140%; }
    .iso-button-dialog-container .header i {
      cursor: pointer;
      padding: 5px; }
      .iso-button-dialog-container .header i:hover {
        background: #e6e6e6;
        border-radius: 3px; }
  .iso-button-dialog-container .content {
    padding: 10px 0; }
    .iso-button-dialog-container .content .selctall {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 120%;
      padding: 0 10px 20px 16px; }
    .iso-button-dialog-container .content .searchbox {
      padding: 0 16px 5px 16px; }
    .iso-button-dialog-container .content .checklist {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 450px;
      padding: 5px 0 5px 16px; }
      .iso-button-dialog-container .content .checklist:focus {
        border-top: 1px solid #f2f2f2; }
      .iso-button-dialog-container .content .checklist .nodata {
        padding-top: 20px;
        color: #EE3932; }
    .iso-button-dialog-container .content .rangeheader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px 5px 16px; }
      .iso-button-dialog-container .content .rangeheader .rangeoutput {
        color: #00358E; }
    .iso-button-dialog-container .content .rangeslider {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center; }

.iso-left-menu {
  background: #004EAA;
  position: fixed;
  height: 100%;
  z-index: 1001;
  padding-top: 50px;
  left: 0;
  transition: 0.5s;
  width: 45px;
  box-shadow: 0px 0px 20px #232425; }
  .iso-left-menu.iso-left-menu-expanded {
    width: 225px;
    transition: 0.5s; }
    .iso-left-menu.iso-left-menu-expanded .iso-left-menu-open {
      justify-content: flex-end; }
      .iso-left-menu.iso-left-menu-expanded .iso-left-menu-open i {
        transform: rotate(180deg); }
  .iso-left-menu .iso-left-menu-open {
    width: 100%;
    background: #004EAA;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.15);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .iso-left-menu .iso-left-menu-open > span {
      /*width: 100%;*/
      float: right;
      background: #004EAA;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 2px; }
      .iso-left-menu .iso-left-menu-open > span i {
        padding: 10px;
        float: right; }
  .iso-left-menu .tooltip {
    min-width: 120px; }
  .iso-left-menu .tooltip-inner {
    background: #3b3e40;
    padding: 8px 0; }
  .iso-left-menu .tooltip.right .tooltip-arrow {
    border-right-color: #3b3e40; }
  .iso-left-menu.gray {
    background: #f0f0f0;
    box-shadow: none; }
    .iso-left-menu.gray .iso-left-menu-open {
      background: #f0f0f0;
      border-bottom: 1px solid rgba(63, 63, 63, 0.2); }
      .iso-left-menu.gray .iso-left-menu-open span {
        background: #f0f0f0; }
        .iso-left-menu.gray .iso-left-menu-open span i {
          color: #3b3e40; }
    .iso-left-menu.gray .iso-left-menu-icons {
      border-left: 0px;
      margin: 5px; }
      .iso-left-menu.gray .iso-left-menu-icons li {
        border-left: 0;
        border-radius: 4px;
        margin-bottom: 3px; }
        .iso-left-menu.gray .iso-left-menu-icons li span {
          color: #3b3e40; }
        .iso-left-menu.gray .iso-left-menu-icons li i {
          color: #3b3e40; }
        .iso-left-menu.gray .iso-left-menu-icons li:hover {
          background: #d9d9d9; }
        .iso-left-menu.gray .iso-left-menu-icons li.active {
          background: #d9d9d9; }

.iso-left-menu-icons {
  padding: 0px;
  list-style-type: none; }
  .iso-left-menu-icons > li {
    display: table;
    padding: 10px;
    width: 100%;
    border: 0;
    cursor: pointer;
    text-align: left;
    border-left: 1px; }
    .iso-left-menu-icons > li:hover {
      background: rgba(255, 255, 255, 0.15);
      border: 0;
      border-left: 1px; }
    .iso-left-menu-icons > li.active {
      /*color: $cp-mango;*/
      border-left: 1px solid #F4AF2D;
      background: rgba(255, 255, 255, 0.12);
      /*i {
                color: $cp-mango;
            }

            span {
                color: $cp-mango;
            }*/ }
    .iso-left-menu-icons > li > span {
      color: #ffffff;
      display: none;
      vertical-align: middle;
      text-align: left;
      width: 100%;
      padding-left: 15px; }
      .iso-left-menu-icons > li > span.showtext {
        display: table-cell; }
      .iso-left-menu-icons > li > span.hidetext {
        display: none; }
    .iso-left-menu-icons > li > i {
      color: #ffffff; }

.iso-banner {
  font-size: 16px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  background: linear-gradient(141deg, #004a73 10%, #009cf3 50%, #004a73 85%);
  position: relative; }
  .iso-banner .iso-banner-container {
    margin: 0 60px 0 45px;
    border-right: 0px solid #005b8d; }
  .iso-banner .iso-banner-items {
    position: absolute;
    right: 4px;
    bottom: -55px; }
    .iso-banner .iso-banner-items ul {
      display: flex; }
      .iso-banner .iso-banner-items ul li {
        flex: 0 0 auto;
        border: 1px solid #2A7DE1; }
  .iso-banner .scroller-wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    height: 30px; }
    .iso-banner .scroller-wrapper .scroller {
      width: 40px;
      text-align: center;
      cursor: pointer;
      display: none;
      padding: 5px;
      margin-top: 5px; }
      .iso-banner .scroller-wrapper .scroller.scroller-right {
        /*float: right;*/
        position: absolute;
        right: 0;
        border-bottom-left-radius: 36px;
        border-top-left-radius: 36px;
        background: #fcfeff;
        opacity: 0.7;
        z-index: 997;
        padding: 0; }
        .iso-banner .scroller-wrapper .scroller.scroller-right:hover {
          opacity: 0.8; }
        .iso-banner .scroller-wrapper .scroller.scroller-right i {
          /*background: $cp-corp-active-color;*/
          /*padding: 2px;*/
          padding: 5px 0;
          /*border-radius: 50%;*/
          color: black; }
      .iso-banner .scroller-wrapper .scroller.scroller-left {
        /*float: left;*/
        position: absolute;
        left: 0;
        border-bottom-right-radius: 36px;
        border-top-right-radius: 36px;
        background: #fcfeff;
        opacity: 0.7;
        z-index: 997;
        padding: 0; }
        .iso-banner .scroller-wrapper .scroller.scroller-left:hover {
          opacity: 0.8; }
        .iso-banner .scroller-wrapper .scroller.scroller-left i {
          /*background: $cp-corp-active-color;*/
          /*padding: 2px;*/
          padding: 4px 0;
          /*border-radius: 50%;*/
          color: black; }

.iso-parent-banner-links {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  /*-webkit-flex-wrap: wrap;*/
  /*flex-wrap: wrap;*/
  margin-top: 5px;
  margin-bottom: 0px;
  /*position: absolute;*/
  /*justify-content: center;*/
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2); }
  .iso-parent-banner-links > li {
    border-bottom: 0px dotted #d9d9d9;
    font-size: 14px;
    flex: 0 0 auto;
    height: 30px;
    line-height: 30px;
    position: relative;
    z-index: 1; }
    .iso-parent-banner-links > li span {
      color: #f2f2f2;
      padding: 10px 16px;
      text-decoration: none; }
    .iso-parent-banner-links > li:hover > a {
      color: #F4AF2D; }
    .iso-parent-banner-links > li.active:before {
      content: "";
      position: absolute;
      left: 25%;
      bottom: 0;
      height: 1px;
      width: 50%;
      border-bottom: 2px solid #F4AF2D; }
    .iso-parent-banner-links > li.active span {
      color: #ffffff;
      /*font-weight: bold;*/ }

.iso-banner-links {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  /*-webkit-flex-wrap: wrap;*/
  /*flex-wrap: wrap;*/
  margin-bottom: 0px;
  /*position: absolute;*/
  /*justify-content: center;*/ }
  .iso-banner-links > li {
    font-size: 14px;
    flex: 0 0 auto;
    height: 30px;
    line-height: 30px;
    position: relative; }
    .iso-banner-links > li a {
      color: #f2f2f2;
      padding: 10px 16px;
      text-decoration: none;
      border-right: 0px dotted #cccccc; }
    .iso-banner-links > li:last-child a {
      border-right: 0; }
    .iso-banner-links > li:hover > a {
      color: #ffffff; }
    .iso-banner-links > li.active {
      /*padding: 0 15px 0 0;*/ }
      .iso-banner-links > li.active:after {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-bottom-color: #fafafa;
        content: " ";
        position: absolute;
        bottom: 0;
        left: 45%;
        z-index: 0; }
      .iso-banner-links > li.active > a {
        color: #ffffff;
        /*font-weight: bold;*/
        cursor: default;
        /*padding-right: 32px;*/ }
      .iso-banner-links > li.active i {
        position: absolute;
        top: 15px;
        right: 8px;
        font-weight: normal;
        cursor: pointer;
        font-size: 16px; }

.iso-banner-scroller {
  width: 40px;
  text-align: center;
  cursor: pointer;
  /*display: none;*/
  padding: 5px;
  margin-top: 5px; }

.iso-banner-scroller-right {
  float: right; }

.iso-banner-scroller-left {
  float: left; }

.iso-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border: 1px solid #00358E;
  opacity: 0.5;
  display: none;
  border-radius: 50%; }
  .iso-back-to-top > i {
    padding: 9px;
    width: 100%;
    height: 100%; }
  .iso-back-to-top:hover {
    opacity: 1;
    cursor: pointer; }

.iso-dropdown-menu {
  position: relative;
  display: inline-block; }
  .iso-dropdown-menu:hover .iso-dropdown-menu-content {
    display: block; }
  .iso-dropdown-menu:hover span {
    color: #ffffff;
    background: #176b99;
    border-bottom: 3px solid #15618b;
    cursor: pointer; }
  .iso-dropdown-menu.menu01 {
    color: #ffffff;
    padding: 8px 12px;
    padding-right: 8px;
    /*background: hsla(0,0%,100%,.15);*/
    border: 0px solid rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    margin: 5px 0;
    display: inline-flex; }
    .iso-dropdown-menu.menu01 span {
      font-size: 120%;
      border-bottom: 0; }
    .iso-dropdown-menu.menu01 i {
      margin-left: 3px; }
    .iso-dropdown-menu.menu01 .iso-dropdown-menu-content {
      top: -2px;
      left: -3px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px #6d7274;
      opacity: 1;
      padding: 15px 0;
      font-size: 115%;
      transform: scale3d(0.8, 0.8, 1);
      -webkit-transition: opacity 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
      transform-origin: left top;
      display: none;
      opacity: 0; }
      .iso-dropdown-menu.menu01 .iso-dropdown-menu-content a {
        color: #6d7274;
        padding: 8px 24px 8px 56px;
        position: relative; }
        .iso-dropdown-menu.menu01 .iso-dropdown-menu-content a:hover {
          background: whitesmoke; }
        .iso-dropdown-menu.menu01 .iso-dropdown-menu-content a.selected {
          font-weight: 600; }
          .iso-dropdown-menu.menu01 .iso-dropdown-menu-content a.selected:before {
            /*Add another block-level blank space*/
            content: '';
            display: block;
            /*Make it a small rectangle so the border will create an L-shape*/
            width: 7px;
            height: 14px;
            /*Add a white border on the bottom and left, creating that 'L' */
            border: solid #00358E;
            border-width: 0 2px 2px 0;
            /*Rotate the L 45 degrees to turn it into a checkmark*/
            transform: rotate(45deg);
            position: absolute;
            left: 28px; }
      .iso-dropdown-menu.menu01 .iso-dropdown-menu-content:hover {
        -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transform: scale3d(1, 1, 1); }
    .iso-dropdown-menu.menu01:hover .iso-dropdown-menu-content {
      display: block;
      opacity: 1; }

.iso-dropdown-menu-content {
  display: none;
  position: absolute;
  background-color: #176b99;
  min-width: 200px;
  box-shadow: 0px 4px 8px 0px #176b99;
  z-index: 9999;
  width: 100%;
  color: #ffffff;
  opacity: 0.95; }
  .iso-dropdown-menu-content a {
    color: #f2f2f2;
    width: 100%;
    display: inline-block;
    padding: 3px 16px; }
    .iso-dropdown-menu-content a:hover {
      background: #1974a6; }
  .iso-dropdown-menu-content.right {
    right: 0; }

.iso-quick-toolbar-items {
  /*position: relative;*/
  padding-top: 0px; }
  .iso-quick-toolbar-items ul {
    /*position: absolute;
        right: 5px;
        top: -6px;
        z-index: 998;*/
    display: flex;
    margin: 0;
    justify-content: flex-end; }
    .iso-quick-toolbar-items ul li {
      flex: 0 0 auto;
      border: 0px solid #2A7DE1;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      /*padding: $quick-toolbar-items-padding;*/
      cursor: pointer;
      /*margin-right: 10px;*/ }
      .iso-quick-toolbar-items ul li i {
        font-weight: normal;
        color: #93989a;
        padding: 5px; }
      .iso-quick-toolbar-items ul li button {
        padding: 0; }
      .iso-quick-toolbar-items ul li:hover {
        /*background: $cp-corp-active-color;*/ }
        .iso-quick-toolbar-items ul li:hover i {
          color: #3F3F3F;
          background: #e6e6e6;
          border-radius: 3px; }

.iso-overlay-menu {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: hidden;
  transition: 0.5s; }
  .iso-overlay-menu .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 40px;
    color: #EE3932; }
  .iso-overlay-menu .iso-overlay-menu-content {
    position: relative;
    top: 50px;
    width: 100%;
    text-align: left;
    margin-top: 30px; }
    .iso-overlay-menu .iso-overlay-menu-content ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      *display: inline;
      zoom: 1;
      top: 0;
      vertical-align: top;
      color: #e6e6e6; }
      .iso-overlay-menu .iso-overlay-menu-content ul li {
        font-weight: normal;
        padding: 10px 0;
        font-size: 14px; }
        .iso-overlay-menu .iso-overlay-menu-content ul li a {
          color: #bfbfbf;
          width: 100%; }
          .iso-overlay-menu .iso-overlay-menu-content ul li a:hover {
            color: #ffffff; }
        .iso-overlay-menu .iso-overlay-menu-content ul li.active a {
          color: #F4AF2D; }

.iso-breadcrumb {
  margin-top: 10px; }
  .iso-breadcrumb ul {
    padding: 5px 0px;
    list-style: none;
    /*background-color: darken($cp-white,5%);*/
    margin-bottom: 0; }
    .iso-breadcrumb ul li {
      display: inline;
      font-size: 16px; }
      .iso-breadcrumb ul li a {
        color: #00358E; }
    .iso-breadcrumb ul li + li:before {
      padding: 8px;
      color: #135b83;
      content: "/\00a0"; }

@media only screen and (max-width: 480px) {
  .iso-left-menu {
    width: 225px;
    left: -225px; }
  .iso-banner {
    font-size: 12px; }
  .iso-back-to-top {
    display: none;
    opacity: 0; }
  }

@media only screen and (max-width: 768px) {
  .iso-banner {
    font-size: 12px; }
  .iso-back-to-top {
    display: none;
    opacity: 0; }
  }

@media only screen and (max-width: 1024px) {
  .iso-left-menu {
    width: 400px;
    left: -400px;
    transition: 0.5s;
    z-index: 998; }
    .iso-left-menu .iso-left-menu-open {
      display: none; }
    .iso-left-menu.active {
      left: 0; }
    .iso-left-menu .tooltip {
      display: none; }
  .iso-left-menu-icons {
    padding: 0 20px; }
    .iso-left-menu-icons > li {
      font-size: 14px; }
      .iso-left-menu-icons > li:hover {
        background: #00358E; }
      .iso-left-menu-icons > li > span {
        display: table-cell; }
        .iso-left-menu-icons > li > span.hidetext {
          display: table-cell; }
  .iso-banner {
    font-size: 12px; }
  .iso-back-to-top {
    right: 30px;
    bottom: 30px; }
  .iso-back-to-top > i {
    padding: 9px; } }

.alert {
  margin: 10px 0 10px 0;
  padding-right: 15px; }

.alert-danger {
  border-radius: 4px;
  color: #EE3932;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #EE3932;
  padding: 0;
  padding: 15px;
  padding-left: 60px;
  min-height: 50px; }
  .alert-danger div:first-child {
    background: #EE3932;
    width: 50px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    text-align: center; }
    .alert-danger div:first-child i {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .alert-danger div:last-child ul {
    margin: 0; }

.alert-success {
  border-radius: 3px;
  color: #33AD82;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #33AD82;
  padding: 0;
  padding: 15px;
  padding-left: 60px;
  min-height: 50px; }
  .alert-success div:first-child {
    background: #33AD82;
    width: 50px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    text-align: center; }
    .alert-success div:first-child i {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .alert-success div:last-child ul {
    margin: 0; }

.alert-modal {
  margin: 0 0 15px 0;
  padding-right: 15px; }

.iso-coa-messages {
  background: #EE3932;
  height: 50px;
  margin-bottom: 10px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  -webkit-animation-name: coamessages;
  /* Chrome, Safari, Opera */
  -webkit-animation-duration: 3s;
  /* Chrome, Safari, Opera */
  animation-name: coamessages;
  animation-duration: 3s;
  animation-iteration-count: infinite; }

/* Chrome, Safari, Opera */
@-webkit-keyframes coamessages {
  from {
    background-color: #EE3932; }
  to {
    background-color: #bf1b02; } }

/* Standard syntax */
@-moz-keyframes coamessages {
  from {
    background-color: #EE3932; }
  to {
    background-color: #bf1b02; } }

@keyframes coamessages {
  from {
    background-color: #EE3932; }
  to {
    background-color: #bf1b02; } }

.iso-coa-messages01 {
  transition: all .2s linear;
  background: rgba(0, 0, 0, 0.6); }
  .iso-coa-messages01.error .content {
    color: #EE3932; }
    .iso-coa-messages01.error .content:before {
      background-color: #EE3932; }
  .iso-coa-messages01.warning .content {
    color: #F4AF2D; }
    .iso-coa-messages01.warning .content:before {
      background-color: #F4AF2D; }
  .iso-coa-messages01 .content {
    padding: 20px 20px 20px 36px;
    position: relative;
    font-size: 16px;
    color: #17cef8; }
    .iso-coa-messages01 .content:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #17cef8;
      border-radius: 50%; }

.iso-global-message {
  position: fixed;
  bottom: 0;
  background: #33AD82;
  width: 100%;
  color: #ffffff;
  z-index: 997;
  min-height: 60px;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center; }

.iso-alert-danger {
  border-radius: 3px;
  color: #EE3932;
  background: rgba(253, 92, 67, 0.1);
  font-size: 14px;
  border: 1px solid #EE3932;
  padding: 10px;
  padding-left: 35px;
  position: relative; }
  .iso-alert-danger:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #EE3932;
    border-radius: 50%; }

.iso-alert-info {
  border-radius: 3px;
  color: #2A7DE1;
  background: rgba(7, 174, 213, 0.1);
  font-size: 14px;
  border: 1px solid #2A7DE1;
  padding: 10px;
  padding-left: 35px;
  position: relative; }
  .iso-alert-info:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2A7DE1;
    border-radius: 50%; }

.iso-alert-warning {
  border-radius: 3px;
  color: #cc9400;
  background: rgba(255, 192, 26, 0.1);
  font-size: 14px;
  border: 1px solid #F4AF2D;
  padding: 10px;
  padding-left: 35px;
  position: relative; }
  .iso-alert-warning:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #F4AF2D;
    border-radius: 50%; }

.iso-alert-success {
  border-radius: 3px;
  color: #33AD82;
  background: rgba(0, 203, 121, 0.1);
  font-size: 14px;
  border: 1px solid #33AD82;
  padding: 10px;
  padding-left: 35px;
  position: relative; }
  .iso-alert-success:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #33AD82;
    border-radius: 50%; }

.modal {
  text-align: center;
  padding: 0 !important; }
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  /* max-width: 700px;  */
  }

.modal-alerts {
  z-index: 9999 !important; }
  .modal-alerts .modal-dialog {
    width: auto;
    min-width: 400px;
    max-width: 1000px; }
  .modal-alerts.error .modal-content {
    border: 0;
    border-left: 5px solid #EE3932;
    background: #ffffff;
    padding: 10px 5px; }
  .modal-alerts.warning .modal-content {
    border: 0;
    border-left: 5px solid #F4AF2D;
    background: #ffffff;
    padding: 10px 5px; }
  .modal-alerts.info .modal-content {
    border: 0;
    border-left: 5px solid #33AD82;
    background: #ffffff;
    padding: 10px 5px; }

.modal-alerts + .modal-backdrop {
  background: #3F3F3F; }

.modal-header {
  padding: 0px 15px;
  border-bottom: 1px solid #e5e5e5; }
  .modal-header.error {
    color: #3F3F3F;
    border: 0;
    padding: 15px; }
    .modal-header.error i {
      color: #EE3932;
      font-size: 35px;
      position: absolute;
      top: 30%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 9999; }
    .modal-header.error span {
      padding-left: 49px;
      line-height: 10px;
      font-weight: bold; }
  .modal-header.warning {
    color: #3F3F3F;
    border: 0;
    padding: 15px; }
    .modal-header.warning i {
      color: #F4AF2D;
      font-size: 35px;
      position: absolute;
      top: 30%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 9999; }
    .modal-header.warning span {
      padding-left: 49px;
      line-height: 10px;
      font-weight: bold; }
  .modal-header.info {
    color: #3F3F3F;
    border: 0;
    padding: 15px; }
    .modal-header.info i {
      color: #33AD82;
      font-size: 35px;
      position: absolute;
      top: 30%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 9999; }
    .modal-header.info span {
      padding-left: 49px;
      line-height: 10px;
      font-weight: bold; }

.modal-body p {
  margin: 0;
  font-size: 14px; }

.modal-body.error {
  color: #6d7274;
  padding: 0; }
  .modal-body.error p {
    padding: 0 65px; }

.modal-body.warning {
  color: #6d7274;
  padding: 0; }
  .modal-body.warning p {
    padding: 0 65px; }

.modal-body.info {
  color: #6d7274;
  padding: 0; }
  .modal-body.info p {
    padding: 0 65px; }

.modal-footer {
  padding: 5px 10px;
  width: 100%; }
  .modal-footer.error {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 64px; }
    .modal-footer.error button {
      border: 0px solid #ffffff;
      color: #ffffff;
      background: #EE3932; }
  .modal-footer.warning {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 64px; }
    .modal-footer.warning button {
      border: 0px solid #ffffff;
      color: #ffffff;
      background: #F4AF2D; }
  .modal-footer.info {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 64px; }
    .modal-footer.info button {
      border: 0px solid #ffffff;
      color: #ffffff;
      background: #33AD82; }

.modal-content {
  border-radius: 0; }

.iso-modal-header {
  color: #00358E;
  position: relative;
  height: 40px;
  border-bottom: 1px solid #e5e5e5; }
  .iso-modal-header h3 {
    margin: 10px 5px;
    color: #00358E;
    text-transform: uppercase; }
  .iso-modal-header i {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #00358E; }

.iso-modal-header-icons {
  position: relative;
  margin-right: 5px;
  top: 6px; }

.iso-modal-header-text {
  font-size: 16px;
  line-height: 40px; }

.iso-modal-close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  text-shadow: 0 1px 0 #ffffff;
  filter: alpha(opacity=80);
  opacity: .8;
  color: #00358E; }

.iso-modal-close:hover,
.iso-modal-close:focus {
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1; }

/*View as modal*/
.modal-fullheight .modal-dialog {
  background: #ffffff;
  height: 80%;
  margin: 0; }

.modal-fullheight .modal-backdrop {
  background-color: #f0f0f0; }
  .modal-fullheight .modal-backdrop.in {
    opacity: 0.9; }

.modal-fullheight .modal-content {
  box-shadow: none;
  border: 0; }
  .modal-fullheight .modal-content .iso-modal-header {
    height: 50px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    .modal-fullheight .modal-content .iso-modal-header h3 {
      margin-bottom: 25px; }
    .modal-fullheight .modal-content .iso-modal-header i {
      top: 15px;
      right: 15px;
      font-size: 26px; }
  .modal-fullheight .modal-content .modal-body {
    padding: 0 30px; }
  .modal-fullheight .modal-content .modal-footer {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 0 30px; }
    .modal-fullheight .modal-content .modal-footer button {
      padding: 10px 16px;
      width: 150px; }

.iso-modal-action-confirm .success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }
  .iso-modal-action-confirm .success i {
    font-size: 60px;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #33AD82;
    color: #33AD82; }
  .iso-modal-action-confirm .success span {
    padding: 20px 20px 40px 20px;
    font-size: 20px;
    color: #33AD82; }
  .iso-modal-action-confirm .success a {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 16px; }
  .iso-modal-action-confirm .success button {
    padding: 10px 16px;
    width: 150px; }

.iso-modal-action-confirm .failed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }
  .iso-modal-action-confirm .failed i {
    font-size: 60px;
    padding: 10px;
    color: #EE3932; }
  .iso-modal-action-confirm .failed span {
    padding: 20px 20px 40px 20px;
    font-size: 20px;
    color: #EE3932; }

/*Blur the background*/
body.modal-open .iso-main-view {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  height: 100%; }

.iso-panel-separator {
  border-right: 1px solid #d0eaf8; }
  .iso-panel-separator.left {
    border-left: 1px solid #d0eaf8; }

/*.iso-panel-box {
    background: $cp-white;
    border: 1px solid lighten($cp-corp-second-color,50%);
    height: 100%;
    margin: 10px 0;
    min-height: 80px;

    &.noborder {
        border: 0px;
    }

    &.light {
        padding: 5px;
    }
}*/
.iso-panel-box {
  border: 0px solid #d5d7d8;
  height: 100%;
  margin: 0;
  min-height: 40px; }
  .iso-panel-box.noborder {
    border: 0px; }
  .iso-panel-box.bordered {
    border: 1px solid #d5d7d8; }
    .iso-panel-box.bordered.top {
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 0; }
    .iso-panel-box.bordered.bottom {
      border-left-width: 0;
      border-right-width: 0;
      border-top-width: 0; }
    .iso-panel-box.bordered.mango {
      border-color: #F4AF2D; }
  .iso-panel-box.bg-white {
    background: #ffffff;
    padding: 5px 10px; }
  .iso-panel-box.light {
    padding: 5px; }
  .iso-panel-box.bottom-shadow {
    box-shadow: 0px 19px 11px -11px #e6e6e6;
    padding-bottom: 15px; }
  .iso-panel-box.top-seperator {
    position: relative;
    background: #ffffff;
    padding: 5px;
    border: 1px solid #d5d7d8; }
    .iso-panel-box.top-seperator:before {
      height: 15px;
      background: #ffffff;
      width: 51%;
      content: '';
      position: absolute;
      top: -15px;
      transform: skewX(-45deg);
      left: 7px;
      border-left: 1px solid #d5d7d8; }
    .iso-panel-box.top-seperator:after {
      height: 15px;
      background: #ffffff;
      width: 51%;
      content: '';
      position: absolute;
      top: -15px;
      transform: skewX(45deg);
      right: 6px;
      border-right: 1px solid #d5d7d8; }
    .iso-panel-box.top-seperator.bordered:before {
      border-top: 1px dashed #bbbebf; }
    .iso-panel-box.top-seperator.bordered:after {
      border-top: 1px dashed #bbbebf; }
  .iso-panel-box.bottom-seperator {
    position: relative;
    background: #ffffff;
    padding: 5px;
    border: 1px solid #d5d7d8; }
    .iso-panel-box.bottom-seperator:before {
      height: 15px;
      background: #ffffff;
      width: 51%;
      content: '';
      position: absolute;
      bottom: -15px;
      transform: skewX(45deg);
      left: 7px;
      border-left: 1px solid #d5d7d8; }
    .iso-panel-box.bottom-seperator:after {
      height: 15px;
      background: #ffffff;
      width: 51%;
      content: '';
      position: absolute;
      bottom: -15px;
      transform: skewX(-45deg);
      right: 6px;
      border-right: 1px solid #d5d7d8; }
    .iso-panel-box.bottom-seperator.bordered:before {
      border-bottom: 1px dashed #bbbebf; }
    .iso-panel-box.bottom-seperator.bordered:after {
      border-bottom: 1px dashed #bbbebf; }

.iso-panel-box-header {
  min-height: 40px;
  font-size: 15px;
  text-transform: capitalize;
  padding: 10px 0;
  line-height: 23px;
  /*border-bottom: 1px solid lighten($cp-corp-second-color,50%);*/
  /*background: darken($cp-white,1%);*/
  color: #3b3e40; }
  .iso-panel-box-header i {
    float: left;
    /*padding-right: 8px;*/
    background-repeat: no-repeat; }
  .iso-panel-box-header a i {
    float: right;
    background-repeat: no-repeat;
    opacity: 0.5; }
  .iso-panel-box-header.bordered {
    border: 1px solid #d5d7d8;
    padding-left: 10px;
    border-bottom: 0;
    background: #fafafa; }
  .iso-panel-box-header.noborder {
    border: 0px;
    /*background: $cp-white;*/ }
    .iso-panel-box-header.noborder.bold {
      font-size: 14px;
      font-weight: bold; }
  .iso-panel-box-header.bold {
    font-size: 12px;
    font-weight: bold; }
  .iso-panel-box-header.large {
    font-size: 16px; }
  .iso-panel-box-header.small {
    font-size: 11px; }
  .iso-panel-box-header.nocase {
    text-transform: none; }
  .iso-panel-box-header.uppercase {
    text-transform: uppercase; }
  .iso-panel-box-header.bluetext {
    color: #00358E; }
  .iso-panel-box-header.underline {
    border-bottom: 1px solid #a0a5a7;
    margin-bottom: 5px; }

/*.iso-panel-content {
    padding: 10px 8px;
    padding-bottom: 5px;
}*/
.iso-panel-content {
  background: #ffffff;
  border: 1px solid #d5d7d8;
  padding: 6px 8px;
  padding-bottom: 3px; }
  .iso-panel-content.light {
    background: #fafafa;
  }
  .iso-panel-content.noborder {
    border: 0px;
    /*padding: 0;*/ }
  .iso-panel-content.border-corp-active {
    border-color: #2A7DE1; }
  .iso-panel-content.transparent {
    background: transparent; }
  .iso-panel-content.pos-realtive {
    position: relative; }
  .iso-panel-content .caption {
    border-bottom: 5px solid #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin-bottom: 20px; }
    .iso-panel-content .caption label {
      font-size: 14px; }
  .iso-panel-content .caption01 {
    border-bottom: 1px solid #d5d7d8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    position: relative; }
    .iso-panel-content .caption01.spaced {
      justify-content: space-between; }
    .iso-panel-content .caption01:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      width: 20%;
      border-bottom: 1px solid #00358E; }
    .iso-panel-content .caption01.noborder {
      border-bottom: 0; }
      .iso-panel-content .caption01.noborder:before {
        border-bottom: 0; }
    .iso-panel-content .caption01 label {
      font-size: 14px;
      color: #00358E; }
      .iso-panel-content .caption01 label.white {
        color: #ffffff; }
      .iso-panel-content .caption01 label.lg {
        font-size: 150%; }
    .iso-panel-content .caption01 .close-button {
      position: absolute;
      top: 8px;
      right: 0;
      font-size: 16px;
      cursor: pointer; }
  .iso-panel-content .caption02 {
    border-bottom: 1px solid #aeb1b3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    margin-bottom: 35px;
    position: relative;
    margin-top: 30px; }
    .iso-panel-content .caption02 label {
      font-size: 14px;
      background: #ffffff;
      padding: 10px 20px;
      font-weight: normal;
      color: #00358E; }
    .iso-panel-content .caption02.hoverboxlabel label {
      background: #fff8e6; }
    .iso-panel-content .caption02 i.menu {
      position: absolute;
      top: -20px;
      left: 0;
      background: whitesmoke;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer; }
      .iso-panel-content .caption02 i.menu:hover {
        background: #ebebeb; }
    .iso-panel-content .caption02 i.options {
      position: absolute;
      top: -20px;
      right: 0;
      background: whitesmoke;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer; }
      .iso-panel-content .caption02 i.options:hover {
        background: #ebebeb; }
  .iso-panel-content .overlay-menu {
    min-width: 300px;
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    background-color: #3F3F3F;
    background-color: rgba(63, 63, 63, 0.9);
    padding: 10px;
    display: none; }
    .iso-panel-content .overlay-menu.right {
      right: 0; }
    .iso-panel-content .overlay-menu.white {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 2px 4px 8px 0 #868b8e; }
    .iso-panel-content .overlay-menu.auto-height {
      bottom: auto; }
    .iso-panel-content .overlay-menu .closebtn {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 20px;
      color: #EE3932;
      cursor: pointer; }
    .iso-panel-content .overlay-menu .overlay-menu-content {
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none; }
      .iso-panel-content .overlay-menu .overlay-menu-content::-webkit-scrollbar {
        display: none; }
  .iso-panel-content .actionpanel {
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    align-items: center; }
    .iso-panel-content .actionpanel i.views {
      cursor: pointer;
      padding: 5px; }
      .iso-panel-content .actionpanel i.views:hover {
        border-radius: 3px;
        background: #e6e6e6; }

.iso-panel-fluid {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff; }
  .iso-panel-fluid.col-6 .iso-panel-box {
    width: 16.666%; }
  .iso-panel-fluid.col-5 .iso-panel-box {
    width: 20%; }
  .iso-panel-fluid.col-4 .iso-panel-box {
    width: 25%; }
  .iso-panel-fluid.col-3 .iso-panel-box {
    width: 33.33%; }
  .iso-panel-fluid.col-2 .iso-panel-box {
    width: 50%; }
  .iso-panel-fluid.col-1 .iso-panel-box {
    width: 100%; }
  .iso-panel-fluid .iso-panel-content {
    padding: 15px; }

.iso-forbidden-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background: #00358E;
  width: 100%;
  height: 100%; }

.iso-forbidden-text {
  position: fixed;
  top: 20%;
  left: 40%;
  margin-left: -40px;
  margin-top: -40px;
  font-size: 20px; }

/*cards prototype1*/
.iso-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /*height: 175px;*/ }
  .iso-cards .card-content {
    flex: 0 0 20%;
    margin: 0 15px 15px 0;
    /*height: 100%;*/
    border: 1px solid #d5d7d8;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; }
    .iso-cards .card-content.curved {
      border-radius: 10px; }
    .iso-cards .card-content .card-title {
      font-size: 15px;
      text-transform: uppercase;
      padding: 10px;
      padding-bottom: 0; }
    .iso-cards .card-content .card-avatar {
      height: 100px;
      width: 100%;
      position: relative;
      background: rgba(42, 125, 225, 0.5);
      /*background: linear-gradient(90deg, $cp-corp-active-color 36%,lighten($cp-corp-active-color, 30%) 100%);*/
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .iso-cards .card-content .card-avatar.black {
        background: rgba(10, 11, 11, 0.5);
        /*background: linear-gradient(90deg, $cp-corp-third-color 36%,lighten($cp-corp-third-color, 30%) 100%);

                */ }
      .iso-cards .card-content .card-avatar.red {
        background: rgba(238, 57, 50, 0.8);
        /*background: linear-gradient(90deg, $cp-error-red 36%,lighten($cp-error-red, 30%) 100%);*/ }
      .iso-cards .card-content .card-avatar i {
        top: 50%;
        transform: translateY(-50%);
        font-size: 70px;
        position: absolute;
        left: 5px;
        color: rgba(0, 0, 0, 0.1); }
    .iso-cards .card-content p {
      padding: 10px;
      padding-bottom: 0; }
    .iso-cards .card-content .card-action {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      align-content: flex-end;
      flex: 1;
      padding-right: 10px;
      position: absolute;
      top: 20px; }
      .iso-cards .card-content .card-action ul {
        display: flex;
        margin-bottom: 0px;
        align-items: flex-end;
        justify-content: flex-end; }
        .iso-cards .card-content .card-action ul li {
          border: 1px solid white;
          height: 40px;
          border-radius: 50%;
          margin: 10px 3px;
          cursor: pointer;
          flex: 0 0 auto;
          background: rgba(255, 255, 255, 0.2);
          /*&:hover {
                        background: hsla(0, 0%, 100%, .2);
                    }*/ }
          .iso-cards .card-content .card-action ul li i {
            margin: 10px 9px;
            color: white; }
  .iso-cards .newcard {
    border: 2px dashed #2A7DE1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    cursor: pointer;
    border-radius: 10px;
    min-height: 150px; }
    .iso-cards .newcard:hover {
      background: #ffffff; }
    .iso-cards .newcard span {
      margin: 10px;
      color: #2A7DE1; }
    .iso-cards .newcard i {
      font-size: 32px;
      color: #2A7DE1; }

.iso-cards01 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between; }
  .iso-cards01 .card-content {
    flex: 0 0 30%;
    background: #ffffff;
    position: relative; }
    .iso-cards01 .card-content.selected:after {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-bottom-color: #F4AF2D;
      content: " ";
      position: absolute;
      bottom: 0;
      left: 45%;
      z-index: 0; }
    .iso-cards01 .card-content .card-title {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .iso-cards01 .card-content .card-title span {
        font-size: 18px;
        color: #ffffff;
        text-transform: uppercase; }
        .iso-cards01 .card-content .card-title span.round {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          margin-left: 6px;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center; }
      .iso-cards01 .card-content .card-title.black {
        /*background: rgba(darken($cp-corp-third-color,30%),0.5);*/
        background: radial-gradient(ellipse at bottom, #d5d7d8 0%, #a0a5a7 100%); }
      .iso-cards01 .card-content .card-title.blue {
        /*background: rgba($cp-corp-color,0.8);*/
        background: radial-gradient(ellipse at bottom, #08c2ed 0%, #0586a3 100%); }
    .iso-cards01 .card-content .card-action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 25px; }
      .iso-cards01 .card-content .card-action p {
        margin: 0;
        font-size: 14px; }
      .iso-cards01 .card-content .card-action i {
        color: rgba(0, 53, 142, 0.8);
        cursor: pointer; }

.iso-cards02 {
  height: 125px;
  background: #07bae4;
  position: relative;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column; }
  .iso-cards02.red {
    background: #fd6952; }
  .iso-cards02.green {
    background: #00da82; }
  .iso-cards02 i {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 80px;
    color: rgba(0, 0, 0, 0.1); }
  .iso-cards02 div {
    height: 50%;
    padding: 0 20px; }
    .iso-cards02 div ul li {
      width: 100%;
      font-size: 16px;
      color: #ffffff; }

.iso-cards03 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  .iso-cards03 .card {
    height: 320px;
    width: 250px;
    margin: 10px;
    box-shadow: 2px 4px 8px 0 #868b8e;
    background: #ffffff; }
    .iso-cards03 .card.noborder {
      border: 0; }
    .iso-cards03 .card .avatar {
      height: 160px;
      position: relative; }
      .iso-cards03 .card .avatar i {
        position: absolute;
        top: 55px;
        left: 50%;
        transform: translateX(-50%);
        background: #00358E;
        color: #ffffff;
        font-size: 34px;
        border-radius: 50%;
        padding: 25px; }
        .iso-cards03 .card .avatar i.green {
          background: #33AD82; }
        .iso-cards03 .card .avatar i.red {
          background: #EE3932; }
    .iso-cards03 .card .content {
      padding: 10px;
      text-align: center;
      font-size: 18px; }

.iso-cards-row {
  width: 100%;
  box-shadow: 1px 1px 1px 1px #d5d7d8;
  background: #ffffff; }
  .iso-cards-row ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; }
    .iso-cards-row ul li {
      width: 100%;
      min-height: 75px;
      border-right: 1px solid #d5d7d8;
      padding: 20px 15px;
      display: flex;
      flex-direction: column; }
      .iso-cards-row ul li:last-child {
        border-right: 0; }
      .iso-cards-row ul li label {
        border-bottom: 1px solid #d5d7d8;
        padding-bottom: 8px; }

.iso-cards-col {
  width: 100%;
  box-shadow: 1px 1px 1px 1px #d5d7d8;
  background: #ffffff; }
  .iso-cards-col ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
    .iso-cards-col ul li {
      width: 100%;
      min-height: 75px;
      border-bottom: 1px solid #d5d7d8;
      padding: 20px 15px;
      display: flex;
      flex-direction: column; }

.iso-review-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }
  .iso-review-cards .nodata {
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 100%;
    background: #ffffff; }
  .iso-review-cards.white {
    background: #ffffff;
    border-radius: 6px;
    padding-top: 10px; }
  .iso-review-cards .card-content {
    /*flex: 0 0 20%;*/
    margin: 0 15px 15px 0;
    /*height: 100%;*/
    /*border-bottom: 1px solid lighten($cp-corp-third-color,50%);*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 15px 10px 40px 10px;
    width: 100%;
    box-shadow: 0 1px 2px #d9d9d9, 0 1px 3px 1px #d9d9d9;
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden; }
    .iso-review-cards .card-content:hover {
      /*border-bottom-color: $cp-corp-active-color;*/
      box-shadow: 0 1px 2px #bfbfbf, 0 1px 3px 1px #bfbfbf;
      color: #3F3F3F; }
      .iso-review-cards .card-content:hover .content .edit {
        /*display: block;*/
        right: -50px; }
    .iso-review-cards .card-content .header {
      color: #2A7DE1;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 20px;
      font-size: 13px; }
      .iso-review-cards .card-content .header .item-center {
        align-items: center; }
      .iso-review-cards .card-content .header .item-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end; }
        .iso-review-cards .card-content .header .item-right.row {
          flex-direction: row;
          align-content: center;
          align-items: center; }
      .iso-review-cards .card-content .header .bold {
        font-weight: bold;
        font-size: 14px; }
      .iso-review-cards .card-content .header .edited {
        background: #fd725d;
        color: #ffffff;
        padding: 3px 8px;
        margin-left: 10px;
        border-radius: 16px;
        font-size: 85%; }
    .iso-review-cards .card-content .content {
      width: 100%;
      margin-top: 20px; }
      .iso-review-cards .card-content .content .edit {
        position: absolute;
        height: 110px;
        width: 110px;
        right: -150px;
        bottom: -40px;
        padding: 18px 25px;
        border-radius: 50%;
        background-color: rgba(0, 53, 142, 0.1);
        transition: all 0.3s ease-in-out;
        z-index: 999; }
        .iso-review-cards .card-content .content .edit i {
          font-size: 26px; }
      .iso-review-cards .card-content .content textarea {
        width: 100%;
        height: 5em; }
    .iso-review-cards .card-content.typeA {
      padding-left: 70px;
      position: relative;
      box-shadow: none;
      border-bottom: 0px dashed #d9d9d9;
      border-radius: 0;
      margin: 0;
      margin-bottom: 20px;
      padding-bottom: 10px; }
      .iso-review-cards .card-content.typeA:last-child {
        border-bottom: 0; }
      .iso-review-cards .card-content.typeA:hover {
        box-shadow: none; }
        .iso-review-cards .card-content.typeA:hover .action {
          /*display: block;*/
          right: 6px; }
      .iso-review-cards .card-content.typeA .noteicon {
        position: absolute;
        top: 22px;
        left: 6px;
        color: #047CB1;
        border: 1px solid rgba(0, 53, 142, 0.2);
        border-radius: 50%;
        padding: 8px; }
      .iso-review-cards .card-content.typeA .content-body {
        background: rgba(0, 53, 142, 0.06);
        width: 99%;
        padding: 0px;
        border-radius: 4px;
        position: relative; }
        .iso-review-cards .card-content.typeA .content-body .items {
          overflow: hidden;
          position: relative;
          padding: 10px 10px 20px 20px; }
        .iso-review-cards .card-content.typeA .content-body .header {
          color: #484b4d;
          margin-bottom: 3px; }
          .iso-review-cards .card-content.typeA .content-body .header span {
            margin-right: 10px; }
          .iso-review-cards .card-content.typeA .content-body .header .bold {
            font-weight: 500;
            font-size: 15px; }
        .iso-review-cards .card-content.typeA .content-body .k-editor-inline {
          background: rgba(0, 53, 142, 0);
          margin-right: 30px;
          color: #3F3F3F; }
        .iso-review-cards .card-content.typeA .content-body:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: 10px;
          right: 100%;
          left: -27px;
          margin-right: -3.5rem;
          border-right: 10px solid rgba(0, 53, 142, 0.06);
          border-bottom: 17px solid transparent;
          border-left: 17px solid transparent;
          border-top: 17px solid transparent; }
      .iso-review-cards .card-content.typeA .action {
        position: absolute;
        right: -100px;
        top: 6px;
        transition: all 0.3s ease-in-out;
        z-index: 999;
        display: flex;
        align-content: center;
        flex-direction: column; }
        .iso-review-cards .card-content.typeA .action i {
          font-size: 16px;
          border-radius: 50%;
          padding: 8px;
          cursor: pointer;
          margin-bottom: 7px; }
          .iso-review-cards .card-content.typeA .action i.edit {
            background-color: rgba(0, 53, 142, 0.25);
            color: rgba(0, 53, 142, 0.8); }
            .iso-review-cards .card-content.typeA .action i.edit:hover {
              color: #00358E; }
          .iso-review-cards .card-content.typeA .action i.delete {
            background-color: rgba(238, 57, 50, 0.2);
            color: rgba(238, 57, 50, 0.5); }
            .iso-review-cards .card-content.typeA .action i.delete:hover {
              color: #EE3932; }
    .iso-review-cards .card-content.typeB {
      padding-left: 70px;
      position: relative;
      box-shadow: none;
      border-bottom: 1px dashed #d9d9d9;
      padding-bottom: 15px;
      border-radius: 0;
      padding-top: 0; }
      .iso-review-cards .card-content.typeB:last-child {
        border-bottom: 0; }
      .iso-review-cards .card-content.typeB:hover {
        box-shadow: none; }
      .iso-review-cards .card-content.typeB .noteicon {
        position: absolute;
        top: 8px;
        left: 6px;
        color: #047CB1;
        border: 1px solid rgba(0, 53, 142, 0.2);
        border-radius: 50%;
        padding: 8px; }
      .iso-review-cards .card-content.typeB .header {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-end;
                align-items: flex-end;*/
        margin-bottom: 20px;
        color: #6d7274;
        margin-bottom: 18px;
        height: 35px; }
      .iso-review-cards .card-content.typeB .content {
        margin-top: 0;
        background: rgba(0, 53, 142, 0.06);
        padding: 10px;
        border-radius: 4px;
        position: relative;
        padding-bottom: 20px; }
        .iso-review-cards .card-content.typeB .content .k-editor-inline {
          background: rgba(0, 53, 142, 0);
          margin-right: 30px;
          color: #3F3F3F; }
        .iso-review-cards .card-content.typeB .content:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: 10px;
          right: 100%;
          left: -27px;
          margin-right: -3.5rem;
          border-right: 10px solid rgba(0, 53, 142, 0.06);
          border-bottom: 17px solid transparent;
          border-left: 17px solid transparent;
          border-top: 17px solid transparent; }
  .iso-review-cards .k-editable-area {
    border: 0; }
  .iso-review-cards .k-editor-inline {
    border: 0;
    max-height: 250px;
    padding: 0;
    margin-right: 50px; }
    .iso-review-cards .k-editor-inline ul {
      list-style-type: disc !important;
      padding: 0 20px !important; }
      .iso-review-cards .k-editor-inline ul li {
        list-style-type: disc; }
    .iso-review-cards .k-editor-inline ol {
      list-style-type: decimal !important;
      padding: 0 20px !important; }
      .iso-review-cards .k-editor-inline ol li {
        list-style-type: decimal; }
  .iso-review-cards table.k-editor {
    border: 0;
    max-height: 100px; }
    .iso-review-cards table.k-editor .k-content {
      padding: 0;
      max-height: 100px; }
  .iso-review-cards.notes .card-content {
    background: #fff1cc; }
  .iso-review-cards.notes .k-editor-inline {
    background: #fff1cc; }

.iso-col-widgets {
  padding: 5px;
  display: flex;
  flex-grow: 1;
  position: relative;
  width: 33.33%; }
  .iso-col-widgets > div {
    background-color: rgba(42, 125, 225, 0.6);
    padding: 30px 30px 10px 30px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden; }
    .iso-col-widgets > div.active {
      border: 0px solid #00358E;
      box-shadow: 0 4px 50px -10px #3F3F3F;
      background-color: rgba(0, 152, 99, 0.8); }
      .iso-col-widgets > div.active .header {
        color: #ffffff; }
        .iso-col-widgets > div.active .header p span.round {
          background-color: #ffffff;
          color: #33AD82; }
    .iso-col-widgets > div .header {
      position: relative;
      color: #ffffff; }
      .iso-col-widgets > div .header p {
        margin: 0;
        display: inline-block;
        font-weight: bold;
        width: 100%;
        margin-bottom: 15px; }
        .iso-col-widgets > div .header p.large {
          font-size: 140%; }
        .iso-col-widgets > div .header p span.round {
          margin-left: 5px;
          background-color: #ffffff;
          color: #2A7DE1;
          border-radius: 50%;
          padding: 3px 9px; }
      .iso-col-widgets > div .header:before {
        content: "";
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        right: -70px;
        bottom: -50px;
        width: 100px;
        height: 100px; }
      .iso-col-widgets > div .header:after {
        content: "";
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        right: -65px;
        bottom: 25px;
        width: 55px;
        height: 55px; }
    .iso-col-widgets > div .content {
      display: none;
      opacity: 0;
      position: absolute;
      z-index: 9999;
      min-width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 4px 8px 0px #6d7274;
      transform: scale3d(0.9, 0.9, 1);
      -webkit-transition: opacity 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
      transform-origin: left top; }
      .iso-col-widgets > div .content:hover {
        -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transform: scale3d(1, 1, 1); }
      .iso-col-widgets > div .content > div {
        padding: 15px; }
        .iso-col-widgets > div .content > div .iso-widget01 {
          padding: 0; }
          .iso-col-widgets > div .content > div .iso-widget01 .widget-content {
            padding-top: 5px; }
    .iso-col-widgets > div:hover .content {
      display: block;
      opacity: 1; }

.iso-action-buttons-as-link {
  display: flex;
  justify-content: center; }
  .iso-action-buttons-as-link.row {
    flex-direction: row; }
  .iso-action-buttons-as-link button {
    border: 1px solid #2A7DE1;
    padding: 15px 0;
    background: #ffffff !important;
    border-radius: 6px; }

table {
  background: #ffffff; }
  table caption {
    padding: 0;
    padding-left: 10px; }
    table caption button span:before {
      margin-right: 8px; }

.table-hover tbody tr:hover {
  background: #f3fbff; }

.table-hover tbody td a:hover {
  color: #00358E; }

.iso-table-col-trash > span {
  color: #EE3932;
  font-size: 14px;
  padding: 0; }

.iso-grid {
  /*background: lighten($cp-corp-color,65%);*/
  background: #ffffff;
  /*height:$grid-height;*/ }
  .iso-grid table {
    border-collapse: collapse; }
    .iso-grid table thead th:first-child,
    .iso-grid table thead th + th[style*="none"] {
      border-left: 0px; }
    .iso-grid table thead th:last-child,
    .iso-grid table thead th[style*="none"] + th {
      border-right: 0px; }
    .iso-grid table tbody tr.selected {
      background: #c0e8ff; }
    .iso-grid table tbody tr:last-child td {
      border-bottom: 0; }
    .iso-grid table tbody td {
      vertical-align: middle;
      /*padding: $grid-table-tbody-td-padding;*/
      color: #6d7274; }
    .iso-grid table tbody td:first-child,
    .iso-grid table tbody td[style*="none"] + td {
      border-left: 0px; }
    .iso-grid table tbody td:last-child,
    .iso-grid table tbody td[style*="none"] + td {
      border-right: 0px; }
  .iso-grid .k-grid-header-wrap {
    border: 0; }
  .iso-grid .k-grid-header {
    padding: 8px 0;
    background: #fafafa; }
    .iso-grid .k-grid-header th.k-header {
      font-weight: bold;
      color: #6d7274;
      padding-top: 2px;
      padding-bottom: 2px;
      background: #fafafa;
      padding-left: 7px;
      vertical-align: middle; }
    .iso-grid .k-grid-header.nodata {
      background: #ffffff; }
  .iso-grid .k-grid-content table tbody td {
    border: 0;
    border-bottom: 1px solid #ebebeb; }
  .iso-grid .k-header > .k-link {
    color: #6d7274; }
  .iso-grid .k-edit-cell input[type=checkbox] {
    float: left;
    margin: 0.6em auto;
    width: 100%; }
    .iso-grid .k-edit-cell input[type=checkbox]:focus {
      outline: 0; }
  .iso-grid .k-pager-wrap {
    background: #ffffff;
    min-height: auto; }
  .iso-grid .iso-grid-row-height-35 {
    height: 25px; }
  .iso-grid .iso-grid-row-height-45 {
    height: 45px; }
  .iso-grid .iso-grid-row-height-50 {
    height: 50px; }
  .iso-grid .iso-grid-row-height-60 {
    height: 60px; }
  .iso-grid .iso-grid-row-height-70 {
    height: 70px; }

.iso-grid-no-data {
  color: #EE3932;
  /*background: lighten($cp-corp-color,65%);*/
  /*background:  darken($cp-white,2%);*/
  /*background:lighten($cp-error-red, 40%);*/
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 110%; }

.iso-grid-error-message {
  list-style-type: none;
  margin-bottom: 0; }
  .iso-grid-error-message > li {
    display: inline-block;
    padding: 5px;
    width: 100%; }

      .iso-grid-cell-selected {
  background-color: #d8fbd2 !important;
  color: #000000  !important; }
  .iso-medical-medcode-selected td {
    color: #000000 !important; }
  .iso-medical-medcode-selected a {
    color: #000000 !important; }
    .iso-medical-medcode-selected a:hover {
      color: #000000 !important; }
    .iso-medical-medcode-selected a:focus {
      color: #000000 !important; }
  .iso-medical-medcode-selected i {
    color: #000000 !important; }

.iso-editable-grid-cell {
  background: #fff8e6;
  /*border: 0.15em solid lighten($cp-mango, 5%) !important;*/
  color: #2A7DE1; }

.iso-mandatory-grid-cell {
  background: #ffe0db;
  border: 0.15em solid #EE3932 !important;
  color: #2A7DE1; }

.iso-editable-grid-container-error {
  text-align: left;
  margin-top: 10px; }
  .iso-editable-grid-container-error.float {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 998; }

.iso-grid-row-error-triangle {
  border-left: 15px solid #EE3932;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  content: " "; }

.iso-grid-row-warning-triangle {
  border-left: 15px solid #F4AF2D;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  content: " "; }

.iso-grid-header-button {
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  padding: 5px 10px; }

.iso-grid-detail-row {
  background: #ffffff; }
  .iso-grid-detail-row:hover {
    background: #ffffff !important; }
  .iso-grid-detail-row .iso-grid-detail-asmodal {
    width: 100%;
    padding: 0px;
    margin: 5px 0;
    position: relative;
    background: #ffffff; }
    .iso-grid-detail-row .iso-grid-detail-asmodal .modal {
      position: relative;
      box-shadow: 0px 4px 3px -3px #3F3F3F;
      border: 1px solid #3F3F3F;
      border-radius: 8px;
      background: #ffffff;
      z-index: 998 !important; }
    .iso-grid-detail-row .iso-grid-detail-asmodal .modal-backdrop {
      /*position: static;*/
      background: #ffffff;
      opacity: 0.5;
      z-index: 988 !important; }
    .iso-grid-detail-row .iso-grid-detail-asmodal .modal-dialog {
      background: #ffffff;
      height: 95%;
      margin: 0;
      width: 100%; }
    .iso-grid-detail-row .iso-grid-detail-asmodal .modal-content {
      box-shadow: none;
      border: 0; }

.iso-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background: #00358E;
  width: 100%;
  height: 100%;
  z-index: 9999;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.iso-page-refresh-spinner {
  /*background:$cp-white;*/
  background: #f7f7f7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99; }
  .iso-page-refresh-spinner.modal {
    display: flex;
    justify-content: center;
    align-items: center; }
  .iso-page-refresh-spinner.white {
    background: #ffffff; }

.iso-page-refresh-spinner-icon {
  width: 60px;
  height: 60px;
  margin: 12% auto;
  background-color: #00358E;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }
  .iso-page-refresh-spinner-icon.white {
    background-color: #ffffff;
    animation: sk-scaleout-plain 1.0s infinite ease-in-out; }
  .iso-page-refresh-spinner-icon.red {
    background-color: #EE3932;
    animation: sk-scaleout-plain 1.0s infinite ease-in-out; }
  .iso-page-refresh-spinner-icon.green {
    background-color: #33AD82;
    animation: sk-scaleout-plain 1.0s infinite ease-in-out; }
  .iso-page-refresh-spinner-icon.mango {
    background-color: #F4AF2D;
    animation: sk-scaleout-plain 1.0s infinite ease-in-out; }
  .iso-page-refresh-spinner-icon.small {
    margin: 0;
    width: 30px;
    height: 30px; }
  .iso-page-refresh-spinner-icon.large {
    margin: 0;
    width: 80px;
    height: 80px; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  25% {
    background-color: #EE3932; }
  50% {
    background-color: #33AD82; }
  75% {
    background-color: #F4AF2D; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  25% {
    background-color: #EE3932; }
  50% {
    background-color: #33AD82; }
  75% {
    background-color: #F4AF2D; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

@-webkit-keyframes sk-scaleout-plain {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout-plain {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.iso-wireframe {
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 998;
  margin: 1px; }

@keyframes shine {
  to {
    background-position: 0 0, 100% 0, 0 0, 0 40px, 0 80px, 0 120px; } }

.iso-spinner {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998; }
  .iso-spinner .widget {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }

.iso-spinner-animation {
  /*position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-45%,-45%);*/
  border-bottom: 3px solid #e6e6e6;
  border-left: 3px solid #e6e6e6;
  border-right: 3px solid #2A7DE1;
  border-top: 3px solid #2A7DE1;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -ms-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear; }
  .iso-spinner-animation.small {
    height: 25px;
    width: 25px; }
  .iso-spinner-animation.medium {
    height: 50px;
    width: 50px; }
  .iso-spinner-animation.large {
    height: 80px;
    width: 80px; }
  .iso-spinner-animation.top-align {
    margin: 12% auto; }

.iso-loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border-bottom: 3px solid #f2f2f2;
  border-left: 3px solid #f2f2f2;
  border-right: 3px solid #30d3f8;
  border-top: 3px solid #30d3f8;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  -webkit-animation: spin .9s infinite linear;
  -moz-animation: spin .9s infinite linear;
  -ms-animation: spin .9s infinite linear;
  -o-animation: spin .9s infinite linear;
  animation: spin .9s infinite linear;
  overflow: hidden; }

.iso-loading-animation.small {
  margin: 20% auto;
  border-bottom: 3px solid #f2f2f2;
  border-left: 3px solid #f2f2f2;
  border-right: 3px solid #30d3f8;
  border-top: 3px solid #30d3f8;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  -webkit-animation: spin .6s infinite linear;
  -moz-animation: spin .6s infinite linear;
  -ms-animation: spin .6s infinite linear;
  -o-animation: spin .6s infinite linear;
  animation: spin .6s infinite linear; }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.iso-loading-progress {
  background-color: #f2f2f2;
  height: 4px;
  margin: 1em;
  overflow: hidden;
  position: relative;
  width: 12em;
  margin: 20% auto; }
  .iso-loading-progress.top {
    height: 2px;
    margin: 0 auto; }
  .iso-loading-progress.wide {
    width: 100%; }

.iso-loading-progress-center {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

.iso-loading-progress-bar {
  animation: side2side 2s ease-in-out infinite;
  background-color: #2A7DE1;
  height: 100%;
  position: absolute;
  width: 50%; }

@keyframes side2side {
  0%, 100% {
    transform: translateX(-50%); }
  50% {
    transform: translateX(150%); } }

@media only screen and (max-width: 768px) {
  .iso-page-refresh > i {
    padding: 5px; } }

@media only screen and (max-width: 1024px) {
  .iso-page-refresh > i {
    padding: 5px; } }

.iso-tabs {
  /*background: darken($cp-white,2%);*/
  /*background: $cp-white;*/ }
  .iso-tabs .tabs {
    list-style: none;
    margin: 0 0 0 0;
    text-align: left;
    /*border-bottom: 5px solid lighten($cp-corp-third-color,50%);*/
    padding: 10px 0; }
    .iso-tabs .tabs > li {
      display: inline;
      padding: 12px 21px;
      border-radius: 2px 2px 0 0;
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
      color: #ffffff;
      width: 100%;
      text-transform: uppercase; }
      .iso-tabs .tabs > li > a {
        color: rgba(0, 53, 142, 0.6);
        text-decoration: none; }
        .iso-tabs .tabs > li > a:active {
          text-decoration: none; }
        .iso-tabs .tabs > li > a:focus {
          text-decoration: none; }
      .iso-tabs .tabs > li:hover {
        /*border-bottom: 5px solid $cp-corp-color;*/
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out; }
        .iso-tabs .tabs > li:hover > a {
          color: #00358E; }
      .iso-tabs .tabs > li.active {
        padding-top: 17px;
        color: #ffffff;
        border-bottom: 3px solid #00358E;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out; }
        .iso-tabs .tabs > li.active > a {
          color: #00358E; }
  .iso-tabs .tab-content {
    width: 100%;
    padding: 15px;
    /*padding-top: $tab-content-padding-top;*/
    background: #ffffff;
    margin-top: 3px; }
  .iso-tabs.secondary {
    padding: 10px; }
    .iso-tabs.secondary .tabs {
      border-bottom: 0; }
      .iso-tabs.secondary .tabs li {
        background: rgba(7, 174, 213, 0.5);
        color: #ffffff;
        margin-right: 10px;
        position: relative; }
        .iso-tabs.secondary .tabs li a {
          color: #ffffff; }
        .iso-tabs.secondary .tabs li:hover {
          border-bottom: 0;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          background: #07aed5; }
          .iso-tabs.secondary .tabs li:hover a {
            color: #ffffff; }
        .iso-tabs.secondary .tabs li.active {
          background: #07aed5;
          padding-top: 12px;
          color: #ffffff;
          border-bottom: 0;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out; }
          .iso-tabs.secondary .tabs li.active a {
            color: #ffffff; }
          .iso-tabs.secondary .tabs li.active:after {
            top: 100%;
            /*right:0;*/
            left: 50%;
            transform: translateX(-50%);
            border: solid transparent;
            content: " ";
            height: 12px;
            width: 12px;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: #07aed5;
            border-width: 12px;
            margin-bottom: -12px;
            transition: all 0.5s ease-in-out; }
    .iso-tabs.secondary.transparent {
      background: transparent; }
      .iso-tabs.secondary.transparent .tab-content {
        background: transparent; }
    .iso-tabs.secondary .tab-content {
      margin-top: 25px;
      border: 1px solid #e6e6e6;
      box-shadow: 0 4px 20px -8px #d9d9d9; }
      .iso-tabs.secondary .tab-content.noborder {
        border: 0; }

.iso-tabs .iso-list-tabs {
  display: flex;
  height: 400px; }
  .iso-tabs .iso-list-tabs .items {
    width: 40%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    position: relative;
    z-index: 1; }
    .iso-tabs .iso-list-tabs .items::-webkit-scrollbar {
      display: none; }
    .iso-tabs .iso-list-tabs .items ul {
      margin-bottom: 0;
      margin-right: 30px; }
      .iso-tabs .iso-list-tabs .items ul li {
        width: 100%;
        min-height: 75px;
        padding: 5px 20px;
        border: 1px solid #ebebeb;
        border-left: 5px solid #e6a700;
        border-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /*margin: 15px 0;*/
        background: #fafafa;
        cursor: pointer;
        position: relative; }
        .iso-tabs .iso-list-tabs .items ul li:first-child {
          margin-top: 0; }
        .iso-tabs .iso-list-tabs .items ul li.greenleftborder {
          border-left-color: #33AD82; }
        .iso-tabs .iso-list-tabs .items ul li.redleftborder {
          border-left-color: #EE3932; }
        .iso-tabs .iso-list-tabs .items ul li.selected {
          width: 100%;
          background: linear-gradient(to right, #f7f7f7, #e8e8e8);
          /*border-color:darken($cp-white,9%);*/
          /*border: 0;*/
          /*box-shadow: 0 5px 6px -1px darken($cp-white,25%);*/
          /*font-size: ($default-font-size + 2);*/
          color: #047CB1;
          /*border-left-color: $cp-corp-active-color;*/ }
          .iso-tabs .iso-list-tabs .items ul li.selected:after {
            left: 99%;
            /*right:0;*/
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 30px;
            width: 30px;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-left-color: #e8e8e8;
            border-width: 15px;
            margin-top: -15px; }
        .iso-tabs .iso-list-tabs .items ul li .headercolumn {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: flex-start; }
          .iso-tabs .iso-list-tabs .items ul li .headercolumn p {
            color: #047CB1;
            padding-left: 15px; }
            .iso-tabs .iso-list-tabs .items ul li .headercolumn p:first-child {
              line-height: 0px;
              padding-top: 8px; }
            .iso-tabs .iso-list-tabs .items ul li .headercolumn p:last-child {
              font-size: 11px;
              line-height: 24px; }
        .iso-tabs .iso-list-tabs .items ul li p {
          margin: 5px 0; }
          .iso-tabs .iso-list-tabs .items ul li p.header {
            color: #047CB1;
            font-weight: 600;
            text-transform: uppercase; }
            .iso-tabs .iso-list-tabs .items ul li p.header.green {
              color: #33AD82; }
            .iso-tabs .iso-list-tabs .items ul li p.header.red {
              color: #EE3932; }
          .iso-tabs .iso-list-tabs .items ul li p.headericon {
            background: #e6a700;
            color: #ffffff;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 24px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            border-radius: 3px; }
            .iso-tabs .iso-list-tabs .items ul li p.headericon.green {
              background: #33AD82; }
            .iso-tabs .iso-list-tabs .items ul li p.headericon.red {
              background: #EE3932; }
          .iso-tabs .iso-list-tabs .items ul li p.headertext {
            color: #047CB1;
            font-size: 14px; }
            .iso-tabs .iso-list-tabs .items ul li p.headertext.green {
              color: #33AD82; }
            .iso-tabs .iso-list-tabs .items ul li p.headertext.red {
              color: #EE3932; }
        .iso-tabs .iso-list-tabs .items ul li i.delete-item {
          position: absolute;
          right: 10px;
          top: 6px;
          font-size: 20px;
          color: #EE3932; }
          .iso-tabs .iso-list-tabs .items ul li i.delete-item.disabled {
            color: #6d7274;
            cursor: default; }
        .iso-tabs .iso-list-tabs .items ul li i.view-item {
          position: absolute;
          right: 35px;
          top: 9px;
          font-size: 18px;
          color: #047CB1; }
  .iso-tabs .iso-list-tabs .content {
    background: #ffffff;
    width: 100%;
    padding: 15px;
    position: relative;
    margin-left: -30px;
    /*box-shadow: 5px 6px 6px -3px darken($cp-white,25%);*/
    border-top: 5px solid #e6a700; }
    .iso-tabs .iso-list-tabs .content.fixed {
      position: fixed; }
    .iso-tabs .iso-list-tabs .content.greentopborder {
      border-top-color: #33AD82; }
    .iso-tabs .iso-list-tabs .content.redtopborder {
      border-top-color: #EE3932; }

.iso-tab-chip-container .iso-tabs {
  /*display: none;*/
  margin-top: 10px;
  padding: 0 10px;
  position: relative;
  border: 1px solid #ebebeb; }
  .iso-tab-chip-container .iso-tabs i {
    position: absolute;
    top: 0;
    right: -2px;
    padding: 5px;
    cursor: pointer; }
    .iso-tab-chip-container .iso-tabs i:hover {
      background: #e6e6e6;
      border-radius: 3px; }
  .iso-tab-chip-container .iso-tabs ul {
    border: 0; }
    .iso-tab-chip-container .iso-tabs ul li {
      display: none; }

.iso-tabs-chip .tabs {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 25%;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-top: 15px; }
  .iso-tabs-chip .tabs li {
    padding: 10px;
    /*border:1px solid darken($cp-white,5%);*/
    box-shadow: 0 1px 2px #d9d9d9, 0 1px 3px 1px #d9d9d9;
    border-radius: 6px;
    background: #ffffff;
    margin: 1px 1px 4px 15px;
    height: 65px;
    width: 245px;
    padding: 13px 13px 13px 80px;
    position: relative;
    cursor: pointer; }
    .iso-tabs-chip .tabs li:hover {
      box-shadow: 0 1px 2px #bfbfbf, 0 1px 3px 1px #bfbfbf; }
    .iso-tabs-chip .tabs li.active {
      box-shadow: 0 1px 2px #bfbfbf, 0 1px 3px 1px #bfbfbf;
      background: #00358E;
      color: #ffffff; }
      .iso-tabs-chip .tabs li.active:after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid transparent;
        border-top-color: #00358E;
        content: " ";
        position: absolute;
        bottom: -8px;
        left: 45%;
        z-index: 0; }
      .iso-tabs-chip .tabs li.active a {
        color: #ffffff; }
    .iso-tabs-chip .tabs li:first-child {
      margin-left: 0px; }
    .iso-tabs-chip .tabs li div {
      font-size: 100%;
      font-weight: 600;
      margin-bottom: 8px; }
    .iso-tabs-chip .tabs li i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      padding: 12px;
      border-radius: 50%;
      font-size: 200%;
      background: whitesmoke; }

.iso-tabs-chip .tab-content {
  width: 100%;
  padding: 10px;
  /* padding-top: 32px; */
  background: #ffffff;
  position: relative;
  box-shadow: 0 1px 2px #bfbfbf, 0 1px 3px 1px #bfbfbf;
  border-radius: 6px; }
  .iso-tabs-chip .tab-content .closetab {
    position: absolute;
    top: 0;
    right: -2px;
    padding: 5px;
    cursor: pointer; }
    .iso-tabs-chip .tab-content .closetab:hover {
      background: #e6e6e6;
      border-radius: 3px; }

.accordion {
  background: #4baee4;
  color: #ffffff;
  border: 1px solid #4baee4;
  padding: 15px 20px;
  position: relative;
  /*height: $accordion-height;*/
  cursor: pointer;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px; }
  .accordion.active {
    background: #4baee4;
    border: 1px solid #4baee4; }
  .accordion i {
    margin-right: 15px;
    font-size: 24px; }
    .accordion i.end {
      margin-left: auto;
      margin-right: 0; }
  .accordion.plain {
    background: #ffffff;
    color: #6d7274;
    border: 0;
    border-bottom: 1px dashed #d5d7d8; }
    .accordion.plain i {
      color: #6d7274; }
    .accordion.plain ~ .accordion-content {
      border: 0;
      background: #fafafa;
      border-bottom: 1px solid #d5d7d8; }
  .accordion.white {
    background: #ffffff;
    color: #6d7274;
    border: 0; }
    .accordion.white i {
      color: #6d7274; }
    .accordion.white ~ .accordion-content {
      border: 0;
      border-top: 1px solid #00358E; }
  .accordion.green {
    background: #33AD82;
    color: #ffffff;
    border: 1px solid #33AD82; }
    .accordion.green i {
      color: #ffffff; }
    .accordion.green ~ .accordion-content {
      border: 1px solid #33AD82; }
  .accordion.red {
    background: #fd6048;
    color: #ffffff;
    border: 1px solid #fd6048; }
    .accordion.red i {
      color: #ffffff; }
    .accordion.red ~ .accordion-content {
      border: 1px solid #fd6048; }
  .accordion.mango {
    background: #F4AF2D;
    color: #ffffff;
    border: 1px solid #F4AF2D; }
    .accordion.mango i {
      color: #ffffff; }
    .accordion.mango ~ .accordion-content {
      border: 1px solid #F4AF2D; }

.accordion-content {
  margin: auto;
  padding: 15px 10px;
  /*padding-bottom: 5px;*/
  border-top: none;
  position: relative;
  width: 100%;
  border: 1px solid #4baee4;
  border-top: 0;
  background: #ffffff; }

.iso-tooltip {
  position: relative; }
  .iso-tooltip .iso-tooltiptext {
    visibility: hidden;
    min-width: 250px;
    max-width: 500px;
    min-height: 25px;
    background-color: #3b3e40;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 999;
    font-size: 12px;
    margin: 8px;
    vertical-align: middle; }
    .iso-tooltip .iso-tooltiptext.iso-hoverboxtext {
      max-width: 650px;
      background-color: #fff8e6;
      color: #6d7274;
      border: 1px solid #d5d7d8; }
    .iso-tooltip .iso-tooltiptext.top {
      bottom: 100%;
      left: 50%; }
      .iso-tooltip .iso-tooltiptext.top::after {
        content: " ";
        position: absolute;
        top: 100%;
        /* At the bottom of the tooltip */
        left: calc(50% - 10px);
        /*margin-left: -5px;*/
        border-width: 5px;
        border-style: solid;
        border-color: #3b3e40 transparent transparent transparent; }
    .iso-tooltip .iso-tooltiptext.right {
      top: -8px;
      left: 105%; }
      .iso-tooltip .iso-tooltiptext.right::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%;
        /* To the left of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #3b3e40 transparent transparent; }
    .iso-tooltip .iso-tooltiptext.bottom {
      top: 105%;
      left: 50%; }
      .iso-tooltip .iso-tooltiptext.bottom::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        /* At the top of the tooltip */
        left: calc(50% - 10px);
        /*margin-left: -5px;*/
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #3b3e40 transparent; }
    .iso-tooltip .iso-tooltiptext.left {
      top: -355px;
      right: 105%; }
      .iso-tooltip .iso-tooltiptext.left::after {
        content: " ";
        position: absolute;
        top: 75%;
        left: 100%;
        /* To the right of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #3b3e40; }
    .iso-tooltip .iso-tooltiptext.predictive {
      top: -155px;
      right: 105%; }
      .iso-tooltip .iso-tooltiptext.predictive::after {
        content: " ";
        position: absolute;
        top: 75%;
        left: 100%;
        /* To the right of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #3b3e40; }
  .iso-tooltip:hover .iso-tooltiptext {
    visibility: visible; }

.tooltip {
  z-index: 9999999; }

.tooltip-inner {
  background: #3b3e40;
  min-width: 100px;
  max-width: 100%; }

.tooltip.top .tooltip-arrow {
  border-top-color: #3b3e40; }

.tooltip.right .tooltip-arrow {
  border-right-color: #3b3e40; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #3b3e40; }

.tooltip.left .tooltip-arrow {
  border-left-color: #3b3e40; }

.iso-info-tooltip {
  max-width: 50%; }
  .iso-info-tooltip .tooltip-inner {
    border: 1px solid #a6dfff;
    background: #d9f1ff;
    color: #00358E;
    padding: 10px 15px;
    text-align: justify; }
  .iso-info-tooltip .tooltip-arrow {
    display: none; }

.iso-popover.error {
  border-color: #EE3932;
  background: #fecac2; }
  .iso-popover.error.right .arrow {
    border-right-color: #EE3932; }
    .iso-popover.error.right .arrow:after {
      border-right-color: #fecac2; }
  .iso-popover.error .popover-content {
    color: #e82102; }

.iso-popover.warning {
  border-color: #F4AF2D;
  background: #ffe399; }
  .iso-popover.warning.right .arrow {
    border-right-color: #F4AF2D; }
    .iso-popover.warning.right .arrow:after {
      border-right-color: #ffe399; }
  .iso-popover.warning .popover-content {
    color: #664a00; }

.iso-moveable-tooltip {
  border: 1px solid #996f00;
  background: #ffe399;
  color: #3b3e40;
  /*box-shadow: 0 2px 3px darken($cp-white,15%);*/
  position: absolute;
  padding: 7px 10px;
  text-align: left;
  /*border-radius: 4px;*/
  max-width: 350px;
  overflow: auto; }

.iso-main-view {
  transition: margin-left .5s; }

.iso-content-viewport {
  margin: 0;
  width: 100%;
  min-height: 100%;
  padding: 0;
  position: relative;
  padding-bottom: 60px;
  margin-top: 10px;
  /*height:79vh;
    overflow-y:scroll;*/ }
  .iso-content-viewport .container {
    /*background: $cp-white;*/
    padding-top: 2px;
    padding-bottom: 60px; }

.iso-system-error {
  color: #EE3932;
  font-size: 16px;
  text-align: center;
  padding-top: 100px; }

.iso-inner-content-viewport {
  padding-top: 49px; }

.iso-info-arrow-box {
  position: relative;
  background: #F4AF2D;
  margin-right: 10px;
  padding: 5px 15px;
  color: #ffffff; }
  .iso-info-arrow-box.rounded {
    border-radius: 4px; }
  .iso-info-arrow-box.right:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #ffffff;
    border-left-color: #F4AF2D;
    border-width: 7px;
    margin-top: -7px; }
  .iso-info-arrow-box.left:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #ffffff;
    border-right-color: #F4AF2D;
    border-width: 7px;
    margin-top: -7px; }

.iso-arrow-box {
  /*position: relative;
    border: 0;
    background: $cp-error-red;
    padding: 10px;
    color: $cp-white;

    &:after {
        bottom: 100%;
        left: 0;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $cp-error-red;
        border-width: 12px;
    }*/
  /*&:before {
        font-family: "Material Icons";
        content: '\E000';
        font-size:1.6em;
        position:absolute;
        top:-2px;
        left:0
    }*/
  position: relative;
  top: -1px;
  color: #EE3932;
  border-top: 2px solid #EE3932;
  padding: 4px 0;
  font-size: 98%;
  font-style: italic; }

.center-block {
  /*display: block;
    margin-left: auto;
    margin-right: auto;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .center-block.vertical {
    vertical-align: middle; }
  .center-block.transparent {
    background: transparent; }

.iso-pull-right {
  display: flex;
  justify-content: flex-end; }

.iso-common-viewport {
  background: #00358E;
  color: #ffffff; }

.iso-blocks {
  margin: 5px 10px; }
  .iso-blocks div {
    border: 1px solid #4baee4;
    text-align: center;
    background: #ffffff;
    cursor: pointer;
    padding: 10px;
    color: #6d7274;
    min-height: 80px;
    display: table;
    margin: 5px 10px 30px 0;
    box-shadow: 0 4px 8px 0 #4baee4;
    transition: 0.3s; }
    .iso-blocks div:hover {
      background: #2A7DE1;
      color: #ffffff;
      box-shadow: 0 8px 16px 0 #92e8fc;
      border: 1px solid #2A7DE1; }
      .iso-blocks div:hover i {
        color: #2A7DE1;
        border: 1px solid #2A7DE1; }
    .iso-blocks div i {
      position: absolute;
      background: #ffffff;
      padding: 7px 0;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      top: -20px;
      left: calc(50% - 20px);
      border: 1px solid #4baee4;
      color: #6d7274; }
    .iso-blocks div p {
      font-size: 14px; }
    .iso-blocks div h4 {
      display: table-cell;
      vertical-align: middle; }

.iso-chip {
  display: inline-block;
  padding: 0 25px;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #fafafa;
  width: 100%;
  color: #6d7274; }
  .iso-chip i {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50%;
    background: #ffffff;
    border: 3px solid #fafafa;
    color: #6d7274; }

.iso-linear-guage {
  width: 100%;
  position: relative;
  height: 35px; }
  .iso-linear-guage .chart {
    height: 15px;
    position: absolute;
    bottom: 3px;
    left: 25px;
    right: 25px;
    border: 1px solid #0d3c56;
    border-top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    align-items: flex-end; }
  .iso-linear-guage .scale {
    position: relative;
    height: 15px;
    width: 10%;
    border: 0;
    align-items: baseline; }
  .iso-linear-guage .midscale {
    border: 1px solid #0d3c56;
    border-top: 0;
    border-left: 0;
    border-bottom: 0; }
  .iso-linear-guage .min {
    position: absolute;
    left: -15px;
    top: -20px; }
  .iso-linear-guage .max {
    position: absolute;
    right: -15px;
    top: -20px; }
  .iso-linear-guage .mid {
    position: absolute;
    top: -20px;
    right: -15px; }
  .iso-linear-guage .range {
    background: #33AD82; }
  .iso-linear-guage .rangeleft {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #33AD82 50%, transparent 50%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, #33AD82 50%, transparent 50%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, #33AD82 50%, transparent 50%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, #33AD82 50%, transparent 50%);
    /* Standard syntax */ }
  .iso-linear-guage .rangeleft25 {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #33AD82 25%, transparent 25%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, #33AD82 25%, transparent 25%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, #33AD82 25%, transparent 25%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, #33AD82 25%, transparent 25%);
    /* Standard syntax */ }
  .iso-linear-guage .rangeleft75 {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #33AD82 75%, transparent 75%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, #33AD82 75%, transparent 75%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, #33AD82 75%, transparent 725%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, #33AD82 75%, transparent 75%);
    /* Standard syntax */ }
  .iso-linear-guage .rangeright {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, transparent 50%, #33AD82 50%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, transparent 50%, #33AD82 50%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, transparent 50%, #33AD82 50%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, transparent 50%, #33AD82 50%);
    /* Standard syntax */ }
  .iso-linear-guage .rangeright25 {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, transparent 25%, #33AD82 25%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, transparent 25%, #33AD82 25%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, transparent 25%, #33AD82 25%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, transparent 25%, #33AD82 25%);
    /* Standard syntax */ }
  .iso-linear-guage .rangeright75 {
    background: #33AD82;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, transparent 75%, #33AD82 75%);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, transparent 75%, #33AD82 75%);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, transparent 75%, #33AD82 75%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, transparent 75%, #33AD82 75%);
    /* Standard syntax */ }
  .iso-linear-guage .pointer {
    position: absolute;
    border: 0;
    padding: 10px;
    color: #ffffff;
    left: -11px;
    top: 20px; }
    .iso-linear-guage .pointer:after {
      bottom: 100%;
      left: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: #33AD82;
      border-width: 10px; }

.iso-bg.white {
  background: #ffffff; }

.iso-bg.vblue {
  background: #00358E; }

.iso-bg.activeblue {
  background: #2A7DE1; }

.iso-bg.vgray {
  background: #047CB1; }

.iso-bg.error-red {
  background: #EE3932; }

.iso-bg.good-green {
  background: #33AD82; }

.iso-bg.mango {
  background: #F4AF2D; }

.iso-bg.light-slate {
  background: #999999; }

.iso-hide-controls.ng-hide {
  transition: all 0.5s linear; }

.iso-panel-content-fluid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden; }
  .iso-panel-content-fluid div {
    margin: 0 8px 0 0; }

.horiz-scroller-wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden; }
  .horiz-scroller-wrapper .scroller {
    width: 40px;
    text-align: center;
    cursor: pointer;
    display: none;
    padding: 5px;
    margin-top: 5px; }
    .horiz-scroller-wrapper .scroller.scroller-right {
      position: absolute;
      right: 0;
      border-radius: 50%;
      background: #77c2eb;
      opacity: 0.7;
      z-index: 997;
      padding: 3px;
      top: 50%;
      transform: translateY(-50%);
      height: 35px;
      width: 35px; }
      .horiz-scroller-wrapper .scroller.scroller-right:hover {
        opacity: 0.8; }
      .horiz-scroller-wrapper .scroller.scroller-right i {
        padding: 5px 0;
        color: black; }
    .horiz-scroller-wrapper .scroller.scroller-left {
      position: absolute;
      left: 0;
      border-radius: 50%;
      background: #77c2eb;
      opacity: 0.7;
      z-index: 997;
      padding: 3px;
      top: 50%;
      transform: translateY(-50%);
      height: 35px;
      width: 35px; }
      .horiz-scroller-wrapper .scroller.scroller-left:hover {
        opacity: 0.8; }
      .horiz-scroller-wrapper .scroller.scroller-left i {
        padding: 4px 0;
        color: black; }

.iso-widget01 {
  background: #ffffff;
  padding: 15px;
  margin: 3px 0;
  min-width: 300px; }
  .iso-widget01 .widget-header {
    position: relative;
    border-bottom: 1px solid #d9d9d9; }
    .iso-widget01 .widget-header h3 {
      color: #00358E;
      text-transform: uppercase; }
    .iso-widget01 .widget-header h5, .iso-widget01 .widget-header h4 {
      color: #00358E; }
    .iso-widget01 .widget-header span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
      color: #2A7DE1; }
      .iso-widget01 .widget-header span.medium {
        font-size: 18px; }
      .iso-widget01 .widget-header span.ribbon {
        padding: 5px;
        right: -27px;
        background: #2A7DE1;
        color: #ffffff; }
    .iso-widget01 .widget-header .close-button {
      position: absolute;
      top: -30px;
      right: -20px;
      font-size: 26px;
      cursor: pointer;
      color: #d9d9d9; }
      .iso-widget01 .widget-header .close-button:hover {
        color: #3F3F3F; }
  .iso-widget01 .widget-content {
    padding: 15px 0; }
    .iso-widget01 .widget-content ul li {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2; }
      .iso-widget01 .widget-content ul li.column {
        flex-direction: column; }
        .iso-widget01 .widget-content ul li.column p {
          display: flex;
          justify-content: space-between; }
        .iso-widget01 .widget-content ul li.column span {
          display: flex;
          justify-content: space-between; }
      .iso-widget01 .widget-content ul li.cursor {
        cursor: pointer; }
      .iso-widget01 .widget-content ul li.hover:hover {
        border-bottom: 1px solid #00358E; }
      .iso-widget01 .widget-content ul li:last-child {
        border-bottom: 0; }
    .iso-widget01 .widget-content.condensed ul li {
      padding: 2px 0;
      border-bottom: 1px solid #e6e6e6; }
    .iso-widget01 .widget-content.width-25 ul {
      width: 25%; }
    .iso-widget01 .widget-content.width-50 ul {
      width: 50%; }
    .iso-widget01 .widget-content.width-75 ul {
      width: 75%; }
    .iso-widget01 .widget-content.left-align ul li {
      display: flex;
      justify-content: flex-start; }
      .iso-widget01 .widget-content.left-align ul li span:first-child {
        width: 25%; }
      .iso-widget01 .widget-content.left-align ul li span:last-child {
        width: 75%;
        word-wrap: break-word; }
    .iso-widget01 .widget-content.big ul li {
      font-size: 14px;
      text-transform: uppercase; }
  .iso-widget01.transparent {
    background: transparent; }

.row.bottom-shadow {
  box-shadow: 0px 19px 11px -11px #e6e6e6; }

.row.sameheight {
  display: flex;
  flex-wrap: wrap; }
  .row.sameheight [class*='col-'] {
    display: flex;
    flex-direction: column; }

.iso-sticky {
  position: fixed;
  min-width: 300px;
  background: #2A7DE1;
  border-radius: 4px;
  padding: 15px; }
  .iso-sticky p {
    color: #ffffff;
    margin: 0; }
  .iso-sticky.top-center {
    top: 175px;
    left: 50%;
    transform: translateX(-50%); }

.iso-page-header-section {
  height: 185px;
  padding-bottom: 20px;
  padding-top: 15px;
  padding-right: 0;
  border-left: 0;
  background: linear-gradient(141deg, #004a73 10%, #009cf3 50%, #004a73 85%);
  color: #ffffff; }
  .iso-page-header-section div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .iso-page-header-section div.left {
      justify-content: flex-start; }
    .iso-page-header-section div .caption {
      font-size: 22px; }

.iso-page-content-section {
  margin-top: -185px; }

.iso-check-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .iso-check-selector div {
    border: 1px solid #00358E;
    padding: 8px 10px;
    margin: 5px;
    color: #00358E;
    position: relative;
    cursor: pointer; }
    .iso-check-selector div i {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(-50%, -50%);
      font-size: 12px; }
    .iso-check-selector div.active {
      background: rgba(0, 78, 170, 0.7);
      border-color: rgba(0, 78, 170, 0.7);
      color: #ffffff;
      padding: 8px 25px; }
      .iso-check-selector div.active i {
        opacity: 1;
        background: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        padding: 3px;
        border-radius: 50%; }

.iso-selected-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px; }
  .iso-selected-filters label {
    margin-bottom: 0;
    margin-right: 10px; }
  .iso-selected-filters a {
    padding: 5px 20px;
    position: relative;
    background: #ebebeb;
    border-radius: 20px; }
    .iso-selected-filters a span {
      padding: 0 5px;
      line-height: 10px; }
    .iso-selected-filters a i {
      font-size: 120%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      opacity: 0; }
    .iso-selected-filters a:hover {
      background: #fd725d;
      color: #fd725d; }
      .iso-selected-filters a:hover i {
        opacity: 1; }

.iso-filter-chip-content {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 36px;
  flex-wrap: wrap;
  width: 100%; }

.iso-content-space-around {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .iso-content-space-around .menu {
    cursor: pointer; }

.iso-chart-container {
  position: relative;
  min-height: 400px;
  margin: 15px 10px 10px 10px;
  padding: 20px;
  box-shadow: 0 4px 25px -18px #3F3F3F;
  border: 1px solid #e6e6e6;
  border-radius: 6px; }
  .iso-chart-container.loading {
    background: #ffffff;
    opacity: 0.2;
    /*filter: blur(2px);
        -webkit-filter: blur(2px);
        height: 100%;*/ }
  .iso-chart-container .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2;
    filter: alpha(opacity=60);
    background: #c8cacc;
    text-align: center;
    border-radius: 6px; }
    .iso-chart-container .overlay div {
      position: relative;
      font-size: 200%;
      margin-top: -17px;
      top: 50%;
      color: #3F3F3F; }

.iso-preview-container {
  background: #6d7274;
  border: 1px solid #6d7274;
  padding: 0; }
  .iso-preview-container .iso-preview {
    position: relative; }
    .iso-preview-container .iso-preview .preview-actions {
      position: absolute;
      top: 0;
      width: 98%;
      left: 0;
      right: 0;
      height: 40px;
      background: #3b3e40;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center; }
      .iso-preview-container .iso-preview .preview-actions div {
        width: 95%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        color: #ffffff; }
        .iso-preview-container .iso-preview .preview-actions div a {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: flex-end; }
          .iso-preview-container .iso-preview .preview-actions div a span {
            display: none;
            opacity: 0;
            color: #ffffff;
            margin-right: 5px;
            transition: 0.3s;
            padding: 5px 15px;
            background: #00358E;
            border-radius: 6px; }
          .iso-preview-container .iso-preview .preview-actions div a i {
            color: #ffffff; }
          .iso-preview-container .iso-preview .preview-actions div a:hover span {
            display: block;
            opacity: 1; }
  .iso-preview-container .preview-content {
    display: flex;
    justify-content: center;
    overflow-y: scroll; }
    .iso-preview-container .preview-content .content {
      width: 85%;
      margin-top: 80px;
      /*margin-bottom: 20px;*/
      background: #ffffff;
      /*height:100%;*/ }
      .iso-preview-container .preview-content .content textarea {
        width: 100%;
        height: 5em; }
      .iso-preview-container .preview-content .content .nodata {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .2;
        filter: alpha(opacity=60);
        background: #ffffff;
        text-align: center; }
        .iso-preview-container .preview-content .content .nodata div {
          position: relative;
          font-size: 200%;
          margin-top: -17px;
          top: 50%;
          color: #3F3F3F; }
  .iso-preview-container .k-editable-area {
    border: 0; }
  .iso-preview-container .k-editor-inline {
    border: 0;
    /*max-height: 250px;*/
    padding: 50px;
    /*margin-top: 20px;
        margin-bottom: 20px;*/
    box-shadow: 10px 0 5px -2px #3F3F3F; }
    .iso-preview-container .k-editor-inline ul {
      list-style-type: disc !important;
      padding: 0 20px !important; }
      .iso-preview-container .k-editor-inline ul li {
        list-style-type: disc; }
    .iso-preview-container .k-editor-inline ol {
      list-style-type: decimal !important;
      padding: 0 20px !important; }
      .iso-preview-container .k-editor-inline ol li {
        list-style-type: decimal; }
  .iso-preview-container table.k-editor {
    border: 0;
    /*max-height: 100px;*/ }
    .iso-preview-container table.k-editor .k-content {
      padding: 0;
      /*max-height: 100px;*/ }

.iso-editor-textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0; }

table.expandEditor {
  border-width: 0;
  width: 100%;
  height: 100%; }

.iso-full-row-bg {
  background: #f8fcff;
  padding: 0; }
  .iso-full-row-bg.bordered {
    border: 1px solid #e3f5ff;
    border-left: 0;
    border-right: 0; }

@media only screen and (max-width: 480px) {
  .iso-blocks div {
    width: 100%; } }

@media only screen and (max-width: 768px) {
  .iso-content-viewport {
    padding: 5px;
    margin-left: -15px; }
    .iso-content-viewport .container {
      width: 100%; }
  /*.iso-inner-content-viewport {
        padding-top: 80px;
    }*/
  .iso-blocks div {
    width: 100%; } }

@media only screen and (max-width: 1024px) {
  .iso-content-viewport {
    padding-left: 10px; }
  .iso-blocks div {
    width: 100%; } }

.k-widget {
  font-family: "Roboto"; }

.k-widget.k-tooltip-validation {
  display: none !important; }

.k-dropdown-wrap .k-select, .k-numeric-wrap .k-select, .k-picker-wrap .k-select {
  line-height: 2.0em; }

.k-dropdown-wrap.k-state-default {
  height: 25px; }

.k-state-hover.k-dropdown-wrap {
  border: 1px solid #008cd9; }

.k-state-hover.k-numeric-wrap {
  border: 1px solid #008cd9; }
  .k-state-hover.k-numeric-wrap .k-select {
    border-left: 1px solid #008cd9; }

.k-state-hover.k-picker-wrap {
  border: 1px solid #008cd9; }
  .k-state-hover.k-picker-wrap .k-select {
    border-left: 1px solid #008cd9; }

.k-state-focused.k-dropdown-wrap {
  border: 1px solid #008cd9; }

.k-state-focused.k-numeric-wrap {
  border: 1px solid #008cd9; }
  .k-state-focused.k-numeric-wrap .k-select {
    border-left: 1px solid #008cd9; }

.k-state-focused.k-picker-wrap {
  border: 1px solid #008cd9; }
  .k-state-focused.k-picker-wrap .k-select {
    border-left: 1px solid #008cd9; }

.k-textbox > input, span.k-autocomplete .k-input, span.k-dropdown-wrap .k-input, span.k-numeric-wrap .k-input, span.k-picker-wrap .k-input, span.k-selectbox .k-input {
  height: 27px;
  padding: 0.24em 0;
  color: #6d7274; }

.k-numerictextbox .k-link {
  height: 1.10em;
  line-height: 1.22em; }

.k-animation-container, .k-animation-container *, .k-animation-container ::after, .k-block .k-header, .k-list-container, .k-widget, .k-widget *, .k-widget ::before {
  box-sizing: border-box; }

.k-list-container {
  color: #6d7274; }

.k-autocomplete .k-input, .k-dropdown-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input, .k-selectbox .k-input, .k-textbox > input {
  height: 25px;
  line-height: 20px; }

/*Kendo grid*/
.k-grid-toolbar {
  background: #ffffff;
  padding: 8px; }
  .k-grid-toolbar .k-button {
    background: #ffffff;
    color: #2A7DE1;
    border: 1px solid #2A7DE1;
    text-transform: uppercase;
    padding: 8px 20px;
    border-radius: 20px; }
    .k-grid-toolbar .k-button:focus {
      color: #6d7274; }
    .k-grid-toolbar .k-button:hover {
      background: #f3fbff; }

.k-grid .k-dirty {
  display: none; }

.no-scrollbar .k-grid-header {
  /*padding: 0 !important;*/ }

.no-scrollbar .k-grid-content {
  overflow-y: visible; }

.k-grid td.k-state-focused {
  box-shadow: inset 0 0 0 0; }

.k-grid .k-grid-header .k-header .k-link {
  height: auto; }

.k-grid .k-grid-header .k-header {
  white-space: normal; }

.k-grid .k-grid-content {
  max-height: 180px; }

/*Kendo multiselect*/
/*--COASI-24430*/
.k-multiselect-wrap {
  min-height: 25px; }

.k-animation-container .k-state-border-up ul.k-list > li.k-state-focused {
  background-color: #f3fbff !important; }

.k-animation-container .k-state-border-up ul.k-list > li.k-state-selected {
  background-color: #d0eaf8; }

/*multiselect place holder*/
.k-multiselect-wrap > .k-input:not(:focus) {
  color: #d5d7d8; }

/*Kendo tooltip*/
.k-widget.k-tooltip {
  background: #135b83 !important;
  color: #ffffff;
  min-width: 120px;
  border-radius: 4px;
  padding: 5px; }

.k-tooltip-content {
  text-align:left;
}

/*Kendo file upload
*/
.k-upload {
  background: #ffffff;
  border: 0;
  width: 100%;
  height: 100%; }

.k-dropzone {
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center; }

.k-dropzone em {
  visibility: visible;
  font-style: normal;
  font-size: 14px;
  color: #6d7274;
  /*padding-bottom: 15px;*/
  text-align: center;
  position: relative; }
  .k-dropzone em:first-letter {
    text-transform: capitalize; }
  .k-dropzone em i {
    position: absolute;
    top: -3px;
    left: -25px; }

.k-dropzone .k-upload-button {
  background: #ffffff;
  color: #00358E;
  padding: 0 5px;
  cursor: pointer;
  min-width: 0px;
  font-size: 14px;
  border: 0; }

.k-upload-selected {
  display: none; }

.k-upload-files {
  margin: 0; }

/*Editor*/
.k-editor.k-header {
  background: #ffffff;
  border-color: #d9d9d9; }

.k-editor .k-content {
  padding: 0px; }

.k-editor .k-resize-handle {
  display: none; }

.k-editor *[contenteditable='false'] {
  color: #a0a5a7;
  cursor: default; }

/*Treeview*/
.k-treeview .k-in {
  padding: 5px; }

/*Range Slider*/
.k-slider-horizontal .k-draghandle {
  width: 20px;
  height: 20px;
  top: -9px;
  border-radius: 50%;
  background: #00358E;
  border-color: #00358E; }

.k-slider-section {
  background: #00358E; }

.k-widget.k-tooltip.k-slider-tooltip {
  background: #008cd9 !important;
  color: #ffffff; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.iso-material-icons.default {
  color: #00358E; }

.iso-material-icons.green {
  color: #33AD82; }

.iso-material-icons.orange {
  color: #F4AF2D; }

.iso-material-icons.white {
  color: #ffffff; }

.iso-material-icons.red {
  color: #EE3932; }

.iso-material-icons.grey {
  color: #d0eaf8; }

.iso-material-icons.md-34 {
  font-size: 32px; }

.iso-material-icons.md-32 {
  font-size: 30px; }

.iso-material-icons.md-30 {
  font-size: 28px; }

.iso-material-icons.md-28 {
  font-size: 26px; }

.iso-material-icons.md-26 {
  font-size: 24px; }

.iso-material-icons.md-24 {
  font-size: 22px; }

.iso-material-icons.md-22 {
  font-size: 20px; }

.iso-material-icons.md-20 {
  font-size: 18px; }

.iso-material-icons.md-18 {
  font-size: 16px; }

.iso-material-icons.md-16 {
  font-size: 14px; }

.iso-material-icons.md-14 {
  font-size: 12px; }

.iso-material-icons.md-12 {
  font-size: 10px; }

.iso-content-header {
  width: 100%;
  z-index: 1002;
  padding: 0 15px;
  background: #00358E;
  position: fixed; }
  .iso-content-header .header {
    width: 100%;
    margin: 0;
    background: #00358E;
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center; }
    .iso-content-header .header .iso-app-menu {
      display: none;
      padding-top: 8px;
      cursor: pointer;
      width: 50px; }
    .iso-content-header .header .iso-logo {
      padding: 0; }
    .iso-content-header .header .iso-brand {
      /*color: $cp-white;
            font-size: ($default-font-size + 8);
            padding: 0 8px;
            border-left: 0px solid $cp-white;*/
      margin: 0;
      padding: 0 0;
      margin-left: 30px; }
      /* .iso-content-header .header .iso-brand img {
        height: 34px; } */
    .iso-content-header .header .iso-header-boxes {
      /* margin-right: 10px; */
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center; }
      .iso-content-header .header .iso-header-boxes .iso-header-search-box {
        background: rgba(255, 255, 255, 0.15);
        width: 400px;
        position: relative;
        border-radius: 2px; }
        .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text] {
          background: transparent;
          border: 0;
          outline: 0;
          /*font-size: ($default-font-size - 1);*/
          height: 32px;
          padding: 0 10px;
          width: 100%;
          color: #ffffff;
          border-radius: 2px;
          -webkit-transition: -webkit-box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear;
          transition: box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear; }
          .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text]:focus {
            background: #ffffff;
            color: #6d7274; }
          .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text]:focus + a i {
            color: #00358E; }
          .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text]::-webkit-input-placeholder {
            /*color: lighten($default-font-color,20%);*/
            color: #ffffff; }
          .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text]:-moz-placeholder {
            /*color: lighten($default-font-color,20%);*/
            color: #ffffff; }
          .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text]:-ms-input-placeholder {
            /*color: lighten($default-font-color,20%);*/
            color: #ffffff; }
        .iso-content-header .header .iso-header-boxes .iso-header-search-box span {
          color: #00358E;
          padding-right: 8px; }
        .iso-content-header .header .iso-header-boxes .iso-header-search-box i {
          position: absolute;
          right: 0;
          top: 0;
          padding-top: 6px;
          padding-right: 6px;
          z-index: 1000000;
          /*color:$cp-corp-color;*/
          color: #ffffff;
          font-size: 22px; }
      .iso-content-header .header .iso-header-boxes .iso-header-user {
        padding: 0 20px;
        color: #ffffff; }
      .iso-content-header .header .iso-header-boxes .iso-header-links {
        padding-left: 5px;
        padding-right: 5px; }
        .iso-content-header .header .iso-header-boxes .iso-header-links > a {
          color: #ffffff;
          text-decoration: none; }
          .iso-content-header .header .iso-header-boxes .iso-header-links > a:hover {
            color: #ffffff;
            text-decoration: none; }
        .iso-content-header .header .iso-header-boxes .iso-header-links i {
          display: none; }
        .iso-content-header .header .iso-header-boxes .iso-header-links .iso-header-logout {
          background: #00517d;
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.4); }
          .iso-content-header .header .iso-header-boxes .iso-header-links .iso-header-logout:hover {
            background: #006197; }

.iso-caseinfos-row {
  /*background: $cp-white;*/
  position: relative; }
  .iso-caseinfos-row .iso-case-info-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 100%; }
    .iso-caseinfos-row .iso-case-info-row .iso-caseinfos {
      flex-grow: 2; }
      .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul {
        list-style: none;
        padding: 10px 0 0 0;
        position: relative;
        margin-bottom: 0; }
        .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li {
          display: inline;
          padding: 3px 10px;
          font-weight: bold; }
          .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li > span {
            font-weight: normal;
            padding: 0 10px; }
            .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li > span:after {
              margin-left: 10px; }
          .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li.more {
            border: 0px solid #00358E;
            border-radius: 10px;
            color: #00358E;
            position: relative;
            padding-right: 28px; }
            .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li.more label {
              font-weight: normal;
              cursor: pointer; }
            .iso-caseinfos-row .iso-case-info-row .iso-caseinfos > ul > li.more i {
              position: absolute;
              right: 3px;
              top: 0;
              font-size: 20px; }
    .iso-caseinfos-row .iso-case-info-row .notification {
      position: relative; }
      .iso-caseinfos-row .iso-case-info-row .notification .pulse {
        position: absolute;
        height: 35px;
        width: 35px;
        left: -6px;
        top: -4px;
        opacity: 0;
        border: 1px solid #EE3932;
        border-radius: 40px;
        /*-webkit-animation: pulsate 3s ease-out;
                -webkit-animation-iteration-count: infinite;
                animation: pulsate 3s ease-out;
                animation-iteration-count: infinite;*/ }
      .iso-caseinfos-row .iso-case-info-row .notification:hover .notification-list {
        display: block;
        opacity: 1; }
      .iso-caseinfos-row .iso-case-info-row .notification i {
        /*color: $cp-error-red;*/
        cursor: pointer; }
      .iso-caseinfos-row .iso-case-info-row .notification .badge {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #EE3932;
        border-radius: 50%;
        padding: 3px;
        min-width: 5px;
        font-size: 0;
        -webkit-animation: badge-blink 3s linear infinite;
        animation: badge-blink 3s linear infinite;
        -webkit-animation-fill-mode: initial;
        animation-fill-mode: initial;
        color: #ffffff; }
      .iso-caseinfos-row .iso-case-info-row .notification .notification-list {
        position: absolute;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 4px 8px 0px #6d7274;
        transform: scale3d(0.9, 0.9, 1);
        -webkit-transition: opacity 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
        transform-origin: right top;
        display: none;
        opacity: 0;
        top: -6px;
        right: -10px;
        min-width: 300px;
        z-index: 9999;
        overflow: hidden; }
        .iso-caseinfos-row .iso-case-info-row .notification .notification-list:hover {
          -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transform: scale3d(1, 1, 1); }
        .iso-caseinfos-row .iso-case-info-row .notification .notification-list .header {
          background-color: #00358E;
          height: 70px;
          padding: 15px;
          color: #ffffff;
          border-radius: 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .header span {
            background-color: #2A7DE1;
            padding: 5px 10px;
            border-radius: 4px;
            margin-left: 8px; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .header:before {
            content: "";
            position: absolute;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 50%;
            right: -45px;
            bottom: 5px;
            width: 100px;
            height: 100px; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .header:after {
            content: "";
            position: absolute;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 50%;
            right: 35px;
            bottom: 38px;
            width: 50px;
            height: 50px; }
        .iso-caseinfos-row .iso-case-info-row .notification .notification-list .items li {
          padding: 10px 20px;
          border-bottom: 1px solid #d5d7d8;
          height: 60px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: space-between; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .items li:hover {
            background: #fafafa;
            cursor: pointer; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .items li:last-child {
            border-bottom: 0; }
          .iso-caseinfos-row .iso-case-info-row .notification .notification-list .items li .item-badge {
            background: rgba(238, 57, 50, 0.8);
            border-radius: 50%;
            padding: 4px 9px;
            color: #ffffff; }

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0.0; } }

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0.0; } }

@-webkit-keyframes badge-blink {
  0%, 49.9% {
    opacity: 0.0; }
  100%, 50%, 60%, 70%, 80%, 90% {
    opacity: 1.0; }
  55%, 65%, 75%, 85%, 95% {
    opacity: 0; } }

@keyframes badge-blink {
  0%, 49.9% {
    opacity: 0.0; }
  100%, 50%, 60%, 70%, 80%, 90% {
    opacity: 1.0; }
  55%, 65%, 75%, 85%, 95% {
    opacity: 0; } }

.iso-caseinfos-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  min-height: 100px;
  z-index: 997;
  border-bottom-width: 2px;
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: opacity 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 250ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
  transform-origin: top top; }
  .iso-caseinfos-menu:hover {
    -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: scale3d(1, 1, 1); }
  .iso-caseinfos-menu .container {
    box-shadow: 0 1px 2px #d9d9d9, 0 1px 3px 1px #d9d9d9;
    border-radius: 6px;
    background: #606567; }
  .iso-caseinfos-menu .header {
    background: #F4AF2D;
    padding: 30px 15px 30px 80px;
    display: flex !important;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-start;
    color: #ffffff; }
    .iso-caseinfos-menu .header span {
      font-size: 110%;
      margin-top: 20px;
      width: 90%; }
  .iso-caseinfos-menu .details {
    padding: 15px 15px;
    flex: 1 0 25%;
    color: rgba(255, 255, 255, 0.8); }
    .iso-caseinfos-menu .details div {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-basis: 20%; }
    .iso-caseinfos-menu .details .iso-claimnumber {
      flex-basis: auto !important;
    }
      .iso-caseinfos-menu .details div div {
        display: flex !important;
        flex-direction: column !important;
        padding: 10px 20px; }
        .iso-caseinfos-menu .details div div span {
          font-size: 115%; }
          .iso-caseinfos-menu .details div div span:first-child {
            font-weight: bold;
            padding-bottom: 2px; }
          .iso-caseinfos-menu .details div div span:last-child {
            color: #30d3f8; }
        .iso-caseinfos-menu .details div div:hover {
          background: rgba(255, 255, 255, 0.15); }
          .iso-caseinfos-menu .details div div:hover span:last-child {
            color: #ffffff; }

.iso-caseinfos-dropdown {
  /*background: $cp-white;*/
  width: 100%;
  padding: 15px 0;
  border-bottom: 0;
  background: #ffffff; }
  .iso-caseinfos-dropdown ul {
    list-style: none;
    padding: 10px 0 0 0;
    position: relative; }
    .iso-caseinfos-dropdown ul > li {
      display: inline;
      padding: 20px 10px;
      font-weight: bold; }
      .iso-caseinfos-dropdown ul > li > span {
        font-weight: normal;
        padding: 0 10px; }
        .iso-caseinfos-dropdown ul > li > span:after {
          margin-left: 10px; }
      .iso-caseinfos-dropdown ul > li:nth-child(1) {
        display: none; }
      .iso-caseinfos-dropdown ul > li:nth-child(2) {
        display: none; }
    .iso-caseinfos-dropdown ul:before {
      top: -16px;
      /*right:0;*/
      left: 15%;
      transform: translateX(-50%);
      border: solid transparent;
      content: " ";
      height: 12px;
      width: 12px;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-top-color: #f7f7f7;
      border-width: 12px;
      margin-bottom: -12px;
      transition: all 0.5s ease-in-out; }

.iso-quick-toolbar-icons div.icons {
  width: 36px;
  height: 36px;
  border: 1px solid #00358E;
  opacity: 0.7;
  border-radius: 50%;
  float: left;
  margin: 5px;
  /*box-shadow: 0 4px 8px 0 lighten($cp-corp-color,5%);*/ }
  .iso-quick-toolbar-icons div.icons > i {
    padding: 5px;
    width: 100%;
    height: 100%;
    color: #00358E; }
  .iso-quick-toolbar-icons div.icons:hover {
    opacity: 1;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 #a6dfff; }

.iso-quick-toolbar-icons div.active {
  border: 1px solid #F4AF2D;
  /*box-shadow: 0 4px 8px 0 lighten($cp-mango,5%);*/ }
  .iso-quick-toolbar-icons div.active > i {
    color: #F4AF2D; }
  .iso-quick-toolbar-icons div.active:hover {
    box-shadow: 0 4px 8px 0 white; }

.iso-rotator-container {
  height: 30px;
  width: 98%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  background: #ebebeb;
  border-radius: 16px; }
  .iso-rotator-container .content {
    position: absolute;
    height: 30px;
    transition: 0.5s ease-in-out top;
    width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    height: 30px; }
    .iso-rotator-container .content.ng-enter {
      top: -30px; }
    .iso-rotator-container .content.ng-enter-active {
      top: 0; }
    .iso-rotator-container .content.ng-leave {
      top: 0; }
    .iso-rotator-container .content.ng-leave-active {
      top: 30px; }
    .iso-rotator-container .content div {
      margin: 5px 3px;
      padding: 5px; }
      .iso-rotator-container .content div span {
        padding: 0 5px; }
        .iso-rotator-container .content div span:first-child {
          font-weight: bold; }

@media only screen and (max-width: 480px) {
  .iso-content-header {
    padding: 0 5px; }
    .iso-content-header .header .iso-logo {
      display: none; }
    .iso-content-header .header .iso-brand {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-user {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-links span {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-links i {
      /* display: block;
      line-height: 50px;
      padding-left: 15px; */
      display: inline;
      vertical-align: middle; }
  .iso-caseinfos > ul li {
    display: none; }
  .iso-caseinfos-dropdown {
    display: block; }
    .iso-caseinfos-dropdown ul li {
      display: block;
      border-bottom: 1px solid #77c2eb; }
      .iso-caseinfos-dropdown ul li:last-child {
        border-bottom: none; }
      .iso-caseinfos-dropdown ul li:nth-child(1) {
        display: block; }
      .iso-caseinfos-dropdown ul li:nth-child(2) {
        display: block; } }

@media only screen and (max-width: 768px) {
  .iso-content-header {
    padding: 0 5px; }
    .iso-content-header .header .iso-brand {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-search-box {
      width: initial; /* override 400px width for smaller screens */
      flex-grow: 1; }
      .iso-content-header .header .iso-header-boxes .iso-header-search-box input[type=text] {
        width: 100%; }
    .iso-content-header .header .iso-header-boxes .iso-header-user {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-links span {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-links i {
      /* display: block;
      line-height: 50px;
      padding-left: 15px; */
      display: inline;
      vertical-align: middle; }
  .iso-caseinfos > ul li {
    display: none; }
  .iso-caseinfos-dropdown {
    display: block; }
    .iso-caseinfos-dropdown ul li {
      display: block;
      border-bottom: 1px solid #77c2eb; }
      .iso-caseinfos-dropdown ul li:last-child {
        border-bottom: none; }
      .iso-caseinfos-dropdown ul li:nth-child(1) {
        display: block; }
      .iso-caseinfos-dropdown ul li:nth-child(2) {
        display: block; } }

@media only screen and (max-width: 1024px) {
  .iso-content-header {
    display: table; }
    .iso-content-header .header .iso-app-menu {
      display: block; }
    .iso-content-header .header .iso-logo {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-user {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-user-icon {
      display: block; }
    .iso-content-header .header .iso-header-boxes .iso-header-links span {
      display: none; }
    .iso-content-header .header .iso-header-boxes .iso-header-links i {
      /* display: block;
      line-height: 50px;
      padding-left: 15px;  */
      display: inline;
      vertical-align: middle; }
  .iso-caseinfos-dropdown ul > li {
    display: block;
    border-bottom: 1px solid #77c2eb;
    padding: 10px; }
    .iso-caseinfos-dropdown ul > li:last-child {
      border-bottom: none; } }

.iso-copyright-bottom {
  color: #ffffff;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  background: #00358E;
  line-height: 50px;
  position: absolute;
  bottom: 0;
  height: 70px;
  width: 100%; }
  .iso-copyright-bottom .iso-logo {
    position: absolute;
    left: -20px;
    top: -5px; }

.iso-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  /* Height of the footer */
  background: #ffffff; }
  .iso-footer .contactus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }

@media only screen and (max-width: 768px) {
  .iso-copyright-bottom .iso-logo {
    top: -25px; } }

body.login-body .iso-main-view {
  height: 100%; }

.iso-login-viewport {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  background-repeat: no-repeat;
  background-position: 100%;
}

.iso-login-panel {
  margin-top: 12%;
  position: relative;
  height: 100%;
  width: 100%;
 }

.iso-login-footer{
  background: rgba(0,0,0,0.3);
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-family: Roboto Light;
  font-size: larger;
  text-align: center;
  line-height: 27px;
  height: 45px;
  position: fixed;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1002;
}

.iso-login-footer-divide1{
  /* padding-left: 5%;
  padding-right: 10px; */
  padding: 0px 10px 0px 10px;
}

/* .iso-login-footer-divide2{
  margin-left: 10px !important;
} */

.iso-login-footer .iso-brand-trademark {
  padding-left: 2px;
  font-size: 10px;
  /* vertical-align: super; */
  position: absolute;
  top: -2px;
}

.iso-login-footer-home {
  background: #00358E;
  backdrop-filter: none;
  line-height: 35px;
  height: 36px;
  padding-top: 0px;
}
.iso-login-footer-home .row {
  margin: 0px;
}
.iso-login-footer-home .left {
  text-align: left;
}
.iso-login-footer-home .right {
  text-align: right;
}

/* display backdrop/overlay of dialog from footer over footer itself, and top bar and left menu */
.cdk-overlay-container {
  z-index: 1003;
}

.iso-bottom-margin {
  margin-bottom: 36px; /* height of the footer */
}

.iso-dialog-panel .mat-dialog-container {
  padding: 0px;
}

.iso-dialog-panel .iso-dialog-content {
  padding: 0 24px 24px 24px;
}

.iso-dialog-panel .iso-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px 10px 0px 10px;
  background: #00358E;
  color: #FFFFFF;
}

.iso-login-header {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1px);
  text-align: center;
  font-size: 24pt;
  color: rgb(42,125,225);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto Light;
}

.iso-login-header .iso-brand {
  margin: 5px 0;
  padding: 10px 0;
}

.iso-login-header .iso-brand img {
  height: 34px;
}

.iso-login-box {
  padding: 25px 20px 25px 20px;
  box-shadow: 0 4px 15px -10px black;
  border-radius: 6px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
  .iso-login-box input[type=text], .iso-login-box input[type=email], .iso-login-box input[type=password] {
    border: 0;
    border-bottom: 1px solid #00358E;
    background-color: #005482;
    color: #ffffff;
    border-radius: 3px;
    font-size: 14px;
    height: 30px;
    padding: 5px 10px;
    box-shadow: none;
    -webkit-transition: -webkit-box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear;
    transition: box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear; }
    .iso-login-box input[type=text]:focus, .iso-login-box input[type=email]:focus, .iso-login-box input[type=password]:focus {
      outline: 0;
      background: #005787;
      box-shadow: none;
      border-bottom: 1px solid #0085cf; }
    .iso-login-box input[type=text]::-ms-clear, .iso-login-box input[type=email]::-ms-clear, .iso-login-box input[type=password]::-ms-clear {
      display: none; }
    .iso-login-box input[type=text]::-webkit-input-placeholder, .iso-login-box input[type=email]::-webkit-input-placeholder, .iso-login-box input[type=password]::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.15);
      font-size: 12px; }
    .iso-login-box input[type=text]:-moz-placeholder, .iso-login-box input[type=email]:-moz-placeholder, .iso-login-box input[type=password]:-moz-placeholder {
      color: rgba(255, 255, 255, 0.15);
      font-size: 12px; }
    .iso-login-box input[type=text]:-ms-input-placeholder, .iso-login-box input[type=email]:-ms-input-placeholder, .iso-login-box input[type=password]:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.15);
      font-size: 12px; }
  .iso-login-box input[type=checkbox] + label {
    color: #6d7274;
    margin: 0 3px;
    font-weight: normal;
    color: #ffffff; }

.iso-login-form-control-label {
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase; }

.iso-login-language-list {
  padding: 0;
  list-style-type: none;
  width: 100%; }
  .iso-login-language-list li {
    display: inline;
    padding: 0 50px 0 10px;
    cursor: pointer;
    width: 100%; }
    .iso-login-language-list li a span {
      padding-left: 15px;
      color: #ffffff; }

.iso-login-controls-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center; }

.iso-login-a {
  color: #2A7DE1;
  padding: 0 15px; }
  .iso-login-a:hover {
    color: #2A7DE1;
    text-decoration: none; }
  .iso-login-a:active {
    color: #2A7DE1;
    text-decoration: none; }

.iso-login-forgotpassword {
  padding: 10px 0 0 0; }

.iso-login-captcha div div {
  width: 100%;
  height: 70px;
  background: #fafafa; }

.iso-login-captcha div:last-child i {
  line-height: 70px; }

.iso-login-right-content {
  color: #e6e6e6;
  text-align: left;
  font-size: 12px; }

.iso-login-privacy-content {
  font-size: 12px; }
  .iso-login-privacy-content div {
    color: #d0eaf8; }

.iso-login-privacy ul {
  padding: 0;
  list-style-type: none; }

.iso-login-privacy li {
  display: inline-block;
  padding: 2px 10px;
  cursor: pointer;
  width: 100%; }
  .iso-login-privacy li a {
    color: #ffffff; }
    .iso-login-privacy li a:hover {
      color: #ffffff; }
    .iso-login-privacy li a:active {
      color: #ffffff; }

.iso-login-assistance {
  font-size: 12px; }

.iso-login-bottom {
  padding: 20px 0;
  color: #e6e6e6;
  text-align: left;
  font-size: 12px; }

.has-form-error {
  color: #EE3932; }

.iso-login-alert {
  margin: 20px 0;
  padding-right: 15px; }

.iso-login-alert-danger {
  color: #ffffff;
  background: #fd725d;
  font-size: 14px;
  padding: 15px;
  display: flex;
  flex-direction: row; }
  .iso-login-alert-danger div:first-child {
    height: 23px;
    padding-right: 10px; }

.iso-login-alert-success {
  color: #ffffff;
  background: #00e489;
  font-size: 14px;
  padding: 15px;
  display: flex;
  flex-direction: row; }
  .iso-login-alert-success div:first-child {
    height: 23px;
    padding-right: 10px; }

@media only screen and (max-width: 480px) {
  .iso-login-box > div {
    padding: 10px 10px 30px 10px; } }

@media only screen and (max-width: 768px) {
  .iso-login-viewport .iso-logo {
    width: 100%;
    text-align: center; }
  .iso-login-viewport .iso-brand {
    width: 100%;
    text-align: center; }
  .iso-login-captcha > div:last-child > span {
    line-height: 10px;
    float: right; } }

@media only screen and (max-width: 1024px) {
  .iso-login-captcha > div:last-child > span {
    line-height: 10px;
    float: right; } }

.iso-home-welcome {
  padding-bottom: 0px;
  padding-top: 15px;
  padding-right: 0;
  border-left: 0;
  /* color option */
  /* background: linear-gradient(141deg, #004a73 10%, #009cf3 50%, #004a73 85%); */
  background: #2A7DE1;
}

.iso-home-welcome-section {
  position: relative;
  height: 100px;
  color: #ffffff; }

.iso-home-welcome-date {
  color: #ffffff; }

.iso-home-section {
  background: #f7f7f7;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 300px; }

.iso-home-assistance {
  border: 2px dotted #00358E;
  background: #ffffff;
  padding-bottom: 20px; }

.iso-home-recentlist {
  margin-bottom: 0; }

.iso-home-profile-lst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0; }
  .iso-home-profile-lst > i {
    position: absolute;
    background: #ffffff;
    padding: 15px;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    top: 25px;
    left: calc(50% - 35px);
    border: 1px solid #d5d7d8;
    color: #6d7274;
    font-size: 38px; }
  .iso-home-profile-lst > div {
    padding: 10px 0;
    align-self: center;
    text-align: center;
    width: 100%; }
    .iso-home-profile-lst > div i {
      float: right;
      cursor: pointer;
      margin-right: 10px;
      padding: 5px; }
      .iso-home-profile-lst > div i:hover {
        background: #e6e6e6;
        border-radius: 3px; }
    .iso-home-profile-lst > div p {
      color: #00358E; }
    .iso-home-profile-lst > div ul {
      margin-top: 15px;
      padding: 0;
      list-style-type: none;
      margin-bottom: 0; }
      .iso-home-profile-lst > div ul li {
        text-align: left;
        color: #00358E;
        width: 100%;
        display: inline-block;
        padding: 10px;
        border-bottom: 1px solid #d0eaf8; }
        .iso-home-profile-lst > div ul li:first-child {
          border-top: 0; }
        .iso-home-profile-lst > div ul li:last-child {
          border-bottom: 0; }
        .iso-home-profile-lst > div ul li span {
          width: 50%; }
          .iso-home-profile-lst > div ul li span:first-child {
            font-weight: bold;
            margin-right: 15px; }
          .iso-home-profile-lst > div ul li span:last-child {
            float: right;
            text-align: right;
            font-size: 14px; }
    .iso-home-profile-lst > div h4 {
      margin: 0; }

.iso-home-quick-links {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 145px; }
  .iso-home-quick-links ul {
    display: flex;
    margin: 0;
    justify-content: flex-end; }
    .iso-home-quick-links ul li {
      flex: 0 0 auto;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      padding: 10px 0 5px 0; }
      .iso-home-quick-links ul li i {
        font-weight: normal;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 11px; }
      .iso-home-quick-links ul li span {
        color: #ffffff;
        margin-top: 5px; }
      .iso-home-quick-links ul li:hover {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 4px; }

@media only screen and (max-width: 480px) {
  .iso-home-welcome {
    padding-bottom: 50px;
    padding-top: 30px; }
    .iso-home-welcome h3 {
      font-size: 14px; }
    .iso-home-welcome h2 {
      font-size: 16px; } }

@media only screen and (max-width: 768px) {
  .iso-home-welcome {
    padding-bottom: 50px; }
    .iso-home-welcome h3 {
      font-size: 14px; }
    .iso-home-welcome h2 {
      font-size: 16px; } }

@media only screen and (max-width: 1024px) {
  .iso-home-recentlist tbody tr td a {
    opacity: 1; } }

.iso-search-box-back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 998;
  opacity: 0.1; }

.iso-search-box-result {
  position: absolute;
  z-index: 10000;
  background: #ffffff;
  /*width: 400px;*/
  /*max-width: 500px;*/
  min-height: 150px;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #d5d7d8;
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 8px 0 #868b8e;
  transition: max-height 2s ease-in; }

.iso-quicksearch-nomatch {
  padding: 15px;
  text-align: center;
  font-size: 14px; }

.iso-header-search-box-result-claimnumber {
  color: #00358E;
  font-weight: bold;
  font-size: 14px; }

.iso-dashboard {
  margin: 0 25px; }

.iso-dashboard-iframe {
  display: block;
  width: 100%;
  border: 0; }

.iso-about-help {
  /*background: darken($cp-white,2%);*/
  background: #ffffff; }

.iso-medical-criteria {
  padding: 0 10px 0 20px; }

.iso-medical-blueman-flash {
  background: white; }
  .iso-medical-blueman-flash object {
    width: 100%;
    height: 275px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .iso-medical-blueman-flash object embed {
      width: 200px;
      height: 275px; }

.iso-medical-select-bodypart {
  color: #6d7274;
  text-align: left; }
  .iso-medical-select-bodypart > span {
    color: #33AD82;
    text-align: left;
    padding-left: 5px;
    font-weight: bold; }

.iso-medical-blueman-filter {
  padding-top: 0px; }
  .iso-medical-blueman-filter .iso-medical-blueman-bodyparts-list {
    height: 155px;
    width: 100%;
    overflow: auto;
    padding: 3px; }

.iso-medical-blueman-search {
  top: 0px;
  left: 0;
  right: 10px;
  transition: all 0.5s ease;
  z-index: 997;
  background: #ffffff;
  /*min-height: 450px;*/
  position: relative; }

.iso-medical-blueman-search {
  position: relative; }
  .iso-medical-blueman-search .expand {
    position: absolute;
    top: 1px;
    right: 0;
    background: #f2f2f2;
    border-radius: 20px 20px 0 0;
    padding: 2px 5px;
    display: none;
    cursor: pointer; }

.iso-medical-blueman-search-icons {
  float: right;
  position: absolute;
  right: 20px;
  top: -8px;
  z-index: 998;
  display: flex;
  /*& > span {
        display: none;
    }*/ }
  .iso-medical-blueman-search-icons > i {
    padding-top: 5px;
    cursor: pointer; }

.iso-medical-medcode-selected {
  background-color: #33AD82 !important;
  color: #ffffff !important; }
  .iso-medical-medcode-selected td {
    color: #ffffff !important; }
  .iso-medical-medcode-selected a {
    color: #ffffff !important; }
    .iso-medical-medcode-selected a:hover {
      color: #ffffff !important; }
    .iso-medical-medcode-selected a:focus {
      color: #ffffff !important; }
  .iso-medical-medcode-selected i {
    color: #ffffff !important; }

@media only screen and (max-width: 480px) {
  .iso-medical-blueman-search {
    position: static;
    background: #ffffff; }
  .iso-medical-blueman-search-icons {
    display: none; } }

@media only screen and (max-width: 768px) {
  .iso-medical-blueman-search {
    position: static;
    background: #ffffff; }
  .iso-medical-blueman-search-icons {
    display: none; } }

@media only screen and (max-width: 1024px) {
  .iso-medical-blueman-search {
    position: static;
    background: #ffffff; }
  .iso-medical-blueman-search-icons {
    display: none; } }

.iso-assessment-generate {
  margin-top: 20px; }

  .show-btn {
    position: absolute;
    top: 4px;
    right: 20px;
    height: 20px;
    width: 20px;
    text-align: center;
}
  html .k-upload {
    background: #ffffff;
    border: 0;
    width: 100%;
    height: 100%; }
  html .k-dropzone {
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center; }
  html .k-dropzone em {
    visibility: visible;
    font-style: normal;
    font-size: 14px;
    color: #6d7274;
    /*padding-bottom: 15px;*/
    /*text-align: center;*/
    position: relative;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; }
    html .k-dropzone em:first-letter {
      text-transform: capitalize; }
    html .k-dropzone em i {
      position: absolute;
      top: -5px;
      left: -30px;
      color: #6d7274;
      font-size: 24px; }
    html .k-dropzone em svg {
      margin: 0 8px 3px 0; }
      html .k-dropzone em svg path:last-child {
        fill: #6d7274; }
    html .k-dropzone em .ills-upload {
      width: 50px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: flex-end;
      transform: rotate(-10deg) translateX(-9px);
      position: relative; }
      html .k-dropzone em .ills-upload:before {
        content: '';
        position: absolute;
        background: #d9d9d9;
        width: 24px;
        height: 28px;
        transform: rotate(35deg) skewX(-45deg);
        left: 2px;
        top: 14px; }
      html .k-dropzone em .ills-upload .folder {
        background: #ffffff;
        width: 40px;
        height: 34px;
        transform: skewY(-12deg);
        border: 1px solid #e6e6e6;
        background-image: linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(#f2f2f2, #f2f2f2), linear-gradient(#f2f2f2, #f2f2f2);
        background-repeat: no-repeat;
        background-size: 2px 30px,2px 30px,2px 30px,2px 30px,2px 30px,2px 30px;
        background-position: 7px 4px,12px 4px,17px 4px,22px 4px,27px 4px,32px 4px; }
        html .k-dropzone em .ills-upload .folder:after {
          content: '';
          position: absolute;
          background: #ebebeb;
          width: 40px;
          height: 20px;
          transform: skewX(-20deg);
          left: 4px;
          bottom: -1px;
          border: 1px solid #d9d9d9;
          border-bottom: 0;
          border-right: 0;
          z-index: 1; }
        html .k-dropzone em .ills-upload .folder:before {
          content: '';
          position: absolute;
          background: #ebebeb;
          width: 20px;
          height: 5px;
          transform: skewX(-20deg);
          left: 8px;
          top: 9px;
          border-radius: 2px 2px 0 0;
          border: 1px solid #d9d9d9;
          border-bottom: 0;
          z-index: 2; }
      html .k-dropzone em .ills-upload .arrow {
        width: 2px;
        height: 20px;
        background: #8c8c8c;
        position: absolute;
        top: -20px;
        left: 28px;
        transform: rotate(10deg); }
        html .k-dropzone em .ills-upload .arrow:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border: 2px solid #8c8c8c;
          bottom: -1px;
          left: -4px;
          transform: rotate(45deg);
          border-top: 0;
          border-left: 0; }
  html .k-dropzone .k-upload-button {
    background: #ffffff;
    color: #00358E;
    padding: 0 5px;
    cursor: pointer;
    min-width: 0px;
    font-size: 14px;
    border: 0; }
  html .k-upload-selected {
    display: none; }
  html .k-upload-files {
    margin: 0; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 20px;
  font-size: 13px;
}

.mat-expansion-panel-body {
  padding: 0px 20px 16px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 6px !important;
}

.mat-button-toggle-button {
  border: 0;
  background: none;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
  width: 100%;
  cursor: pointer;
  /* width: 50px!important; */
  min-width: unset!important;
}

.mat-slide-toggle-content {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 13px;
}
