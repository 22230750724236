﻿@mixin margin-from-bottom {
  margin-bottom: 69px; // height of footer (45) + 24
}

@mixin action-button {
  button {
    background: rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    border-radius: 15px;
  }
}

.success.mat-snack-bar-container {
  background: $cp-link-color;
  color: white;
  // angular overrides a user-defined .mat-snack-bar-container class unless view encapsulation is set to none
  @include margin-from-bottom;
  @include action-button;
}

.success.mat-button-wrapper {
  color: white;
  font-weight: bold;
}

.warning.mat-snack-bar-container {
  background: $cp-warning-yellow;
  color: white;
  @include margin-from-bottom;
  @include action-button;
}

.error.mat-snack-bar-container {
  background: $cp-error-red;
  color: white;
  @include margin-from-bottom;
  @include action-button;
}

.info.mat-snack-bar-container {
  background: $cp-mango;
  color: white;
  @include margin-from-bottom;
  @include action-button;
}

.warning.mat-button-wrapper {
  color: black;
  font-weight: bold;
}
