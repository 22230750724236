﻿button {
    @include defaultbutton;

    &[disabled=disabled] {
        color: lighten($cp-corp-second-color,50%);
        cursor: no-drop;
    }
    &.success {
        background: $cp-good-green;
        border-radius: 4px;
        &:hover {
            background: darken($cp-good-green,10%);
            color: $cp-white;
        }
    }

    &.square {
        min-height: 50px;
        min-width: 50px;
        border-radius: 10px;
        font-size: ($default-font-size + 2);

        span {
            font-size: ($default-font-size + 12);
        }
    }

    &.roundbutton {
        min-width: 0;
        border-radius: 50%;
        padding: 10px;
        margin: 0 5px 5px 0;
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        /*i {
            position: absolute;
            top: 8px;
            left: 8px;
        }*/
    }

    &.floating-button {
        min-width: 0;
        border-radius: 50%;
        padding: 5px 10px;
        box-shadow: 0 4px 8px 0 lighten($cp-corp-second-color,20%);
        transition: 0.3s;

        i {
            /*position:absolute;
            top:8px;
            left:8px;*/
            padding-top: 4px;
        }
    }

    &.flat-button {
        background: $cp-white;
        color: $cp-corp-active-color;
        border: 0;
        font-weight: bold;
        min-width: 50px;
        font-size: $default-font-size - 1;

        &.transparent {
            background: transparent;
        }

        &.dotted {
            border: 1px dotted $cp-corp-active-color;
            padding: 10px;
            border-radius: 4px;
        }

        &.withicon {
            display: flex;
            align-items: center;

            i {
                border: 1px solid $cp-corp-active-color;
                border-radius: 50%;
                padding: 3px;
            }
        }

        &.withicon-colum {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: normal;
            margin: 10px;

            i {
                border: 1px solid $cp-corp-active-color;
                border-radius: 50%;
                padding: 3px;
                margin-bottom: 10px;
            }

            &:hover {
                font-weight: bold;

                i {
                    background: $cp-corp-active-color;
                    color: $cp-white;
                }
            }
        }

        i {
            margin-right: 5px;
        }
    }

    &.iso-cancel {
        @include tertiarybutton($cp-corp-active-color);
    }

    &.iso-close {
        @include tertiarybutton($cp-corp-active-color);
    }

    &.iso-close-warning {
        @include tertiarybutton($cp-mango);

        &.flat-button {
            background: $cp-white;
            color: $cp-mango;
            font-weight: bold;
            border: 0;
            font-size: $default-font-size + 1;
        }
    }

    &.iso-clear {
        @include tertiarybutton($cp-corp-active-color);
    }

    &.iso-warning-action {
        @include secondarybutton($cp-mango,$cp-white);

        &.flat-button {
            background: $cp-white;
            color: $cp-mango;
            font-weight: bold;
            border: 0;
            font-size: $default-font-size + 1;
        }
        /*&:hover {
            @include secondarybutton(darken($cp-mango,5%),$cp-white);
        }*/
    }

    &.iso-close-error {
        @include secondarybutton($cp-error-red,$cp-white);
        /*&:hover {
            @include secondarybutton(darken($cp-error-red,5%),$cp-white);
        }*/
        &.flat-button {
            background: $cp-white;
            color: $cp-error-red;
            font-weight: bold;
            border: 0;
            font-size: $default-font-size + 1;
        }
    }

    &.disabled-on-loading {
        color: gray ;
        cursor: wait ; 
    }
}

.iso-link-button {
    border: 1px solid $cp-corp-active-color;
    padding: $link-button-padding;
    text-transform: uppercase;
    background: $cp-white;
    color: $cp-corp-active-color;
    font-size: $default-font-size;


    &[disabled=disabled] {
        border: 1px solid lighten($cp-corp-second-color,50%);
        cursor: no-drop;

        &:hover {
            color: lighten($cp-corp-second-color,50%);
            background: $cp-white;
        }
    }

    &:hover {
        color: $cp-corp-active-color;
        background: lighten($cp-corp-color,65%);
    }

    i {
        float: left;
        font-size: ($default-font-size + 6);
        margin-right: 8px;
    }


    &.small {
        padding: 5px 10px;
        font-size: $default-font-size;
    }

    &.round {
        border-radius: 20px;
    }

    &.round8 {
        border-radius: 8px;
    }

    &.round4 {
        border-radius: 4px;
    }

    &.cancel {
        border: 1px solid lighten($cp-corp-second-color,50%);
        color: $cp-corp-second-color;

        &:hover {
            color: $cp-corp-second-color;
            background: darken($cp-white,5%);
        }
    }
}

.iso-close-icon {
    float: right;
    opacity: 0.5;
    background-repeat: no-repeat;

    &.white {
        float: right;
        opacity: 1.0;
    }
}

.iso-button-dialog {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    cursor: pointer;
    background: darken($cp-white,10%);
    margin: 3px;
    color: $cp-corp-third-color;

    div{
        padding: 5px;
    }

    i {
        padding: 0;
    }

    &:hover {
        background: darken($cp-white,15%);
    }

    &.active {
        background: lighten($cp-corp-third-color,10%);
        color: $cp-white;
        pointer-events: none;
    }

    &.chip {
        border-radius: 16px;
    }

    &.selected {
        background: lighten($cp-corp-color,10%);
        color: $cp-white;

        &:hover {
            background: $cp-corp-color;
        }

        &.active {
            background: $cp-corp-color;
            pointer-events: none;
        }

        i {
            border-radius: 50%;
            font-size: 100%;
            background: darken($cp-corp-color,10%);
            margin: 4px 0 4px 10px;
            padding: 3px;
            color: hsla(0,0%,100%,.5);

            &:hover {
                background: hsla(0,0%,100%,.5);
                color: $cp-corp-color;
            }
        }
    }
}

.iso-button-dialog-text {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
}

.iso-button-dialog-container {
    background: $cp-white;
    position: absolute;
    min-width: 300px;
    min-height: 200px;
    box-shadow: 5px 6px 10px -3px $cp-corp-third-color;
    z-index: 1;
    display: none;
    border-radius: 6px;
    transition: margin-left .5s;
    border: 1px solid darken($cp-white,10%);

    & .header {
        /*color: $cp-corp-color;*/
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px 0 16px;
        /*color: $cp-white;*/
        font-size: 140%;

        i {
            cursor: pointer;
            padding: 5px;

            &:hover {
                background: darken($cp-white,10%);
                border-radius: 3px;
            }
        }
    }

    & .content {
        padding: 10px 0;

        & .selctall {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 120%;
            padding:0 10px 20px 16px;
        }

        & .searchbox {
            padding: 0 16px 5px 16px;
        }

        & .checklist {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 450px;
            padding: 5px 0 5px 16px;

            &:focus{
                border-top:1px solid darken($cp-white, 5%);
            }

            & .nodata {
                padding-top: 20px;
                color: $cp-error-red;
            }


        }

        & .rangeheader {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:20px 16px 5px 16px;

            & .rangeoutput {
                color: $cp-corp-color;
            }
        }

        & .rangeslider {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
